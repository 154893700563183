export const colors = {
  orange: "#ff5f30",
  red: "#FF4E40",
  "light-gray": "#f5f5f5",
  "dark-gray": "#4d4d4d",
  "gray-1": "#afafaf",
  "gray-2": "#4d4d4d26",
  "gray-3": "#4d4d4d4d",
  "blue-1": "#009BB4",
  "blue-2": "#A3E9F5",
  "blue-3": "#007386",
  "blue-4": "#004c59",
  "blue-5": "#ddf9fe",
};
