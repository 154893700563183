import React, {
  useRef,
  useEffect,
  useCallback,
  useContext,
  useState,
} from "react";
import { AppGlobalDataContext } from "../../providers/AppGlobalDataProvider";
import { StyledPage } from "./SliceViewer.styled";
import { vtkUtil } from "../../utils/vtkUtils";
import MeasurementManager from "../../utils/MeasurementManager";
import vtkRenderWindow from "@kitware/vtk.js/Rendering/Core/RenderWindow";
import vtkActor from "@kitware/vtk.js/Rendering/Core/Actor";
import vtkCamera from "@kitware/vtk.js/Rendering/Core/Camera";
import vtkMapper from "@kitware/vtk.js/Rendering/Core/Mapper";
import vtkRenderer from "@kitware/vtk.js/Rendering/Core/Renderer";
import vtkGenericRenderWindow from "@kitware/vtk.js/Rendering/Misc/GenericRenderWindow";
import vtkPolyData from "@kitware/vtk.js/Common/DataModel/PolyData";
import SliceViewerHandler from "./SliceViewerHandler";
import vtkInteractorStyleMeasurement from "../../vtkExtension/InteractorStyleMeasurement";
import vtkMath from "@kitware/vtk.js/Common/Core/Math";
import vtkMatrixBuilder from "@kitware/vtk.js/Common/Core/MatrixBuilder";
import {
  ISliceData,
  MeasurementContext,
} from "../../components/layouts/Measurement";
import { Vector3 } from "@kitware/vtk.js/types";
import { ViewOrientation } from "../../utils/common";
import vtkWidgetManager from "@kitware/vtk.js/Widgets/Core/WidgetManager";
import "@kitware/vtk.js/Rendering/Profiles/Geometry";
import { CREATE_MEASUREMENT_EVENT, subscribe } from "../../utils/event.js";
import { Measurement } from "./Measurement";

interface RenderObject {
  genericRenderWindow: vtkGenericRenderWindow;
  renderWindow: vtkRenderWindow;
  renderer: vtkRenderer;
}
const STEP_DISTANCE = 1.0;
const WIDTH = 60.0;
const OFFSET_Y = -100.0;
const NUMBER_OF_PT = (WIDTH * 4) / STEP_DISTANCE;
const MAJOR_GRID_ALPHA = 0.3;
const MINOR_GRID_ALPHA = 0.1;

export const SliceViewer: React.FC = () => {
  const container = useRef(null);
  const { activeNormalMeshes, crossSection, measurements, setMeasurements } =
    useContext(AppGlobalDataContext);
  const {
    sliceData,
    sliceColor,
    meshVisibleStatus,
    upperVisible,
    lowerVisible,
    setMultipleGenericRenderWindows,
    setMultipleRenderers,
    measurementManager,
    setMeasurementManager,
    setIsCreatingMeasurement,
    isCrossSectionUpdating,
    setIsMeasurementEnabled,
  } = useContext(MeasurementContext);
  const [renderObject, setRenderOject] = useState<RenderObject | null>(null);
  const [sliceViewerHandler] = useState<SliceViewerHandler>(
    new SliceViewerHandler()
  );
  const interactorStyleSuppressRotate = useRef(
    vtkInteractorStyleMeasurement.newInstance()
  );
  const [isSliceReady, setIsSliceReady] = useState(false);
  const measurementsLoaded = useRef(false);

  const distanceWidgetFinishCallback = useCallback(
    async (widget: any) => {
      setIsCreatingMeasurement(false);
      let measurement = new Measurement(widget);
      setMeasurements((prevState) => [...prevState, measurement]);
    },
    [setIsCreatingMeasurement, setMeasurements]
  );

  // Trigger setMeasurements to update measurement panel live
  const distanceWidgetUpdateCallback = useCallback(() => {
    setMeasurements((prevState) => [...prevState]);
  }, [setMeasurements]);

  const createDistanceWidget = useCallback(async () => {
    if (renderObject && measurementManager) {
      setIsCreatingMeasurement(true);
      measurementManager.createLineWidget(
        distanceWidgetFinishCallback,
        distanceWidgetUpdateCallback
      );
    }
  }, [
    renderObject,
    measurementManager,
    distanceWidgetFinishCallback,
    distanceWidgetUpdateCallback,
    setIsCreatingMeasurement,
  ]);

  // Subscribe to create measurement event to add measurement widget
  useEffect(() => {
    subscribe(CREATE_MEASUREMENT_EVENT, () => {
      createDistanceWidget();
    });
  }, [renderObject, createDistanceWidget]);

  useEffect(() => {
    if (!container) return;

    const genericRenderWindow = vtkUtil.createGenericRenderWindow(container);
    const renderer = genericRenderWindow.getRenderer();

    setMultipleGenericRenderWindows((prevState) => [
      ...prevState,
      genericRenderWindow,
    ]);
    setMultipleRenderers((prevState) => [...prevState, renderer]);

    const renderWindow = genericRenderWindow.getRenderWindow();
    const widgetManager = vtkWidgetManager.newInstance();
    widgetManager.setRenderer(renderer);
    widgetManager.enablePicking();

    renderer.updateLightsGeometryToFollowCamera();
    renderer.getActiveCamera().setParallelProjection(true);

    genericRenderWindow.resize();
    renderWindow
      ?.getInteractor()
      ?.setInteractorStyle(interactorStyleSuppressRotate.current);
    setRenderOject({
      genericRenderWindow,
      renderWindow,
      renderer,
    });
    setMeasurementManager(new MeasurementManager(widgetManager, renderer));
  }, [
    container,
    setMultipleGenericRenderWindows,
    setMultipleRenderers,
    setMeasurementManager,
  ]);

  const renderCanvas = useCallback(() => {
    renderObject?.renderWindow?.render();
  }, [renderObject?.renderWindow]);

  const drawGridLines = useCallback(
    (major_grid: boolean) => {
      const buffer_pts_latitude = new Float32Array(NUMBER_OF_PT * 3);
      const lines_latitude = new Uint32Array((NUMBER_OF_PT / 2) * 3);

      const buffer_pts_longitude = new Float32Array(NUMBER_OF_PT * 3);
      const lines_longitude = new Uint32Array((NUMBER_OF_PT / 2) * 3);

      let grid_number: number[] = [];
      for (let i = 0; i < (WIDTH * 2.0) / STEP_DISTANCE; ++i) {
        const is_major: boolean = major_grid && i % 10 === 0;
        const is_minor: boolean = !major_grid && i % 10 !== 0;
        if (is_major || is_minor) {
          grid_number.push(i);
        }
      }

      for (let index = 0; index < grid_number.length; ++index) {
        let pt_latitude_0: number[] = [
          -WIDTH,
          OFFSET_Y,
          -WIDTH + grid_number[index] * STEP_DISTANCE,
        ];
        let pt_latitude_1: number[] = [
          WIDTH,
          OFFSET_Y,
          -WIDTH + grid_number[index] * STEP_DISTANCE,
        ];
        for (let j = 0; j < 3; ++j) {
          buffer_pts_latitude[6 * grid_number[index] + j] = pt_latitude_0[j];
          buffer_pts_latitude[6 * grid_number[index] + 3 + j] =
            pt_latitude_1[j];
        }

        lines_latitude[3 * grid_number[index]] = 2;
        lines_latitude[3 * grid_number[index] + 1] = 2 * grid_number[index];
        lines_latitude[3 * grid_number[index] + 2] = 2 * grid_number[index] + 1;

        let pt_longitude_0: number[] = [
          -WIDTH + grid_number[index] * STEP_DISTANCE,
          OFFSET_Y,
          -WIDTH,
        ];
        let pt_longitude_1: number[] = [
          -WIDTH + grid_number[index] * STEP_DISTANCE,
          OFFSET_Y,
          WIDTH,
        ];
        for (let j = 0; j < 3; ++j) {
          buffer_pts_longitude[6 * grid_number[index] + j] = pt_longitude_0[j];
          buffer_pts_longitude[6 * grid_number[index] + 3 + j] =
            pt_longitude_1[j];
        }

        lines_longitude[3 * grid_number[index]] = 2;
        lines_longitude[3 * grid_number[index] + 1] = 2 * grid_number[index];
        lines_longitude[3 * grid_number[index] + 2] =
          2 * grid_number[index] + 1;
      }

      const polydata_latitude = vtkPolyData.newInstance();
      polydata_latitude.getPoints().setData(buffer_pts_latitude, 3);
      polydata_latitude.getLines().setData(lines_latitude);

      const mapper_latitude = vtkMapper.newInstance();
      mapper_latitude.setInputData(polydata_latitude);

      const actor_latitude = vtkActor.newInstance();
      actor_latitude.setMapper(mapper_latitude);
      actor_latitude.getProperty().setColor(0.25, 0.25, 0.25);
      if (major_grid) {
        actor_latitude.getProperty().setOpacity(MAJOR_GRID_ALPHA);
      } else {
        actor_latitude.getProperty().setOpacity(MINOR_GRID_ALPHA);
      }
      actor_latitude.setVisibility(true);

      renderObject?.renderer.addActor(actor_latitude);

      const polydata_longitude = vtkPolyData.newInstance();
      polydata_longitude.getPoints().setData(buffer_pts_longitude, 3);
      polydata_longitude.getLines().setData(lines_longitude);

      const mapper_longitude = vtkMapper.newInstance();
      mapper_longitude.setInputData(polydata_longitude);

      const actor_longitude = vtkActor.newInstance();
      actor_longitude.setMapper(mapper_longitude);
      actor_longitude.getProperty().setColor(0.25, 0.25, 0.25);

      if (major_grid) {
        actor_longitude.getProperty().setOpacity(MAJOR_GRID_ALPHA);
      } else {
        actor_longitude.getProperty().setOpacity(MINOR_GRID_ALPHA);
      }

      actor_longitude.setVisibility(true);

      renderObject?.renderer.addActor(actor_longitude);
      renderCanvas();
    },
    [renderCanvas, renderObject?.renderer]
  );

  useEffect(() => {
    if (renderObject) {
      drawGridLines(true);
      drawGridLines(false);
      sliceViewerHandler.setRenderer(renderObject.renderer);
    }
    if (renderObject) {
      renderCanvas();
    }
  }, [renderObject, drawGridLines, renderCanvas, sliceViewerHandler]);

  const rotateActor = useCallback(
    (actor: vtkActor, normal: any) => {
      const normPanel = renderObject?.renderer
        .getActiveCamera()
        .getViewPlaneNormal() as Vector3;
      vtkMath.normalize(normal);

      let m4 = vtkMatrixBuilder
        .buildFromRadian()
        .identity()
        .rotateFromDirections(normal, normPanel)
        .getMatrix();

      actor.setUserMatrix(m4);
    },
    [renderObject?.renderer]
  );

  const renderSlice = useCallback(
    (slicesData: any, normal: any) => {
      sliceViewerHandler.clearSliceGraphics();

      const camera = renderObject?.renderer.getActiveCamera();
      const focalPoint = camera?.getFocalPoint();
      const convertedPosition = focalPoint?.map(
        (val, idx) => val - normal[idx] * 50
      ) as Array<number>;
      camera?.setPosition(
        convertedPosition[0],
        convertedPosition[1],
        convertedPosition[2]
      );
      vtkUtil.setCameraOrientation(camera as vtkCamera, ViewOrientation.Back);

      let counter = 0;
      slicesData.forEach((sliceData: ISliceData, index: number) => {
        const mapper = vtkMapper.newInstance();
        mapper.setInputData(sliceData.polydata);

        const actor = vtkActor.newInstance();
        actor.setMapper(mapper);
        actor
          .getProperty()
          .setColor(
            sliceColor[counter][0],
            sliceColor[counter][1],
            sliceColor[counter][2]
          );
        let visible: boolean = true;
        if (activeNormalMeshes[index].isUpperJaw()) {
          visible = upperVisible;
        } else if (activeNormalMeshes[index].isLowerJaw()) {
          visible = lowerVisible;
        }

        const found = meshVisibleStatus.find(
          (a) => a.scanId === sliceData.scanId
        );
        if (found !== undefined) {
          visible = visible && found?.visible;
        }
        actor.setVisibility(visible);

        counter++;
        actor.getProperty().setLineWidth(4);
        rotateActor(actor, normal);
        renderObject?.renderer.addActor(actor);
        sliceViewerHandler.addSliceActor(actor);
      });
      renderObject?.renderer.resetCamera();
      setScale(camera as vtkCamera);
      setIsSliceReady(true);
    },
    [
      lowerVisible,
      meshVisibleStatus,
      activeNormalMeshes,
      renderObject?.renderer,
      rotateActor,
      sliceColor,
      sliceViewerHandler,
      upperVisible,
      setIsSliceReady,
    ]
  );

  // Load measurements from context if needed
  useEffect(() => {
    if (measurementManager && isSliceReady && !measurementsLoaded.current) {
      measurements.forEach((measurement) => {
        const widgetHandle = measurementManager.createFinishedLineWidget(
          measurement.point1,
          measurement.point2,
          measurement.textPosition,
          distanceWidgetUpdateCallback
        );
        measurement.setWidgetHandle(widgetHandle);
      });
      measurementsLoaded.current = true;
    }
  }, [
    measurementManager,
    isSliceReady,
    measurements,
    distanceWidgetUpdateCallback,
  ]);

  // Cross section updated with new sliceData => render slice and canvas
  useEffect(() => {
    const { polydata, normal } = sliceData;
    if (polydata && normal) {
      // Render new slice
      renderSlice(polydata, normal);
    }
    renderCanvas();
  }, [sliceData, renderSlice, renderCanvas]);

  // Delete measurements when cross section is updated
  useEffect(() => {
    if (
      isCrossSectionUpdating ||
      (crossSection && measurementsLoaded.current)
    ) {
      if (measurementManager) measurementManager.clearLineWidgets();
      setMeasurements([]);
      setIsCreatingMeasurement(false);
    }
  }, [
    crossSection,
    isCrossSectionUpdating,
    measurementManager,
    setIsCreatingMeasurement,
    setMeasurements,
  ]);

  // Check if we can enable measurement
  useEffect(() => {
    setIsMeasurementEnabled(isSliceReady && !isCrossSectionUpdating);
  }, [isCrossSectionUpdating, isSliceReady, setIsMeasurementEnabled]);

  const setScale = (cam: vtkCamera) => {
    let scale = cam.getParallelScale();
    cam.setParallelScale(scale * 0.35);
  };

  return <StyledPage ref={container} />;
};

export default SliceViewer;
