import React, {
  useRef,
  useEffect,
  useCallback,
  useContext,
  useState,
} from "react";
import {
  AppGlobalDataContext,
  AnimationState,
} from "../../providers/AppGlobalDataProvider";
import { StyledLayout } from "./SuperImposition.styled";
import { ViewOrientation } from "../../utils/common";
import GenericToolBar from "../shared/GenericToolBar/GenericToolBar";
import { vtkUtil } from "../../utils/vtkUtils";
import ColorManipulationTool from "../shared/ColorManipulationTool/ColorManipulationTool";
import vtkGenericRenderWindow from "@kitware/vtk.js/Rendering/Misc/GenericRenderWindow";
import WasmWorkerClient from "../../workers/WasmWorkerClient";
import { WasmCmdType } from "../../utils/OccluUtil";
import meshAligner, { computeAlignmentMatrix } from "../../utils/meshAligner";
import { NormalMesh, RenderingMode } from "../../model/Mesh";
import BusyDlg from "../shared/BusyDlg/BusyDlg";
import { Timeline, TimeLineMode } from "../shared/TimeLine/TimeLine";
import CommentPanel from "../shared/Comment/CommentPanel";
import { applyTransformationToMesh, matrix4 } from "../../utils/matrixUtils";
import vtkInteractorStyleMeshView from "../../vtkExtension/InteractorStyleMeshView";
import { mat4 } from "gl-matrix";

export const SuperImposition: React.FC = () => {
  const vtkContainerRef = useRef(null);
  const {
    normalMeshes,
    opacityValues,
    animationState,
    isTrueColorOn,
    analysesDataManager,
    isMatching,
    setIsMatching,
    translations,
  } = useContext(AppGlobalDataContext);
  const actorsMapRef = useRef<{ [key: string]: NormalMesh }>({});
  const [actorsMapLength, setActorsMapLength] = useState(0);
  const [genericRenderWindow, setGenericRenderWindow] =
    useState<vtkGenericRenderWindow>();
  const [cameraOrientation, setCameraOrientation] = useState<
    ViewOrientation | 0
  >(ViewOrientation.Front);
  const [processing, setProcessing] = useState<boolean>(true);

  const interactorStyleSuppressRotate = useRef(
    vtkInteractorStyleMeshView.newInstance()
  );

  // Initialize worker pools
  const [jawUpperWorkersReady, setJawUpperWorkersReady] = useState<number>(0);
  const [jawLowerWorkersReady, setJawLowerWorkersReady] = useState<number>(0);
  const [upperJawWorkers, setUpperJawWorkers] = useState<WasmWorkerClient[]>(
    []
  );
  const [lowerJawWorkers, setLowerJawWorkers] = useState<WasmWorkerClient[]>(
    []
  );
  const renderMeshes = useCallback(async () => {
    if (!genericRenderWindow) {
      return;
    }

    const renderer = genericRenderWindow.getRenderer();
    genericRenderWindow
      ?.getInteractor()
      ?.setInteractorStyle(interactorStyleSuppressRotate.current);

    // Sort meshes by date in order add them to the 3D scene with newest on top layer
    const sortedMeshes = [...normalMeshes].sort(
      (a, b) => Number(a.acquisitionDateTime) - Number(b.acquisitionDateTime)
    );

    const picker = genericRenderWindow.getInteractor().getPicker();
    if (picker) {
      picker.initializePickList();
    }

    for (let normalMesh of sortedMeshes) {
      normalMesh.setRenderingMode(
        isTrueColorOn ? RenderingMode.TrueColor : RenderingMode.Colored
      );
      normalMesh.setVisible(normalMesh.isActive);

      if (!actorsMapRef.current[normalMesh.id]) {
        renderer.addActor(normalMesh.actor);
        actorsMapRef.current[normalMesh.id] = normalMesh;
      }

      // Add actors for picking
      if (picker) {
        picker.addPickList(normalMesh.actor);
      }
    }

    renderer.resetCamera();
    genericRenderWindow.getRenderWindow().render();
    setActorsMapLength(Object.keys(actorsMapRef.current).length);
  }, [normalMeshes, isTrueColorOn, genericRenderWindow]);

  const initializeWorkers = (numWorkers: number) => {
    const upperWorkers = Array.from(
      { length: numWorkers },
      () => new WasmWorkerClient()
    );
    const lowerWorkers = Array.from(
      { length: numWorkers },
      () => new WasmWorkerClient()
    );
    upperWorkers.forEach((worker) => {
      worker.onmessage = (e) => {
        if (WasmCmdType.InitWasmSuccess === e?.data) {
          setJawUpperWorkersReady((prev) => prev + 1);
        }
      };
      worker.postMessage({ type: WasmCmdType.InitWasm });
    });
    lowerWorkers.forEach((worker) => {
      worker.onmessage = (e) => {
        if (WasmCmdType.InitWasmSuccess === e?.data) {
          setJawLowerWorkersReady((prev) => prev + 1);
        }
      };
      worker.postMessage({ type: WasmCmdType.InitWasm });
    });
    setUpperJawWorkers(upperWorkers);
    setLowerJawWorkers(lowerWorkers);
  };

  // Initialize workers once normal meshes are loaded
  useEffect(() => {
    setProcessing(true);
    if (isMatching) {
      renderMeshes();
      setProcessing(false);
      return;
    }
    const targetUpperMesh = normalMeshes.find(
      (mesh) => mesh.isUpperJaw() && !mesh.scanId.startsWith("uploadId-")
    );
    const targetLowerMesh = normalMeshes.find(
      (mesh) => mesh.isLowerJaw() && !mesh.scanId.startsWith("uploadId-")
    );
    const meshesToAlign = normalMeshes.filter(
      (mesh) => mesh !== targetUpperMesh && mesh !== targetLowerMesh
    );

    if (meshesToAlign.length > 0) {
      const numWorkers = Math.ceil(meshesToAlign.length / 2);
      initializeWorkers(numWorkers);
    }
  }, [normalMeshes, renderMeshes, isMatching]);

  useEffect(() => {
    if (
      upperJawWorkers.length > 0 &&
      lowerJawWorkers.length > 0 &&
      jawUpperWorkersReady === upperJawWorkers.length &&
      jawLowerWorkersReady === lowerJawWorkers.length
    ) {
      meshAligner.setWorkers(upperJawWorkers, lowerJawWorkers);
    }
  }, [
    jawUpperWorkersReady,
    jawLowerWorkersReady,
    upperJawWorkers,
    lowerJawWorkers,
  ]);

  useEffect(() => {
    if (!vtkContainerRef) {
      return;
    }
    const genericRenderWindow =
      vtkUtil.createGenericRenderWindow(vtkContainerRef);
    setGenericRenderWindow(genericRenderWindow);
  }, [vtkContainerRef]);

  const updateRender = useCallback(async () => {
    if (!genericRenderWindow || !actorsMapLength) {
      return;
    }

    Object.values(actorsMapRef.current).forEach((normalMesh) => {
      const opacity = opacityValues[normalMesh.id];
      normalMesh.setOpacity(opacity);
    });
    genericRenderWindow.getRenderWindow().render();
  }, [opacityValues, genericRenderWindow, actorsMapLength]);

  useEffect(() => {
    updateRender();
  }, [updateRender]);

  useEffect(() => {
    if (animationState === AnimationState.Stopped) {
      updateRender();
    }
  }, [animationState, updateRender]);

  useEffect(() => {
    if (!genericRenderWindow || !cameraOrientation) {
      return;
    }
    const renderer = genericRenderWindow.getRenderer();
    const cam = renderer.getActiveCamera();
    vtkUtil.setCameraOrientation(cam, cameraOrientation);
    renderer.getActiveCamera().setParallelProjection(true);
    renderer.resetCamera();
    genericRenderWindow.resize();
  }, [genericRenderWindow, cameraOrientation]);

  const handleMeshAlignment = useCallback(async () => {
    setProcessing(true);

    // Separate meshes into upper and lower jaw groups
    const upperJawMeshes: NormalMesh[] = [];
    const lowerJawMeshes: NormalMesh[] = [];
    normalMeshes.forEach((mesh) => {
      if (mesh.isUpperJaw()) {
        upperJawMeshes.push(mesh);
      } else {
        lowerJawMeshes.push(mesh);
      }
    });
    const targetUpperMesh = upperJawMeshes.find(
      (mesh) => !mesh.scanId.startsWith("uploadId-")
    );
    const targetLowerMesh = lowerJawMeshes.find(
      (mesh) => !mesh.scanId.startsWith("uploadId-")
    );
    const processMeshes = async (
      meshes: NormalMesh[],
      targetMesh: NormalMesh
    ) => {
      await Promise.all(
        meshes
          .filter((mesh) => mesh !== targetMesh) // Exclude the target mesh itself
          .map(async (mesh, index) => {
            // read from analyses data justify whether need transform or not
            const relation = analysesDataManager.getRelation(
              mesh.scanId,
              mesh.name,
              targetMesh.scanId
            );
            if (relation) {
              applyTransformationToMesh(
                mesh.actor?.getMapper()?.getInputData(),
                relation.matrix as matrix4
              );
              return; // Skip this mesh since it has already been transformed
            }
            try {
              const matrix = await computeAlignmentMatrix(
                mesh,
                targetMesh,
                index
              );
              if (matrix && matrix.length === 16) {
                applyTransformationToMesh(
                  mesh.actor?.getMapper()?.getInputData(),
                  matrix as matrix4
                );
                // update analyses data
                analysesDataManager.updateRelations(
                  mesh.scanId,
                  mesh.name,
                  targetMesh.scanId,
                  matrix as mat4
                );
              } else {
                console.error("Invalid transformation matrix");
              }
            } catch (error) {
              console.error("Error computing alignment matrix:", error);
            }
          })
      );
    };
    const promises = [];
    if (targetUpperMesh) {
      promises.push(processMeshes(upperJawMeshes, targetUpperMesh));
    }
    if (targetLowerMesh) {
      promises.push(processMeshes(lowerJawMeshes, targetLowerMesh));
    }
    await Promise.all(promises);
    setIsMatching(true);

    // Release memory occupied by workers
    if (upperJawWorkers.length > 0 || lowerJawWorkers.length > 0) {
      upperJawWorkers.forEach((worker) => worker.terminate());
      lowerJawWorkers.forEach((worker) => worker.terminate());
      setUpperJawWorkers([]);
      setLowerJawWorkers([]);
      setJawUpperWorkersReady(0);
      setJawLowerWorkersReady(0);
    }
  }, [
    normalMeshes,
    upperJawWorkers,
    lowerJawWorkers,
    analysesDataManager,
    setIsMatching,
  ]);

  useEffect(() => {
    if (
      normalMeshes.length > 2 &&
      upperJawWorkers.length > 0 &&
      lowerJawWorkers.length &&
      jawUpperWorkersReady === upperJawWorkers.length &&
      jawLowerWorkersReady === lowerJawWorkers.length
    ) {
      handleMeshAlignment();
    }
  }, [
    normalMeshes,
    jawUpperWorkersReady,
    jawLowerWorkersReady,
    upperJawWorkers.length,
    lowerJawWorkers.length,
    handleMeshAlignment,
  ]);

  return (
    <>
      <StyledLayout ref={vtkContainerRef} />
      <GenericToolBar
        cameraOrientation={cameraOrientation}
        setCameraOrientation={setCameraOrientation}
        genericRenderWindow={genericRenderWindow || null}
        withTrueColor={true}
        withViewOrientation={true}
        withComments={true}
      />
      {!processing && (
        <ColorManipulationTool
          genericRenderWindow={genericRenderWindow}
          setCameraOrientation={setCameraOrientation}
        />
      )}
      <CommentPanel genericRenderWindows={[genericRenderWindow]} />
      <BusyDlg open={processing} title={translations["PROCESSING"]} />
      {!processing && (
        <Timeline
          genericRenderWindow={genericRenderWindow || null}
          mode={TimeLineMode.Animation}
          onSelectionChanged={() => {}}
        />
      )}
    </>
  );
};

export default SuperImposition;
