import React from "react";

const PencilIcon: React.FC = () => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M5 16.9998H7.17157L14.3787 9.79274L12.2071 7.62117L5 14.8283V16.9998ZM13.6213 6.20696L15.7929 8.37853L17.1716 6.99985L15 4.82828L13.6213 6.20696ZM3 14.4141V17.9998C3 18.5521 3.44772 18.9998 4 18.9998H7.58579C7.851 18.9998 8.10536 18.8945 8.29289 18.707L19.2929 7.70696C19.6834 7.31643 19.6834 6.68327 19.2929 6.29274L15.7071 2.70696C15.3166 2.31643 14.6834 2.31643 14.2929 2.70696L3.29289 13.707C3.10536 13.8945 3 14.1488 3 14.4141ZM2 20.9998C2 20.4476 2.44772 19.9998 3 19.9998H21C21.5523 19.9998 22 20.4476 22 20.9998C22 21.5521 21.5523 21.9998 21 21.9998H3C2.44772 21.9998 2 21.5521 2 20.9998Z"
        fill="#4D4D4D"
      />
    </svg>
  );
};

export default PencilIcon;
