import React from "react";

interface EclipseIconProps {
  color: string;
}

const EclipseIcon: React.FC<EclipseIconProps> = ({ color }) => {
  return (
    <svg
      width="36"
      height="36"
      viewBox="0 0 36 36"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_16290_29681)">
        <rect y="15" width="1200" height="6" rx="3" fill="#AFAFAF" />
        <circle
          cx="18"
          cy="18"
          r="15"
          fill="white"
          stroke={color}
          strokeWidth="6"
        />
        <circle cx="18" cy="18" r="9" fill={color} />
      </g>
      <rect
        x="-181.5"
        y="-929.5"
        width="1923"
        height="1033"
        stroke="#009BB4"
        strokeWidth="3"
      />
      <defs>
        <clipPath id="clip0_16290_29681">
          <rect x="-180" y="-928" width="1920" height="1030" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default EclipseIcon;
