import vtkPolyData from "@kitware/vtk.js/Common/DataModel/PolyData";
import { vtkUtil } from "./vtkUtils";
import { Mesh, NormalMesh } from "../model/Mesh";
import { OccluUtil, WasmCmdType, MeshPairT } from "./OccluUtil";
import WasmWorkerClient from "../workers/WasmWorkerClient";

class OcclusionMappingWorker {
  private worker1!: WasmWorkerClient;
  private worker2!: WasmWorkerClient;

  setWorker(worker1: WasmWorkerClient, worker2: WasmWorkerClient) {
    this.worker1 = worker1;
    this.worker2 = worker2;
  }

  private cache: Map<
    string,
    { upperScalars: Float32Array; lowerScalars: Float32Array }
  >;
  constructor() {
    this.cache = new Map();
  }

  compute(
    upperPd: vtkPolyData,
    lowerPd: vtkPolyData
  ): Promise<{ upperScalars: Float32Array; lowerScalars: Float32Array }> {
    if (!this.worker1 || !this.worker2 || !upperPd || !upperPd) {
      return Promise.reject("worker not ready");
    }
    const cacheKey = this.generateCacheKey(upperPd, lowerPd);
    if (this.cache.has(cacheKey)) {
      return Promise.resolve(this.cache.get(cacheKey)!);
    }
    return new Promise((resolve) => {
      const data1 = OccluUtil.toMeshPairT(upperPd, lowerPd);
      const data2 = OccluUtil.toMeshPairT(lowerPd, upperPd);
      let resolveCount = 0;
      const results: { upperScalars: any; lowerScalars: any } = {
        upperScalars: null,
        lowerScalars: null,
      };
      if (
        !OccluUtil.isMeshDataValid(data1.first) ||
        !OccluUtil.isMeshDataValid(data2.first) ||
        !OccluUtil.isMeshDataValid(data1.second) ||
        !OccluUtil.isMeshDataValid(data2.second)
      ) {
        resolve(results);
        return;
      }
      const onFinally = () => {
        ++resolveCount;
        if (resolveCount === 2) {
          this.cache.set(cacheKey, results);
          resolve(results);
        }
      };
      this.doCompute(data1, this.worker1).then((scalars) => {
        results.lowerScalars = scalars;
        onFinally();
      });
      this.doCompute(data2, this.worker2).then((scalars) => {
        results.upperScalars = scalars;
        onFinally();
      });
    });
  }
  private generateCacheKey(upperPd: vtkPolyData, lowerPd: vtkPolyData): string {
    const upperBounds = upperPd.getBounds().join(",");
    const lowerBounds = lowerPd.getBounds().join(",");
    return `${upperBounds}-${lowerBounds}`;
  }

  private postToWorker(
    msg: MeshPairT,
    worker: WasmWorkerClient
  ): Promise<Float32Array> {
    if (worker && msg.first && msg.second) {
      const type = WasmCmdType.ComputeOcclusion;
      return new Promise((resolve) => {
        worker.postMessage({ type, data: msg }, (e) => {
          resolve(e.data.scalars);
        });
      });
    } else {
      return Promise.reject();
    }
  }

  private doCompute(data: MeshPairT, worker: WasmWorkerClient): Promise<any> {
    return new Promise((resolve) => {
      this.postToWorker(data, worker).then((scalars) => {
        resolve(scalars);
      });
    });
  }
}

export const computeOcclusionMap = (
  upperMesh: NormalMesh,
  lowerMesh: NormalMesh
): Promise<{ upperScalars: Float32Array; lowerScalars: Float32Array }> => {
  return new Promise((resolve, reject) => {
    const transform = (
      a: Mesh,
      b: Mesh
    ): Promise<{ upperScalars: Float32Array; lowerScalars: Float32Array }> => {
      return new Promise((resolve, reject) => {
        if (a && b) {
          const a_pd = vtkUtil.getPolyData(a.actor);
          const b_pd = vtkUtil.getPolyData(b.actor);
          occlusionMapWorker
            .compute(a_pd, b_pd)
            .then(
              (results: {
                upperScalars: Float32Array;
                lowerScalars: Float32Array;
              }) => {
                resolve(results);
              }
            )
            .catch(reject);
        } else {
          reject("");
        }
      });
    };

    if (upperMesh && lowerMesh) {
      const transformPromise = transform(upperMesh, lowerMesh);
      transformPromise
        .then((results) => {
          resolve(results);
        })
        .catch(reject);
    } else {
      reject("Invalid input");
    }
  });
};

export const occlusionMapWorker = new OcclusionMappingWorker();
export default computeOcclusionMap;
