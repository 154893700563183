import React, { useContext, useState } from "react";
import {
  CommentFormContainer,
  CommentInput,
  ButtonContainer,
  SaveButton,
  CancelButton,
} from "./CommentForm.styled";
import { AppGlobalDataContext } from "../../../providers/AppGlobalDataProvider";

const CommentForm: React.FC<{
  position2D: { x: number; y: number };
  onCancel: () => void;
  onSave: (text: string) => void;
}> = (props) => {
  const [formPosition] = useState(props.position2D);
  const [commentText, setCommentText] = useState("");
  const { translations } = useContext(AppGlobalDataContext);

  const handleSave = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.stopPropagation();
    if (commentText.trim() !== "") {
      props.onSave(commentText);
      setCommentText("");
    }
  };

  const handleCancel = (event: React.MouseEvent<HTMLButtonElement>) => {
    props.onCancel();
    event.stopPropagation();
  };

  return (
    <CommentFormContainer
      x={formPosition.x}
      y={formPosition.y}
      onClick={(e) => e.stopPropagation()}
    >
      <CommentInput
        placeholder={translations["ADD_YOUR_COMMENT"]}
        value={commentText}
        onChange={(e) => setCommentText(e.target.value)}
        autoFocus
      />
      <ButtonContainer>
        <CancelButton onClick={handleCancel}>
          {translations["CANCEL"]}
        </CancelButton>
        <SaveButton onClick={handleSave} disabled={commentText.trim() === ""}>
          {translations["SAVE"]}
        </SaveButton>
      </ButtonContainer>
    </CommentFormContainer>
  );
};

export default CommentForm;
