import vtkRenderWindowInteractor from "@kitware/vtk.js/Rendering/Core/RenderWindowInteractor";
import React, {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import DialogBox, {
  GroupedMeshes,
} from "../../components/shared/DialogBox/DialogBox";
import Tooltip from "../../components/shared/Tooltip/Tooltip";
import UpperLowerSwitch from "../../components/shared/UpperLowerSwitch/UpperLowerSwitch";
import { ReactComponent as ApplayIcon } from "../../images/apply.svg";
import { ReactComponent as ResetIcon } from "../../images/reset.svg";
import { ReactComponent as RestoreIcon } from "../../images/restore.svg";
import SwapPNG from "../../images/swap.png";
import TipAnimation from "../../images/tip_point_based_matching.png";
import InfoImage from "../../images/info.png";
import { ReactComponent as AutoMatchingIcon } from "../../images/auto-matching.svg";
import { AppGlobalDataContext } from "../../providers/AppGlobalDataProvider";
import { formatDateFromString } from "../../utils/dateTime";
import { TriView, vtkUtil } from "../../utils/vtkUtils";
import { NormalMesh, RenderingMode, createGroupedMeshes } from "../Mesh";
import { PickPointHandler } from "./PickPointHandler";
import {
  ControlPanel,
  ColorIndicator,
  HorizonDivider,
  LeftBottomLabel,
  LeftTopLabel,
  StyledLayout,
  VerticleDivider,
  PanelTitle,
  TipText,
  MatchingMethodGroup,
  GroupLabel,
  Viewport,
  IconButton,
} from "./PointBasedMatching.styled";
import { ViewOrientation } from "../../utils/common";
import vtkInteractorStyleMatching from "../../vtkExtension/InteractorStyleMatching";
import { applyTransformationToMesh, matrix4 } from "../../utils/matrixUtils";
import vtkRenderer from "@kitware/vtk.js/Rendering/Core/Renderer";
import WasmWorkerClient from "../../workers/WasmWorkerClient";
import { WasmCmdType } from "../../utils/OccluUtil";
import meshAligner, { computeAlignmentMatrix } from "../../utils/meshAligner";
import BusyDlg from "../../components/shared/BusyDlg/BusyDlg";

type LabelParam = { color: string; dateTime: string };
export const PointBasedMatching: React.FC = () => {
  const vtkContainerRef = useRef(null);
  const { activeNormalMeshes, analysesDataManager, translations } =
    useContext(AppGlobalDataContext);
  const [triView, setTriView] = useState<TriView>();
  const [selectDialogOpened, setSelectDialogOpened] = useState<boolean>(false);
  const [selectScanIds, setSelectScanIds] = useState<string[]>([]);
  const [groupedMeshes, setGroupedMeshes] = useState<GroupedMeshes>({
    upperJawMeshes: [],
    lowerJawMeshes: [],
  });
  const [upperVisible, setUpperVisible] = useState<boolean>(true);
  const [matrix, setMatrix] = useState<matrix4>();
  const [buttonStatus, setButtonStatus] = useState({
    apply: false,
    restore: false,
    reset: false,
  });
  const [RenderMeshLB, setRenderMeshLB] = useState<NormalMesh>();
  const pickPointHandler = useRef(new PickPointHandler());
  const interactorStyle = useRef(vtkInteractorStyleMatching.newInstance());

  const [processing, setProcessing] = useState<boolean>(false);
  // Initialize worker pools
  const [wasmWorkerReady, setWasmWorkerReady] = useState<boolean>(false);
  const wasmWorker = useRef(new WasmWorkerClient());

  useEffect(() => {
    if (wasmWorkerReady) {
      meshAligner.setWorkers([wasmWorker.current], [wasmWorker.current]);
    }
  }, [wasmWorkerReady]);

  useEffect(() => {
    // init render window
    const view = vtkUtil.createTriViewRenderWindow(vtkContainerRef);
    setTriView(view);
  }, []);

  useEffect(() => {
    if (triView) {
      enablePickingPoints(triView.renderWindow.getInteractor());
      pickPointHandler.current.setRenderers(triView);
      pickPointHandler.current.setMatchingCallback((mat) => {
        setMatrix(mat);
      });
      pickPointHandler.current.setMatchingAvailable((status) => {
        setButtonStatus(status);
      });
    }
  }, [triView]);

  useEffect(() => {
    if (
      activeNormalMeshes.length > 0 &&
      groupedMeshes.upperJawMeshes.length <= 0
    ) {
      // reset color and visible
      activeNormalMeshes.forEach((mesh) => {
        mesh.setRenderingMode(RenderingMode.Colored);
        mesh.setVisible(true);
      });
      setGroupedMeshes(createGroupedMeshes(activeNormalMeshes));
    }
  }, [activeNormalMeshes, groupedMeshes]);

  useEffect(() => {
    const ids = groupedMeshes.upperJawMeshes.map((m) => m.scanId);
    if (2 === ids.length) {
      setSelectScanIds(ids);
    } else if (2 < ids.length) {
      setSelectDialogOpened(true);
    }
  }, [groupedMeshes]);

  const RenderMeshLT = useMemo(() => {
    if (0 === selectScanIds.length) {
      return undefined;
    }
    return activeNormalMeshes.find(
      (mesh) =>
        mesh.scanId === selectScanIds[0] && upperVisible !== mesh.isLowerJaw()
    );
  }, [selectScanIds, activeNormalMeshes, upperVisible]);

  const RenderMeshMain = useMemo(() => {
    if (0 === selectScanIds.length) {
      return undefined;
    }
    return activeNormalMeshes.find(
      (mesh) =>
        mesh.scanId === selectScanIds[1] && upperVisible !== mesh.isLowerJaw()
    );
  }, [selectScanIds, activeNormalMeshes, upperVisible]);

  useEffect(() => {
    if (!RenderMeshMain) {
      return;
    }
    const duplicated = RenderMeshMain.duplicate(null);
    const pd = vtkUtil.getPolyData(RenderMeshMain.actor);
    duplicated.actor = vtkUtil.buildActorFromDeepCopy(pd);
    duplicated.setRenderingMode(RenderingMode.Colored);
    setRenderMeshLB(duplicated);
  }, [RenderMeshMain]);

  const LabelParamLT: LabelParam = useMemo(() => {
    if (RenderMeshLT) {
      return {
        color: RenderMeshLT.preferColorHex,
        dateTime: formatDateFromString(RenderMeshLT.acquisitionDateTime),
      };
    } else {
      return { color: "transparent", dateTime: "" };
    }
  }, [RenderMeshLT]);

  const LabelParamLB: LabelParam = useMemo(() => {
    if (RenderMeshLB) {
      return {
        color: RenderMeshLB.preferColorHex,
        dateTime: formatDateFromString(RenderMeshLB.acquisitionDateTime),
      };
    } else {
      return { color: "transparent", dateTime: "" };
    }
  }, [RenderMeshLB]);

  function setViewOrientation(renderer: vtkRenderer, orient: ViewOrientation) {
    const camera = renderer.getActiveCamera();
    camera.setClippingRange(0.0001, 100000);
    vtkUtil.setCameraOrientation(camera, orient);
  }

  useEffect(() => {
    if (!triView) return;
    if (RenderMeshLT && RenderMeshLB && RenderMeshMain) {
      // clean renderer
      triView.rendererLT.removeAllActors();
      triView.rendererLB.removeAllActors();
      triView.rendererMain.removeAllActors();

      triView.rendererLT.addActor(RenderMeshLT.actor);
      RenderMeshLT.actor.getProperty().setOpacity(1.0);

      triView.rendererLB.addActor(RenderMeshLB.actor);
      RenderMeshLB.actor.getProperty().setOpacity(1.0);

      triView.rendererMain.addActor(RenderMeshLT.actor);

      triView.rendererMain.addActor(RenderMeshMain.actor);
      RenderMeshMain.actor.getProperty().setOpacity(1.0);

      pickPointHandler.current.setPickActorLT(RenderMeshLT.actor);
      pickPointHandler.current.setPickActorLB(RenderMeshLB.actor);

      if (upperVisible) {
        setViewOrientation(triView.rendererLT, ViewOrientation.Bottom);
        setViewOrientation(triView.rendererLB, ViewOrientation.Bottom);
        setViewOrientation(triView.rendererMain, ViewOrientation.Bottom);
      } else {
        setViewOrientation(triView.rendererLT, ViewOrientation.Top);
        setViewOrientation(triView.rendererLB, ViewOrientation.Top);
        setViewOrientation(triView.rendererMain, ViewOrientation.Top);
      }
      pickPointHandler.current.resetPoints();
    }
    triView.renderWindow.resize();
    triView.rendererLT.resetCamera();
    triView.rendererLB.resetCamera();
    triView.rendererMain.resetCamera();
    triView.renderWindow.getRenderWindow().render();
  }, [triView, RenderMeshLT, RenderMeshLB, RenderMeshMain, upperVisible]);

  useEffect(() => {
    // initializeWorkers()
    wasmWorker.current.onmessage = (e) => {
      if (WasmCmdType.InitWasmSuccess === e?.data) {
        setWasmWorkerReady(true);
      }
    };
    wasmWorker.current.postMessage({ type: WasmCmdType.InitWasm });
  }, [RenderMeshLT, RenderMeshLB]);

  useEffect(() => {
    if (!matrix || !RenderMeshMain) return;
    if (RenderMeshLT && RenderMeshLB && RenderMeshMain) {
      if (matrix && matrix.length === 16) {
        applyTransformationToMesh(
          RenderMeshMain.actor?.getMapper()?.getInputData(),
          matrix
        );
      } else {
        console.error("Invalid transformation matrix");
      }
      RenderMeshMain.actor.modified();

      triView?.renderWindow.getRenderWindow().render();
      // Save matrix
      analysesDataManager.updateRelations(
        RenderMeshMain.scanId,
        RenderMeshMain.name,
        RenderMeshLT.scanId,
        matrix
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [matrix]);

  const runAutomaticAlignment = useCallback(async () => {
    if (!RenderMeshLT || !RenderMeshLB) return;
    setProcessing(true);

    try {
      const mat = (await computeAlignmentMatrix(
        RenderMeshLB,
        RenderMeshLT,
        0
      )) as matrix4;
      pickPointHandler.current.updateTransActorMatrix(mat);
      const matArray = pickPointHandler.current.multiplyInvertTransform(
        mat,
        matrix
      );
      setMatrix(matArray);
    } catch (error) {
      console.error("Error computing alignment matrix:", error);
    }
    setProcessing(false);
  }, [RenderMeshLT, RenderMeshLB, matrix, setMatrix]);

  function enablePickingPoints(interactor: vtkRenderWindowInteractor) {
    if (!interactor) return;
    interactor.setInteractorStyle(interactorStyle.current);
    interactor.onLeftButtonPress((e) => {
      const pos = e.position;
      const pokedRenderer = e.pokedRenderer;
      const grabbed = pickPointHandler.current.pickPoint(
        [pos.x, pos.y, pos.z],
        pokedRenderer
      );
      if (grabbed) {
        interactorStyle.current.setPanRotateMode(false);
      }
    });
    interactor.onLeftButtonRelease((e) => {
      interactorStyle.current.setPanRotateMode(true);
      const pos = e.position;
      const pokedRenderer = e.pokedRenderer;
      pickPointHandler.current.addPoint([pos.x, pos.y, pos.z], pokedRenderer);
    });
    interactor.onMouseMove((e) => {
      const pos = e.position;
      const pokedRenderer = e.pokedRenderer;

      pickPointHandler.current.updateSphere(
        [pos.x, pos.y, pos.z],
        pokedRenderer
      );
    });
  }

  function handleApply() {
    if (!RenderMeshLT || !RenderMeshLB) return;
    pickPointHandler.current.performMatching(RenderMeshLB, RenderMeshLT);
  }

  function handleRestore() {
    pickPointHandler.current.restorePoint();
  }

  function handleReset() {
    pickPointHandler.current.resetPoints();
  }

  function handleDialogConfirm(selectedScans: string[]): void {
    setSelectDialogOpened(false);
    setSelectScanIds(selectedScans);
  }

  return (
    <StyledLayout>
      {/* tri-viewport */}
      <Viewport ref={vtkContainerRef} />
      {/* control panel */}
      <ControlPanel>
        <PanelTitle>{translations["MATCHING"]}</PanelTitle>
        <TipText>
          <img src={InfoImage} alt="Info" />
          <div>{translations["SELECT_MATCHING_POINTS_ON_EACH_SCAN"]}</div>
        </TipText>
        <img src={TipAnimation} alt="animation" />
        {/* point base matching group */}
        <MatchingMethodGroup>
          <GroupLabel> {translations["POINT_BASED_MATCHING"]}</GroupLabel>
          <Tooltip title={translations["APPLY"]}>
            <IconButton
              onClick={handleApply}
              style={{ opacity: buttonStatus.apply ? 1.0 : 0.3 }}
            >
              <ApplayIcon />
            </IconButton>
          </Tooltip>
          <Tooltip title={translations["RESTORE"]}>
            <IconButton
              onClick={handleRestore}
              style={{ opacity: buttonStatus.restore ? 1.0 : 0.3 }}
            >
              <RestoreIcon />
            </IconButton>
          </Tooltip>
          <Tooltip title={translations["RESET"]}>
            <IconButton
              onClick={handleReset}
              style={{ opacity: buttonStatus.reset ? 1.0 : 0.3 }}
            >
              <ResetIcon />
            </IconButton>
          </Tooltip>
        </MatchingMethodGroup>
        {/* auto matching group */}
        <MatchingMethodGroup>
          <GroupLabel>{translations["AUTO_MATCHING"]}</GroupLabel>
          <Tooltip title={translations["AUTO_MATCHING"]}>
            <IconButton onClick={runAutomaticAlignment}>
              <AutoMatchingIcon />
            </IconButton>
          </Tooltip>
        </MatchingMethodGroup>
      </ControlPanel>

      {selectDialogOpened && (
        <DialogBox
          groupedMeshes={groupedMeshes}
          onClose={() => setSelectDialogOpened(false)}
          onConfirm={handleDialogConfirm}
          cancelAsHide={selectScanIds.length >= 2}
        />
      )}

      <UpperLowerSwitch
        upperVisible={upperVisible}
        lowerVisible={!upperVisible}
        upperVisibleChange={() => {
          setUpperVisible((val) => !val);
        }}
        lowerVisibleChange={() => {
          setUpperVisible((val) => !val);
        }}
      />
      <LeftTopLabel>
        <div>
          <ColorIndicator
            style={{
              backgroundColor: LabelParamLT.color,
            }}
          />
          <span>{LabelParamLT.dateTime}</span>
        </div>
      </LeftTopLabel>
      <LeftBottomLabel>
        <div>
          <ColorIndicator
            style={{
              backgroundColor: LabelParamLB.color,
            }}
          />
          <span>{LabelParamLB.dateTime}</span>
          {groupedMeshes.upperJawMeshes.length > 2 && (
            <img
              src={SwapPNG}
              alt="reset"
              onClick={() => setSelectDialogOpened(true)}
            />
          )}
        </div>
      </LeftBottomLabel>
      <HorizonDivider />
      <VerticleDivider />
      <BusyDlg open={processing} title={translations["PROCESSING"]} />
    </StyledLayout>
  );
};

export default PointBasedMatching;
