import React from "react";

const ArrowDown: React.FC = () => {
  return (
    <svg
      width="20"
      height="12"
      viewBox="0 0 20 12"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M1.70711 0.792893C1.31658 0.402369 0.683417 0.402369 0.292893 0.792893C-0.0976311 1.18342 -0.0976311 1.81658 0.292893 2.20711L9.29289 11.2071C9.68342 11.5976 10.3166 11.5976 10.7071 11.2071L19.7071 2.20711C20.0976 1.81658 20.0976 1.18342 19.7071 0.792893C19.3166 0.402369 18.6834 0.402369 18.2929 0.792893L10 9.08579L1.70711 0.792893Z"
        fill="#4D4D4D"
      />
    </svg>
  );
};

export default ArrowDown;
