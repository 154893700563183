import React, { useCallback, useContext, useEffect, useState } from "react";
import {
  ActionButtonsContainer,
  CancelButton,
  SaveButton,
  CommentActionButton,
  CommentBoxContainer,
  CommentBoxHeader,
  CommentInput,
  DateSpan,
} from "./CommentBox.styled";
import { Comment } from "../../../model/Comment";
import vtkGenericRenderWindow from "@kitware/vtk.js/Rendering/Misc/GenericRenderWindow";
import { timeElapsedBetweenDates } from "../../../utils/dateTime";
import CommentIcon from "../../svg-icons/CommentIcon";
import vtkCoordinate from "@kitware/vtk.js/Rendering/Core/Coordinate";
import TrashIcon from "../../svg-icons/TrashIcon";
import PencilIcon from "../../svg-icons/PencilIcon";
import { AppGlobalDataContext } from "../../../providers/AppGlobalDataProvider";

const CommentBox: React.FC<{
  genericRenderWindows: (vtkGenericRenderWindow | undefined)[];
  comment: Comment;
  setCommentOnFocus: React.Dispatch<React.SetStateAction<Comment | null>>;
  showDeleteCommentPrompt: boolean;
  setShowDeleteCommentPrompt: React.Dispatch<React.SetStateAction<boolean>>;
  isInEditMode: boolean;
  setIsInEditMode: React.Dispatch<React.SetStateAction<boolean>>;
}> = ({
  genericRenderWindows,
  comment,
  setCommentOnFocus,
  showDeleteCommentPrompt,
  setShowDeleteCommentPrompt,
  isInEditMode,
  setIsInEditMode,
}) => {
  const { setComments, translations } = useContext(AppGlobalDataContext);
  const [commentText, setCommentText] = useState("");
  const [commentBoxPosition, setCommentBoxPosition] = useState<{
    x: number;
    y: number;
  }>({ x: 0, y: 0 });

  const calculateCommentPosition = useCallback((): { x: number; y: number } => {
    for (let i = 0; i < genericRenderWindows.length; ++i) {
      const genericRenderWindow = genericRenderWindows[i];
      if (genericRenderWindow) {
        const renderer = genericRenderWindow.getRenderer();
        if (renderer.getActors().includes(comment.actor)) {
          const bounds = comment.actor.getBounds();
          const position = [
            (bounds[0] + bounds[1]) / 2,
            (bounds[2] + bounds[3]) / 2,
            (bounds[4] + bounds[5]) / 2,
          ];
          const coordinate = vtkCoordinate.newInstance();
          coordinate.setCoordinateSystemToWorld();
          coordinate.setValue(position); // Set the position as an array of [x, y, z]
          const displayPosition = coordinate.getComputedDisplayValue(renderer);

          if (!displayPosition) {
            console.error("Failed to compute display position");
            return { x: 0, y: 0 };
          }

          // Compute screen scale
          const clientWidth = genericRenderWindow.getContainer().clientWidth;
          const viewWidth = genericRenderWindow
            .getRenderWindow()
            .getViews()[0]
            .getSize()[0];
          const screenScale = viewWidth / clientWidth;

          let screenX =
            displayPosition[0] / screenScale +
            genericRenderWindow.getContainer().offsetLeft;
          let screenY = displayPosition[1] / screenScale;

          return { x: screenX, y: screenY };
        }
      }
    }
    console.error("Renderer not found");
    return { x: 0, y: 0 };
  }, [genericRenderWindows, comment]);

  useEffect(() => {
    const position = calculateCommentPosition();
    if (position.x === 0 && position.y === 0 && !showDeleteCommentPrompt) {
      setCommentOnFocus(null);
      return;
    }
    setCommentBoxPosition(position);
    setCommentText(comment.text);
  }, [
    calculateCommentPosition,
    comment,
    setCommentOnFocus,
    showDeleteCommentPrompt,
  ]);

  const handleCommentSave = () => {
    comment.text = commentText;
    setComments((prevState) => [...prevState]); // Trigger saving the comment to ScanFlow after edition
    setIsInEditMode(false);
  };

  return (
    <CommentBoxContainer
      x={commentBoxPosition?.x || 0}
      y={commentBoxPosition?.y || -150}
      onClick={(e) => e.stopPropagation()}
    >
      {!isInEditMode && (
        <CommentBoxHeader>
          <CommentIcon />
          <DateSpan>
            {timeElapsedBetweenDates(comment.creationDate, new Date())}
          </DateSpan>
          <ActionButtonsContainer>
            <CommentActionButton onClick={() => setIsInEditMode(true)}>
              <PencilIcon />
            </CommentActionButton>
            <CommentActionButton
              onClick={() => setShowDeleteCommentPrompt(true)}
            >
              <TrashIcon />
            </CommentActionButton>
          </ActionButtonsContainer>
        </CommentBoxHeader>
      )}
      <CommentInput
        readOnly={!isInEditMode}
        placeholder={translations["ADD_YOUR_COMMENT"]}
        value={commentText}
        onChange={(e) => setCommentText(e.target.value)}
        autoFocus
      />
      {isInEditMode && (
        <ActionButtonsContainer>
          <CancelButton
            onClick={() => {
              setIsInEditMode(false);
              setCommentText(comment.text);
            }}
          >
            {translations["CANCEL"]}
          </CancelButton>
          <SaveButton onClick={handleCommentSave}>
            {translations["SAVE"]}
          </SaveButton>
        </ActionButtonsContainer>
      )}
    </CommentBoxContainer>
  );
};

export default CommentBox;
