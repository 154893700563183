import React from "react";

const MeasurementRulerIcon: React.FC = () => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M3.21221 22.5455L1.45485 20.7882C0.28328 19.6166 0.283277 17.7171 1.45485 16.5455L16.5455 1.45485C17.7171 0.283278 19.6166 0.283279 20.7882 1.45485L22.5455 3.21221C23.7171 4.38378 23.7171 6.28328 22.5455 7.45485L7.45485 22.5455C6.28328 23.7171 4.38378 23.7171 3.21221 22.5455ZM2.58622 19.6568L4.34358 21.4142C4.89032 21.9609 5.77675 21.9609 6.32348 21.4142L21.4142 6.32348C21.9609 5.77675 21.9609 4.89032 21.4142 4.34358L19.6568 2.58622C19.1101 2.03949 18.2236 2.03949 17.6769 2.58622L17.3639 2.89922L19.2326 4.76785C19.545 5.08026 19.545 5.5868 19.2326 5.89922C18.9201 6.21164 18.4136 6.21164 18.1012 5.89922L16.2326 4.03059L14.6973 5.56588L15.5659 6.43451C15.8783 6.74693 15.8783 7.25346 15.5659 7.56588C15.2535 7.8783 14.7469 7.8783 14.4345 7.56588L13.5659 6.69725L12.0306 8.23256L13.8992 10.1012C14.2116 10.4136 14.2116 10.9201 13.8992 11.2326C13.5868 11.545 13.0803 11.545 12.7678 11.2326L10.8992 9.36393L9.36392 10.8992L10.2326 11.7678C10.545 12.0803 10.545 12.5868 10.2326 12.8992C9.92013 13.2116 9.4136 13.2116 9.10118 12.8992L8.23255 12.0306L6.69726 13.5659L8.56588 15.4345C8.8783 15.7469 8.8783 16.2535 8.56588 16.5659C8.25346 16.8783 7.74693 16.8783 7.43451 16.5659L5.56588 14.6973L4.03059 16.2325L4.89922 17.1012C5.21164 17.4136 5.21164 17.9201 4.89922 18.2325C4.5868 18.545 4.08027 18.545 3.76785 18.2325L2.89922 17.3639L2.58622 17.6769C2.03949 18.2236 2.03949 19.1101 2.58622 19.6568Z"
        fill="#4D4D4D"
      />
    </svg>
  );
};

export default MeasurementRulerIcon;
