const JawIconForAnimation = () => {
  return (
    <svg
      width="28"
      height="29"
      viewBox="0 0 28 28"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M23.5 17.5L21 15L26 15L23.5 17.5Z"
        fill="#FEC00F"
        id="lowerYellowArrow"
      />
      <path
        d="M23.5 11.5L21 14H26L23.5 11.5Z"
        fill="#FEC00F"
        id="upperYellowArrow"
      />
      <g id="lowerJaw">
        <path
          d="M8.98042 20.3189C6.83418 20.3189 5.39481 19.835 4.40018 19.2236C3.40218 18.6101 2.78091 17.8247 2.33673 17.1067C1.99111 16.548 1.77798 15.8428 1.69735 15.2307C1.86561 15.3697 2.0562 15.5241 2.25039 15.6632C2.50406 15.8449 2.82228 16.0443 3.18055 16.1637C3.39275 16.2345 3.78428 16.3176 4.22978 16.403C4.70404 16.4939 5.31675 16.6013 6.02214 16.7167C7.43356 16.9476 9.23208 17.2127 11.0653 17.4434C12.896 17.6738 14.775 17.8716 16.3426 17.9654C17.1254 18.0122 17.8452 18.034 18.4496 18.0189C19.0236 18.0046 19.5953 17.9562 20.0219 17.8141C20.9087 17.5184 22.1674 17.5018 23.2711 17.6853C23.8144 17.7757 24.2661 17.906 24.5721 18.0408C24.6821 18.0892 24.7574 18.1314 24.8049 18.1622C24.8501 18.301 24.8947 18.4259 24.932 18.5304C24.9435 18.5626 24.9544 18.593 24.9643 18.6212C25.0205 18.7806 25.0553 18.8904 25.0788 19.0024C25.1211 19.2032 25.1471 19.5048 25.0453 20.156C24.9578 20.7162 24.8016 20.9316 24.6062 21.0792C24.3432 21.2779 23.8624 21.4625 22.8864 21.6351C22.1869 21.7588 21.6026 21.5835 20.6831 21.3075C20.6574 21.2998 20.6315 21.2921 20.6053 21.2842C19.6668 21.0028 18.5005 20.6797 16.9775 20.9282C15.8689 21.1091 15.1247 20.9891 13.9956 20.7898L13.9344 20.779C12.7842 20.5758 11.3302 20.3189 8.98042 20.3189ZM1.7037 14.3029C1.70375 14.303 1.70311 14.3049 1.70161 14.3085C1.70289 14.3046 1.70364 14.3028 1.7037 14.3029ZM24.8633 18.2059C24.8631 18.206 24.861 18.2041 24.8573 18.2003C24.8616 18.204 24.8634 18.2059 24.8633 18.2059Z"
          fill="#4D4D4D"
          stroke="#4D4D4D"
          strokeWidth="2"
          strokeMiterlimit="10"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M25.5513 17.4028C25.9167 17.0374 26.2026 13.75 25.839 13.75C25.4755 13.75 24.8908 14.1154 24.7446 14.4808C24.5985 14.8462 24.7446 15.5111 24.7446 16.0324C24.7446 16.5536 24.8196 17.0374 25.0023 17.4028C25.185 17.7682 25.1859 17.7682 25.5513 17.4028Z"
          fill="white"
          stroke="#4D4D4D"
          strokeMiterlimit="10"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M24.8594 17.9156C25.2248 17.5502 25.3799 13.5691 24.6492 13.5691C23.9184 13.5691 23.4082 14.221 23.262 14.5864C23.1159 14.9518 22.85 16.8479 23.6655 17.1253C24.481 17.4028 24.494 18.281 24.8594 17.9156Z"
          fill="white"
          stroke="#4D4D4D"
          strokeMiterlimit="10"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M23.6151 14.6031C23.4806 14.1828 23.3538 13.5858 22.623 13.5858C21.8922 13.5858 20.723 14.5074 20.723 15.6036C20.723 16.6997 22.085 17.9492 22.6903 18.6134C23.2956 19.2776 23.7496 18.0663 23.7496 17.3355C23.7496 16.6047 23.7496 15.0235 23.6151 14.6031Z"
          fill="white"
          stroke="#4D4D4D"
          strokeMiterlimit="10"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M21.1517 16.234C21.1517 15.1379 20.4592 14.1603 19.7284 14.1603C18.9977 14.1603 18.2669 14.891 17.9015 15.2564L17.9015 15.2564C17.5361 15.6218 17.6039 15.8984 17.6039 16.6292C17.6039 17.36 19.2944 18.2013 20.0252 18.2013C20.7559 18.2013 21.1517 17.3302 21.1517 16.234Z"
          fill="white"
          stroke="#4D4D4D"
          strokeMiterlimit="10"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M17.6879 16.2005C17.6879 15.1043 17.1919 14.1997 16.2503 14.3259C15.3087 14.452 15.1826 15.0825 14.6193 15.175C14.056 15.2675 14.1233 15.7473 14.1233 16.4781C14.1233 17.2089 15.8215 17.9157 16.4353 17.9662C17.049 18.0166 17.6879 17.2966 17.6879 16.2005Z"
          fill="white"
          stroke="#4D4D4D"
          strokeMiterlimit="10"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M11.8798 17.8235C10.4328 17.618 8.35827 16.6541 8.35828 15.9552C8.35828 15.2562 8.35472 14.7602 8.76784 14.4498C9.18096 14.1394 10.0789 14.1893 10.8024 14.292C11.5259 14.3948 11.8699 14.7114 12.2316 14.7628C12.5934 14.8141 12.9466 14.4363 13.6188 14.9008C14.2909 15.3653 14.1869 16.2935 14.0841 17.017C13.9814 17.7405 13.3268 18.0289 11.8798 17.8235Z"
          fill="white"
          stroke="#4D4D4D"
          strokeMiterlimit="10"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M3.81833 13.8127C4.26359 13.5107 5.0398 13.1738 5.68 13.2718C6.3202 13.3698 6.47138 13.7673 7.19753 13.8127C7.92368 13.8582 8.24219 14.1041 8.47459 14.5759C8.70699 15.0477 8.51867 15.5327 8.38411 16.0325C8.24942 16.5327 7.44841 16.5834 6.62114 16.422C5.70845 16.244 3.14488 15.1917 3.0487 14.9287C2.95252 14.6657 3.37306 14.1148 3.81833 13.8127Z"
          fill="white"
          stroke="#4D4D4D"
          strokeMiterlimit="10"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
      <g id="upperJaw">
        <path
          d="M3.79999 11C3.19915 11 2.43586 10.834 1.82076 10.6028C1.6613 10.5429 1.5245 10.4833 1.41217 10.4279C1.64029 10.0935 2.21663 9.51038 3.67138 8.92849C6.06982 7.96912 7.87708 7.52663 10.6356 7.9864C12.2922 8.26251 14.6666 8.11862 16.8205 7.80185C18.9631 7.48675 21.0967 6.96962 22.2472 6.39437C23.0169 6.0095 23.472 6.09236 23.7934 6.27686C24.1927 6.50612 24.611 7.02314 25.0008 7.90452C25.0453 8.00501 25.09 8.10541 25.1346 8.20558C25.5413 9.11937 25.9394 10.0138 26.0676 10.7795C26.1364 11.1906 26.1014 11.4357 26.0424 11.5707C26.0034 11.6601 25.9197 11.7906 25.5991 11.8975C24.2838 12.3359 23.0327 12.454 16.7717 12.5769C16.3855 12.5756 15.5115 12.4873 14.3165 12.3382C13.1394 12.1914 11.7216 11.9953 10.3058 11.798C10.1663 11.7786 10.0268 11.7592 9.88754 11.7397C8.61757 11.5627 7.36933 11.3886 6.33224 11.2546C5.21529 11.1104 4.25477 11 3.79999 11Z"
          fill="#4D4D4D"
          stroke="#4D4D4D"
          strokeWidth="2"
          strokeMiterlimit="10"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M27.3 14C27.3 13.2692 26.8299 11 26.3279 11C25.8258 11 25.8258 11.5 25.3238 12C24.8218 12.5 25.5793 14.7544 25.8259 15C26.0725 15.2456 26.463 15.5 26.8299 15.5C27.1968 15.5 27.3 14.3246 27.3 14Z"
          fill="white"
          stroke="#4D4D4D"
          strokeMiterlimit="10"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M25.8259 14C25.8259 13.6346 25.4808 11.969 25.3238 11.5C25.1668 11.0309 25.123 10.8334 24.8218 10.8C24.5205 10.7666 23.3157 12 23.3157 12.5C23.3157 13 23.5612 14.2388 23.8177 14.5C24.1073 14.7948 24.7561 15 25.123 15C25.4899 15 25.8259 14.1936 25.8259 14Z"
          fill="white"
          stroke="#4D4D4D"
          strokeMiterlimit="10"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M22.8136 15.2999C23.1805 15.2999 23.6169 14.9615 23.6169 13.4999C23.6169 12.0384 23.3908 10.7159 22.412 10.7159C21.4331 10.7159 20.3034 11.9038 20.3034 12.9999C20.3034 14.0961 20.8037 14.5269 21.1705 14.673C21.5374 14.8192 22.4467 15.2999 22.8136 15.2999Z"
          fill="white"
          stroke="#4D4D4D"
          strokeMiterlimit="10"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M18.7973 15.4999C19.1659 15.4999 20.4039 14.3307 20.4039 13.5999C20.4039 12.8692 19.6778 11.7 18.7973 11.7C17.9169 11.7 16.7892 12.5967 16.7892 12.9999C16.7892 13.4032 16.7236 14.3346 17.0904 14.6999C17.4573 15.0653 18.4288 15.4999 18.7973 15.4999Z"
          fill="white"
          stroke="#4D4D4D"
          strokeMiterlimit="10"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M15.2831 15.5C14.781 15.5 13.275 14.8 13.275 14.3L13.2749 13C13.2749 13 14.279 12 15.2831 12C16.2871 12 16.7892 12.7692 16.7892 13.5V14.3C16.5104 14.9081 15.7842 15.5 15.2831 15.5Z"
          fill="white"
          stroke="#4D4D4D"
          strokeMiterlimit="10"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M10.7648 14.3C10.3979 14.3 10.4187 14.4807 9.76072 14.7C9.10269 14.4807 8.25461 13.7505 8.25461 13.5C8.25461 13.2494 8.25461 12.8999 8.35502 12.4C8.45542 11.9 9.66416 11.6 10.7648 11.6C11.8654 11.6 12.9286 12.0632 13.0994 12.4808C13.2702 12.8984 13.235 14.3 13.0742 14.6C12.9133 14.9 12.3737 15.0385 11.9988 15.0385C11.6319 14.8923 11.1317 14.3 10.7648 14.3Z"
          fill="white"
          stroke="#4D4D4D"
          strokeMiterlimit="10"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M5.7444 13.2718C5.4509 13.2718 4.95711 13.4485 4.59029 13.5947C3.58621 13.7947 3.23422 12.8192 3.23422 12.5999V12C3.23422 11.6346 4.57041 11 5.7444 11C6.91839 11 8.17424 11.6242 8.30339 12C8.43254 12.3758 8.36027 13.2476 8.23881 13.5C8.11736 13.7524 7.50956 14.0824 7.1491 14.0144C6.72128 13.9337 6.0379 13.2718 5.7444 13.2718Z"
          fill="white"
          stroke="#4D4D4D"
          strokeMiterlimit="10"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
    </svg>
  );
};

export default JawIconForAnimation;
