import { ReactComponent as LowerJawIcon } from "../../../images/lower_small.svg";
import { ReactComponent as UpperJawIcon } from "../../../images/upper_small.svg";
import styled from "styled-components";

interface IUpperLowerSwitchProps {
  upperVisible: boolean;
  upperVisibleChange: () => void;
  lowerVisible: boolean;
  lowerVisibleChange: () => void;
}

const UpperLowerSwitch: React.FC<IUpperLowerSwitchProps> = (props) => {
  const { upperVisible, lowerVisible, upperVisibleChange, lowerVisibleChange } =
    props;

  const switchUpperJawVisibility = () => {
    upperVisibleChange();
  };

  const switchLowerJawVisibility = () => {
    lowerVisibleChange();
  };

  const JawsPanel = styled.div`
    position: fixed;
    bottom: 20px;
    left: 20px;
    display: flex;
    flex-direction: column;
    & > div {
      cursor: pointer;
    }
    & svg > path:nth-child(2) {
      fill: #ffffff;
    }
    & .selected > svg > path:nth-child(2) {
      fill: #009bb4;
    }
  `;

  return (
    <JawsPanel>
      <div
        className={upperVisible ? "selected" : ""}
        onClick={switchUpperJawVisibility}
      >
        <UpperJawIcon />
      </div>
      <div
        className={lowerVisible ? "selected" : ""}
        onClick={switchLowerJawVisibility}
      >
        <LowerJawIcon />
      </div>
    </JawsPanel>
  );
};

export default UpperLowerSwitch;
