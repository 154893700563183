import styled from "styled-components";
import { colors } from "../../../theme";

export const ButtonsContainer = styled.div`
  position: fixed;
  top: 120px;
  right: 0;
  display: flex;
  justify-content: flex-end;
  flex-direction: row;
  align-items: center;
  gap: 2px;
  background-color: transparent;
  z-index: 10;

  & button {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 62px;
    height: 62px;
    border: 2px solid ${colors["gray-1"]};
    background-color: white;
    cursor: pointer;
    outline: none;

    &:hover:not(.active):not(.activeView) {
      background-color: ${colors["blue-2"]};
    }
  }

  & button.toggleMenuVisibility {
    width: 40px;
  }

  & button.active {
    background-color: ${colors["blue-1"]};
    border: 2px solid transparent;
  }

  &.collapsed button:not(:last-child) {
    visibility: hidden;
  }
`;

export const DropdownContainer = styled.div`
  position: absolute;
  top: 100%;
  right: 0;
  display: flex;
  flex-direction: row;
  gap: 2px;
  padding: 8px 0;
  z-index: 10;

  &.hidden {
    visibility: hidden;
  }

  & button.activeView {
    background-color: ${colors["blue-1"]};
    border: 2px solid transparent;
  }
`;
