import React, {
  useRef,
  useEffect,
  useCallback,
  useContext,
  useState,
} from "react";
import { AppGlobalDataContext } from "../../providers/AppGlobalDataProvider";
import { StyledLayout } from "./MeasurementLeftViewer.styled";
import { ViewOrientation } from "../../utils/common";
import { vtkUtil } from "../../utils/vtkUtils";
import vtkRenderWindow from "@kitware/vtk.js/Rendering/Core/RenderWindow";
import vtkGenericRenderWindow from "@kitware/vtk.js/Rendering/Misc/GenericRenderWindow";
import vtkRenderer from "@kitware/vtk.js/Rendering/Core/Renderer";
import { RGBColor } from "@kitware/vtk.js/types";
import CrossSectionHandler from "./CrossSectionHandler";
import { MeasurementContext } from "../../components/layouts/Measurement";
import vtkInteractorStyle from "@kitware/vtk.js/Rendering/Core/InteractorStyle";
import { RenderingMode } from "../Mesh";
import vtkInteractorStyleMeshView from "../../vtkExtension/InteractorStyleMeshView";
import { CrossSection } from "./CrossSection";

interface RenderObject {
  genericRenderWindow: vtkGenericRenderWindow;
  renderWindow: vtkRenderWindow;
  renderer: vtkRenderer;
  interactorStyle: vtkInteractorStyle;
}

const isTouchEvent = (event: any): event is TouchEvent => {
  return "touches" in event;
};

export const MeasurementLeftViewer: React.FC = () => {
  const vtkContainerRef = useRef(null);
  const { activeNormalMeshes } = useContext(AppGlobalDataContext);
  const {
    meshVisibleStatus,
    upperVisible,
    lowerVisible,
    setSliceData,
    setSliceColor,
    setMultipleGenericRenderWindows,
    setMultipleRenderers,
    setIsCrossSectionUpdating,
  } = useContext(MeasurementContext);
  const { crossSection, setCrossSection, setMeasurements } =
    useContext(AppGlobalDataContext);
  const [crossSectionHandler] = useState<CrossSectionHandler>(
    new CrossSectionHandler(crossSection)
  );

  const [renderObject, setRenderOject] = useState<RenderObject | null>(null);
  const hold_left_mouse = useRef(false);
  const touch_move = useRef(false);

  const interactorStyleSuppressRotate = useRef(
    vtkInteractorStyleMeshView.newInstance()
  );

  const renderMeshes = useCallback(() => {
    const genericRenderWindow =
      vtkUtil.createGenericRenderWindow(vtkContainerRef);

    const renderer = genericRenderWindow.getRenderer();
    setMultipleGenericRenderWindows((prevState) => [
      genericRenderWindow,
      ...prevState,
    ]);

    setMultipleRenderers((prevState) => [...prevState, renderer]);

    // Sort meshes by date in order add them to the 3D scene with newest on top layer
    const sortedMeshes = [...activeNormalMeshes].sort(
      (a, b) => Number(a.acquisitionDateTime) - Number(b.acquisitionDateTime)
    );

    for (let mesh of sortedMeshes) {
      mesh.setRenderingMode(RenderingMode.Colored);
      mesh.setOpacity(100);
      renderer.addActor(mesh.actor);
      crossSectionHandler.addMesh(mesh);
      const rgbColor: RGBColor = [
        mesh.preferColor.r,
        mesh.preferColor.g,
        mesh.preferColor.b,
      ];
      crossSectionHandler.setColor(rgbColor);
    }

    setSliceColor(crossSectionHandler.getSliceColor());
    const renderWindow = genericRenderWindow.getRenderWindow();
    const cam = renderer.getActiveCamera();
    vtkUtil.setCameraOrientation(cam, ViewOrientation.Front);
    renderer.resetCamera();
    cam.setParallelProjection(true);
    genericRenderWindow.resize();
    renderWindow
      ?.getInteractor()
      ?.setInteractorStyle(interactorStyleSuppressRotate.current);
    const interactorStyle = renderWindow?.getInteractor()?.getInteractorStyle();
    setRenderOject({
      genericRenderWindow,
      renderWindow,
      renderer,
      interactorStyle,
    });
  }, [
    activeNormalMeshes,
    vtkContainerRef,
    crossSectionHandler,
    setSliceColor,
    setMultipleGenericRenderWindows,
    setMultipleRenderers,
  ]);

  useEffect(() => {
    renderMeshes();
  }, [renderMeshes]);

  // Configure CrossSection tool
  useEffect(() => {
    if (!renderObject || !crossSectionHandler) return;

    crossSectionHandler.setRenderer(renderObject.renderer);
    crossSectionHandler.setSlicesDataCallBack((data) => {
      setSliceData(data);
    });
    crossSectionHandler.setInteractionCallback(() => {
      setIsCrossSectionUpdating(true);
    });
    crossSectionHandler.setEndInteractionCallback((cs: CrossSection | null) => {
      setIsCrossSectionUpdating(false);
      setCrossSection(cs);
    });
    crossSectionHandler.updateCrossSection();

    const interactor = renderObject?.renderWindow?.getInteractor();
    if (!interactor) return;

    crossSectionHandler.setEnabled(true);
    interactor.onLeftButtonPress((e) => {
      hold_left_mouse.current = true;
      if (crossSectionHandler.isEnabled()) {
        if (touch_move.current) {
          touch_move.current = false;
          crossSectionHandler.resetSelection();
        } else {
          crossSectionHandler.onPointSelection(e);
        }
        renderObject?.renderWindow.render();
      }
    });
    interactor.onLeftButtonRelease((e) => {
      hold_left_mouse.current = false;
    });
    interactor.onRightButtonPress((e) => {
      if (crossSectionHandler.isEnabled()) {
        renderObject?.renderWindow.render();
      }
    });
    interactor.onMouseMove((e) => {
      if (crossSectionHandler.isEnabled() && hold_left_mouse.current) {
        crossSectionHandler.resetSelection();
      }
    });
    interactor.onPinch((e) => {
      if (isTouchEvent(e) && Object.keys(e.touches).length >= 2) {
        touch_move.current = true;
      }
    });
  }, [
    renderObject,
    crossSectionHandler,
    setSliceData,
    setCrossSection,
    setMeasurements,
    setIsCrossSectionUpdating,
  ]);

  // Update meshes visibility
  useEffect(() => {
    let refresh = false;
    for (const m of activeNormalMeshes) {
      let visible: boolean = true;
      if (meshVisibleStatus.length > 0) {
        const found = meshVisibleStatus.find((a) => a.id === m.id);
        visible =
          Boolean(found?.visible) &&
          ((m.isUpperJaw() && upperVisible) ||
            (m.isLowerJaw() && lowerVisible));
      } else {
        visible =
          (m.isUpperJaw() && upperVisible) || (m.isLowerJaw() && lowerVisible);
      }
      if (visible !== m.visible) {
        m.setVisible(visible);
        refresh = true;
      }
    }
    if (refresh) renderObject?.renderWindow?.render();
  }, [
    activeNormalMeshes,
    renderObject,
    meshVisibleStatus,
    upperVisible,
    lowerVisible,
  ]);

  return <StyledLayout ref={vtkContainerRef} />;
};

export default MeasurementLeftViewer;
