import React from "react";

const ExclamationBubbleIcon: React.FC = () => {
  return (
    <svg
      width="45"
      height="46"
      viewBox="0 0 45 46"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M23 1.5C10.8756 1.5 1 11.3756 1 23.5V45.5H23C35.1244 45.5 45 35.6244 45 23.5C45 11.3756 35.1244 1.5 23 1.5Z"
        fill="#009BB4"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M23.0002 9.5C20.9063 9.5 19.3135 11.3802 19.6578 13.4456L21.8358 26.5136C21.9307 27.0828 22.4231 27.5 23.0002 27.5C23.5772 27.5 24.0697 27.0828 24.1646 26.5136L26.3426 13.4456C26.6868 11.3802 25.0941 9.5 23.0002 9.5ZM23.0002 36.5C24.657 36.5 26.0002 35.1569 26.0002 33.5C26.0002 31.8431 24.657 30.5 23.0002 30.5C21.3433 30.5 20.0002 31.8431 20.0002 33.5C20.0002 35.1569 21.3433 36.5 23.0002 36.5Z"
        fill="white"
      />
    </svg>
  );
};

export default ExclamationBubbleIcon;
