import styled from "styled-components";

export const ErrorTitle = styled.h4`
  max-width: 50vw;
`;

export const ErrorText = styled.p`
  font-weight: bold;
`;

export const MessageContainer = styled.div`
  box-sizing: border-box;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  min-height: 100vh;
`;
