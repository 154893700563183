import React, { useContext, useState, useEffect } from "react";
import {
  MeshSelectorDiv,
  MeshCheckBoxList,
  MeshCheckboxLi,
} from "../../layouts/Measurement.styled";
import { AppGlobalDataContext } from "../../../providers/AppGlobalDataProvider";
import { formatDateFromString } from "../../../utils/dateTime";
import { NormalMesh } from "../../../model/Mesh";
import Checkbox from "@mui/material/Checkbox";
import {
  IMeasurementMeshVisibleState,
  MeasurementContext,
} from "../../../components/layouts/Measurement";

class MeshSelectItem {
  id: string;
  scanId: string;
  index: number;
  label: string;
  checked: boolean;
  color: string;
  onChange?: (st: IMeasurementMeshVisibleState) => void;
  constructor(mesh: NormalMesh, index: number) {
    this.id = mesh.id;
    this.scanId = mesh.scanId;
    this.label = formatDateFromString(mesh.acquisitionDateTime);
    this.checked = true;
    this.color = mesh.preferColorHex;
    this.index = index;
  }

  setOnChange = (func: (st: IMeasurementMeshVisibleState) => void) => {
    this.onChange = func;
  };

  onCheckedChange = (checked: boolean) => {
    this.checked = checked;
    this.onChange?.({
      id: this.id,
      scanId: this.scanId,
      index: this.index,
      visible: this.checked,
    });
  };
}

const MeshCheckbox: React.FC<{ data: MeshSelectItem }> = (props) => {
  const [checked, setChecked] = useState(props.data.checked);
  const onChange = () => {
    let tmp = !checked;
    props.data.onCheckedChange(tmp);
    setChecked(tmp);
  };
  const color = props.data.color;
  return (
    <MeshCheckboxLi>
      <span>{props.data.label}</span>
      <Checkbox
        checked={checked}
        onChange={onChange}
        sx={{
          color: color,
          "&.Mui-checked": {
            color: color,
          },
        }}
      />
    </MeshCheckboxLi>
  );
};

const MeasurementMeshSelector: React.FC = () => {
  const { activeNormalMeshes } = useContext(AppGlobalDataContext);
  const { meshVisibleStatus, setMeshVisibleStatus } =
    useContext(MeasurementContext);

  const onChangeCallback = (st: IMeasurementMeshVisibleState) => {
    const tmp: IMeasurementMeshVisibleState[] = [];
    meshVisibleStatus.forEach((a) => tmp.push(a));
    const found = tmp.filter((a) => a.scanId === st.scanId);
    found.forEach((a) => (a.visible = st.visible));
    setMeshVisibleStatus?.(tmp);
  };

  useEffect(() => {
    setMeshVisibleStatus?.(createDefaultStatus(activeNormalMeshes));
  }, [activeNormalMeshes, setMeshVisibleStatus]);

  const selectItems = createSelectItems(activeNormalMeshes, true);
  selectItems.forEach((a) => a.setOnChange(onChangeCallback));
  const selectors = selectItems.map((a, index) => (
    <MeshCheckbox data={a} key={index} />
  ));
  return selectItems.length > 0 ? (
    <MeshSelectorDiv>
      <MeshCheckBoxList> {selectors}</MeshCheckBoxList>
    </MeshSelectorDiv>
  ) : null;
};

const createSelectItems = (
  meshList: NormalMesh[],
  filterSameScan: boolean = false
) => {
  const items: MeshSelectItem[] = [];
  meshList.forEach((m, index) => {
    if (filterSameScan) {
      const found = items.find((a) => a.scanId === m.scanId);
      if (undefined !== found) {
        return;
      }
    }
    items.push(new MeshSelectItem(m, index));
  });
  return items;
};

const createDefaultStatus = (meshList: NormalMesh[]) => {
  const tmp: IMeasurementMeshVisibleState[] = [];
  const items = createSelectItems(meshList);
  items.forEach((a) =>
    tmp.push({ id: a.id, scanId: a.scanId, index: a.index, visible: a.checked })
  );
  return tmp;
};

export default MeasurementMeshSelector;
