export const mergeBase64 = (imagesBase64: string[]): Promise<string> => {
  return new Promise((resolve) => {
    const images = imagesBase64.map((base64) => {
      const img = new Image();
      img.src = base64;
      return img;
    });

    Promise.all(
      images.map(
        (img) =>
          new Promise<void>((res) => {
            img.onload = () => res();
          })
      )
    ).then(() => {
      const totalWidth = images.reduce((sum, img) => sum + img.width, 0);
      const totalHeight = Math.max(...images.map((img) => img.height));

      const canvas = document.createElement("canvas");
      const ctx = canvas.getContext("2d");
      if (!ctx) return;

      canvas.width = totalWidth;
      canvas.height = totalHeight;
      ctx.fillStyle = "#ffffff";
      ctx.fillRect(0, 0, canvas.width, canvas.height);

      let xOffset = 0;
      images.forEach((img, index) => {
        const yOffset = totalHeight - img.height;
        ctx.drawImage(img, xOffset, yOffset);
        xOffset += img.width;

        if (index < images.length - 1) {
          ctx.fillStyle = "#c4c4c4";
          ctx.fillRect(xOffset, 0, totalWidth, 1);
          xOffset += 1;
        }
      });

      resolve(canvas.toDataURL("image/png"));
    });
  });
};
