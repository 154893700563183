import React from "react";

const TrashIcon: React.FC = () => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M10 2C9.44771 2 9 2.44772 9 3V5H15V3C15 2.44772 14.5523 2 14 2H10ZM17 5V3C17 1.34315 15.6569 0 14 0H10C8.34315 0 7 1.34315 7 3V5H4.51223C4.50486 4.99992 4.49746 4.99992 4.49005 5H2C1.44772 5 1 5.44772 1 6C1 6.55228 1.44772 7 2 7H3.58435L4.84291 21.2637C4.97956 22.8123 6.27664 24 7.8313 24H16.1687C17.7234 24 19.0204 22.8123 19.1571 21.2637L20.4156 7H22C22.5523 7 23 6.55228 23 6C23 5.44772 22.5523 5 22 5H19.5099C19.5025 4.99992 19.4951 4.99992 19.4878 5H17ZM16 7H8H5.59212L6.83517 21.0879C6.88072 21.6041 7.31308 22 7.8313 22H16.1687C16.6869 22 17.1193 21.6041 17.1648 21.0879L18.4079 7H16ZM9 10C9 9.44771 9.44771 9 10 9C10.5523 9 11 9.44772 11 10V19C11 19.5523 10.5523 20 10 20C9.44771 20 9 19.5523 9 19V10ZM14 9C13.4477 9 13 9.44771 13 10V19C13 19.5523 13.4477 20 14 20C14.5523 20 15 19.5523 15 19V10C15 9.44772 14.5523 9 14 9Z"
        fill="#4D4D4D"
      />
    </svg>
  );
};

export default TrashIcon;
