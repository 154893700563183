import React from "react";

const CommentIcon: React.FC = () => {
  return (
    <svg
      width="36"
      height="32"
      viewBox="0 0 36 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M6.5619 24.1397L6.2582 25.2731L5.17157 29.3284L12.2638 27.4281L12.7661 27.2935L13.2703 27.4206C14.7618 27.7965 16.3497 28 18 28C27.1935 28 34 21.8538 34 15C34 8.14624 27.1935 2 18 2C8.80652 2 2 8.14624 2 15C2 18.114 3.35696 21.023 5.72067 23.3216L6.5619 24.1397ZM4.01618 31.7086L12.7815 29.3599C14.4332 29.7762 16.1852 30 18 30C27.9411 30 36 23.2843 36 15C36 6.71573 27.9411 0 18 0C8.05887 0 0 6.71573 0 15C0 18.7249 1.62927 22.1327 4.32635 24.7554L2.79143 30.4838C2.5922 31.2274 3.27261 31.9078 4.01618 31.7086Z"
        fill="#AFAFAF"
      />
    </svg>
  );
};

export default CommentIcon;
