import styled from "styled-components";

export const RulerPanel = styled.div`
  height: 408px;
  position: fixed;
  right: 18px;
  top: 400px;
`;
export const RulerPanelTitle = styled.div`
  height: 30px;
  width: 100%;
  text-align: right;
  font-size: 22px;
  margin-bottom: 18px;
`;
export const RulerContainer = styled.div`
  display: flex;
  justify-content: right;
`;
export const RulerScalersArea = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
`;
export const RulerScaler = styled.div`
  width: 156px;
  height: 60px;
  line-height: 60px;
  text-align: right;
  font-size: 18px;
`;
export const RulerCardsArea = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  background: linear-gradient(
    to bottom,
    #b700b7 0px 54px,
    #eb1414 66px 114px,
    #e88714 126px 174px,
    #ebcc14 186px 234px,
    #bdf514 246px 294px,
    #14e8bd 306px 360px
  );
`;
export const RulerCard = styled.div`
  width: 156px;
  height: 60px;
  line-height: 60px;
  text-align: center;
  font-size: 18px;
  &:nth-child(-n + 3) {
    color: #fff;
  }
`;
