import { RefObject, useCallback, useEffect } from "react";

export const useClickOutsideOfComponent = (
  ref: RefObject<HTMLElement | undefined>,
  callback: () => void,
  pause: boolean
) => {
  const handleClick = useCallback(
    (event: MouseEvent) => {
      if (ref.current && !ref.current.contains(event.target as HTMLElement)) {
        callback();
      }
    },
    [ref, callback]
  );

  useEffect(() => {
    if (pause) return;

    document.addEventListener("click", handleClick);

    return () => {
      document.removeEventListener("click", handleClick);
    };
  }, [pause, handleClick]);
};
