export interface IAppConfig {
  readonly name: string;
  buildVersion: string;
  releaseDate: string;
  instrumentationKey: string;
  connectionString: string;
}

type IAppConfigJson = {
  name: string;
  buildVersion: string;
  releaseDate: string;
  instrumentationKey: string;
  connectionString: string;
};

export class AppConfig implements IAppConfig {
  readonly name: string;
  readonly buildVersion: string;
  readonly releaseDate: string;
  readonly instrumentationKey: string;
  readonly connectionString: string;

  constructor(json: IAppConfigJson) {
    console.assert(json.name && json.buildVersion);
    this.name = json.name;
    this.buildVersion = json.buildVersion;
    this.releaseDate = json.releaseDate;
    this.instrumentationKey = json.instrumentationKey;
    this.connectionString = json.connectionString;
  }
}

// Load config file from "/public/config.json"
const configFileName = "config.json";
const configUrl = process.env.PUBLIC_URL + "/" + configFileName;

async function loadConfig() {
  try {
    const configRequest = await fetch(configUrl);
    const configResponse = await configRequest.json();

    const loadedConfig: IAppConfig = new AppConfig(configResponse);
    return loadedConfig;
  } catch (e) {
    throw new Error(`Failed to load app configuration (${configFileName})`);
  }
}

export { loadConfig };
