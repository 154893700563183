import styled from "styled-components";
import { colors } from "../../../theme";

export const Backdrop = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  z-index: 1000;
`;

export const Dialog = styled.div`
  min-width: 610px;
  padding: 20px;
  border: 1px solid #ccc;
  border-radius: 5px;
  background-color: ${colors["light-gray"]};
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
`;

export const Title = styled.h1`
  margin: 5px 0 25px 0;
  text-align: center;
  font-size: 36px;
  font-weight: 400;
`;

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 20px 0;
`;

export const InputWrapper = styled.div`
  display: flex;
  justify-content: center;
  gap: 30px;

  & svg {
    height: 75px;
  }
`;

export const InputContainer = styled.div`
  width: min-content;
  align-self: flex-end;
`;

export const DateInputContainer = styled(InputContainer)`
  margin-right: 30px;
`;

export const StyledSpan = styled.span`
  display: block;
  margin-bottom: 5px;
  color: ${colors["dark-gray"]};
  font-size: 18px;
`;

export const StyledInputLabel = styled.label<{ $hasValue: boolean }>`
  position: relative;
  display: block;
  width: 380px;
  height: 36px;
  line-height: 36px;
  margin-bottom: 25px;
  padding: 0px 30px 0 12px;
  border: 1px solid ${colors["gray-1"]};
  border-radius: 3px;
  background-color: white;
  color: ${(props) =>
    props.$hasValue ? colors["dark-gray"] : colors["gray-1"]};
  font-size: 18px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  cursor: pointer;

  & input {
    display: none;
  }

  & svg {
    position: absolute;
    right: 10px;
    height: inherit;
  }
`;

export const StyledDateInputLabel = styled(StyledInputLabel)`
  & input {
    position: absolute;
    right: 0;
    top: 0;
    display: block;
    width: 217px;
    height: 100%;
    opacity: 0;
    overflow: visible;
  }

  & input::-webkit-calendar-picker-indicator {
    position: absolute;
    right: 0;
    top: 0;
    width: 380px;
    height: 100%;
    margin: 0;
    padding: 0;
    cursor: pointer;
  }

  & input[type="date" i] {
    margin-top: 1px;
  }
`;

export const Buttons = styled.div`
  display: flex;
  justify-content: center;
  gap: 15px;
`;

export const ImportButton = styled.button`
  min-width: 250px;
  height: 62px;
  border-radius: 5px;
  border: 2px solid ${colors["blue-1"]};
  font-size: 22px;
  background-color: ${colors["blue-1"]};
  color: white;
  outline: none;
  cursor: pointer;

  &:disabled {
    background-color: ${colors["gray-1"]};
    border-color: ${colors["dark-gray"]};
    color: ${colors["dark-gray"]};
    opacity: 15%;
    cursor: default;
  }
`;

export const CancelButton = styled(ImportButton)`
  border-color: ${colors["gray-1"]};
  background-color: transparent;
  color: ${colors["dark-gray"]};
`;
