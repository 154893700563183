import React from "react";
import { StyledTooltip } from "./Tooltip.styled";

type TooltipProps = {
  children: React.ReactNode;
  title: string;
};

const Tooltip: React.FC<TooltipProps> = ({ children, title }) => {
  return (
    <StyledTooltip title={title} followCursor={true} placement={"bottom-start"}>
      {children}
    </StyledTooltip>
  );
};

export default Tooltip;
