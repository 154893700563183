import macro from "@kitware/vtk.js/macros";
import vtkInteractorStyleTrackballCamera from "@kitware/vtk.js/Interaction/Style/InteractorStyleTrackballCamera";
import { States } from "@kitware/vtk.js/Rendering/Core/InteractorStyle/Constants";

// ----------------------------------------------------------------------------

// vtkInteractorStyleMeshView methods

// ----------------------------------------------------------------------------

function vtkInteractorStyleMeshView(publicAPI: any, model: any) {
  // Set our className

  model.classHierarchy.push("vtkInteractorStyleMeshView");

  // Public API methods

  //----------------------------------------------------------------------------

  const handleLeftButtonPressSuper = publicAPI.handleLeftButtonPress;

  publicAPI.handleLeftButtonPress = (callData: any) => {
    if (model.normalMode) {
      handleLeftButtonPressSuper(callData);
    } else {
      model.previousPosition = callData.position;

      if (model.panMode && callData.shiftKey) {
        publicAPI.startPan();
      }
      publicAPI.startRotate();
    }
  };

  publicAPI.handleRightButtonPress = () => {
    publicAPI.startPan();
  };

  publicAPI.handleRightButtonRelease = () => {
    publicAPI.endPan();
  };

  publicAPI.isPanOrRotate = () => {
    return States.IS_ROTATE === model.state || States.IS_PAN === model.state;
  };

  // Override handleKeyPress to intercept any key press event and disable hot keys
  publicAPI.handleKeyPress = () => {};
}

// ----------------------------------------------------------------------------

// Object factory

// ----------------------------------------------------------------------------

const DEFAULT_VALUES = {
  normalMode: false,

  panRotateMode: false,
  panMode: true,
  rotateMode: false,
};

// ----------------------------------------------------------------------------

export function extend(publicAPI: any, model: any, initialValues: any) {
  Object.assign(model, DEFAULT_VALUES, initialValues);

  // Inheritance

  vtkInteractorStyleTrackballCamera.extend(publicAPI, model, initialValues);

  // Create get-set macros

  macro.setGet(publicAPI, model, ["normalMode", "panMode", "rotateMode"]);

  // For more macro methods, see "Sources/macros.js"

  // Object specific methods

  vtkInteractorStyleMeshView(publicAPI, model);
}

// ----------------------------------------------------------------------------

export const newInstance = macro.newInstance(
  extend,
  "vtkInteractorStyleMeshView"
);

// ----------------------------------------------------------------------------

// eslint-disable-next-line
const vtkInteractorStyleMeshViewModule = { newInstance };

export default vtkInteractorStyleMeshViewModule;
