import { BrowserRouter } from "react-router-dom";
import "./App.css";
import { Header } from "./components/shared/Header/Header";
import AppRoutes from "./routing/AppRoutes";
import ModeMenuPanel from "./components/shared/ModeMenuPanel/ModeMenuPanel";
import { useEffect } from "react";
import { subscribe } from "./utils/event";

function App() {
  useEffect(() => {
    subscribe("contextmenu", (event: Event) => {
      event.preventDefault();
    });
  });
  return (
    <BrowserRouter>
      <Header />
      <AppRoutes />
      <ModeMenuPanel />
    </BrowserRouter>
  );
}

export default App;
