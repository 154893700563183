// Utility functions for matrix operations
export type matrix4 = [
  number,
  number,
  number,
  number,
  number,
  number,
  number,
  number,
  number,
  number,
  number,
  number,
  number,
  number,
  number,
  number,
];
export const multiplyPoint = (matrix: matrix4, point: any[]) => {
  const [x, y, z] = point;
  const w = 1.0;
  const nx = matrix[0] * x + matrix[1] * y + matrix[2] * z + matrix[3] * w;
  const ny = matrix[4] * x + matrix[5] * y + matrix[6] * z + matrix[7] * w;
  const nz = matrix[8] * x + matrix[9] * y + matrix[10] * z + matrix[11] * w;
  const nw = matrix[12] * x + matrix[13] * y + matrix[14] * z + matrix[15] * w;
  return [nx / nw, ny / nw, nz / nw];
};

export const multiplyVector = (matrix: matrix4, vector: any[]) => {
  const [x, y, z] = vector;
  const nx = matrix[0] * x + matrix[1] * y + matrix[2] * z;
  const ny = matrix[4] * x + matrix[5] * y + matrix[6] * z;
  const nz = matrix[8] * x + matrix[9] * y + matrix[10] * z;
  return [nx, ny, nz];
};

export const applyTransformationToMesh = (mesh: any, matrix: matrix4) => {
  const points = mesh.getPoints().getData();
  const normals = mesh.getPointData().getNormals().getData();
  // Transform points
  for (let i = 0; i < points.length; i += 3) {
    const point = [points[i], points[i + 1], points[i + 2]];
    const newPoint = multiplyPoint(matrix, point);
    points[i] = newPoint[0];
    points[i + 1] = newPoint[1];
    points[i + 2] = newPoint[2];
  }
  // Transform normals
  for (let i = 0; i < normals.length; i += 3) {
    const normal = [normals[i], normals[i + 1], normals[i + 2]];
    const newNormal = multiplyVector(matrix, normal);
    normals[i] = newNormal[0];
    normals[i + 1] = newNormal[1];
    normals[i + 2] = newNormal[2];
  }
  mesh.getPoints().modified();
  mesh.getPointData().getNormals().modified();
};
