import vtkActor from "@kitware/vtk.js/Rendering/Core/Actor";
import vtkDataArray from "@kitware/vtk.js/Common/Core/DataArray";
import vtkRenderer from "@kitware/vtk.js/Rendering/Core/Renderer";
import vtkLookupTable from "@kitware/vtk.js/Common/Core/LookupTable";
import { mat4 } from "gl-matrix";
import vtkPolyData from "@kitware/vtk.js/Common/DataModel/PolyData";

class OccluUtil {
  static createSpreadUpTransform(upActor: vtkActor, lowActor: vtkActor) {
    const upperBounds = upActor.getMapper()?.getInputData()?.getBounds();
    const lowerBounds = lowActor.getMapper()?.getInputData().getBounds();
    if (!upperBounds || !lowerBounds) {
      return mat4.create();
    }

    const box_y_up = upperBounds[3] - upperBounds[2];
    const box_y_low = lowerBounds[3] - lowerBounds[2];
    const box_y_max = (box_y_low + box_y_up) / 2;

    const box_x_up = upperBounds[1] - upperBounds[0];
    const box_x_low = lowerBounds[1] - lowerBounds[0];
    const box_x_max = box_x_low > box_x_up ? box_x_low : box_x_up;
    let dist_between_archs = 0;

    if (box_y_max > 48.0) {
      // handle for full arch overlap
      dist_between_archs = box_y_max + 14;
    } else {
      dist_between_archs = box_y_max + box_y_max / 4 + box_x_max / 4;
    }

    let m = mat4.fromValues(
      1,
      0,
      0,
      0,
      0,
      -1,
      0,
      dist_between_archs,
      0,
      0,
      -1,
      0,
      0,
      0,
      0,
      1
    );
    mat4.transpose(m, m);
    return m;
  }

  static setPointDataScalars(actor: vtkActor, values: Float32Array) {
    const pd = actor.getMapper()?.getInputData();
    if (!pd || !values || !pd.getPointData()) {
      return;
    }
    const scalars = vtkDataArray.newInstance({
      numberOfComponents: 1,
      values: values,
      size: values.length,
    });
    pd.getPointData().setScalars(scalars);
  }

  static setCameraforSpreadMesh(renderer: vtkRenderer) {
    if (!renderer || !renderer.getActiveCamera()) {
      return;
    }
    const cam = renderer.getActiveCamera();
    cam.setFocalPoint(0, 0, 0);
    cam.setPosition(0, 0, 1);
    cam.setViewUp(0, 1, 0);
    renderer.resetCamera();
  }

  // lookup table for occlusion mapping
  static createLutForOccluMapping() {
    const PurpleColor = [183.09, 0, 183.09, 255];
    const RedColor = [234.6, 20.4, 20.4, 255];
    const OrangeColor = [232.05, 135.15, 20.4, 255];
    const YellowColor = [234.6, 204, 20.4, 255];
    const GreenColor = [188.7, 244.8, 20.4, 255];
    const BlueColor = [20.4, 232.05, 188.7, 255];
    const NormalColor = [200, 200, 200, 255];

    const colors = [
      PurpleColor,
      RedColor,
      OrangeColor,
      YellowColor,
      GreenColor,
      BlueColor,
      BlueColor,
      BlueColor,
      BlueColor,
      BlueColor,
      BlueColor,
      BlueColor,
      NormalColor,
    ];
    return this.createLut(colors, this.occluMappingRange());
  }

  static occluMappingRange() {
    return { min: -0.15, max: 0.5 };
  }

  // lookup table for distance mapping
  static createLutForDistMapping() {
    const colors = [
      [0, 234, 255, 255], // cyan for value 0.0
      [223, 255, 0, 255], // yellow for value 0.2
      [255, 191, 0, 255], // orange for value 0.4
      [255, 127, 0, 255], // dark orange for value 0.6
      [255, 0, 0, 255], // red for value 0.8
      [183, 0, 183, 255], // magenta for value 1.0
    ];
    return this.createLut(colors, this.distMappingRange());
  }

  static createLut(colors: number[][], range: { min: number; max: number }) {
    const numOfColors = colors.length;
    const numOfComponents = 4; //RGBA
    const table = vtkDataArray.newInstance({
      numberOfComponents: numOfComponents,
      size: numOfColors * numOfComponents,
      dataType: "Uint8Array",
    });
    for (let i = 0; i < colors.length; ++i) {
      table.setTuple(i, colors[i]);
    }
    const lut = vtkLookupTable.newInstance();
    lut.setTable(table);
    lut.setMappingRange(range.min, range.max);
    lut.build();
    return lut;
  }

  static distMappingRange() {
    return { min: 0.0, max: 1.0 };
  }

  static spreadUpperandLowerArch(up: vtkActor, low: vtkActor) {
    const mat = this.createSpreadUpTransform(up, low);
    up.setUserMatrix(mat);
  }

  static setLookupTable(actor: vtkActor, lut: vtkLookupTable) {
    if (actor && actor.getMapper()) {
      actor.getMapper()?.setLookupTable(lut);
    }
  }

  static setMapperScalarRange(
    actor: vtkActor,
    range: { min: number; max: number }
  ) {
    actor?.getMapper()?.setScalarRange(range.min, range.max);
  }

  // static createOccluMesh(mesh: Mesh) {
  //   if (!mesh.actor) {
  //     return null;
  //   }
  //   const ret = new OccluMesh(mesh.name);
  //   ret.actor = vtkUtil.buildActorFromDeepCopy(vtkUtil.getPolyData(mesh.actor));
  //   return ret;
  // }

  static vec2Arr<ArrType>(
    vec: any,
    c: { new (arg: any): ArrType }
  ): ArrType | null {
    const sz = vec?.size();
    if (!sz) {
      return null;
    }
    const arr = new c(sz) as any;
    for (let i = 0; i < sz; ++i) {
      arr[i] = vec.get(i);
    }
    console.log("array length:", arr.length);
    return arr;
  }

  static fillVectorWithArr(vec: any, arr: any) {
    arr?.forEach((e: number) => {
      vec?.push_back?.(e);
    });
  }

  static arr2vec(arr: any, createVector: () => any) {
    const vec = createVector();
    arr?.forEach((e: number) => {
      vec?.push_back?.(e);
    });
    return vec;
  }

  static toMeshDataT(pd: vtkPolyData): MeshDataT {
    if (pd?.getPoints === undefined) {
      return new MeshDataT();
    }
    const verts = pd?.getPoints()?.getData();
    const cells = pd?.getPolys()?.getData();
    return {
      verts: Float32Array.from(verts),
      cells: Uint32Array.from(cells),
    };
  }

  static isMeshDataValid(data: MeshDataT) {
    return data.cells?.length > 0 && data.verts?.length > 0;
  }

  static isValidPolyData(pd: vtkPolyData) {
    if (!pd || !pd.getBounds) {
      return false;
    }
    return true;
  }

  static toMeshPairT(a: vtkPolyData, b: vtkPolyData): MeshPairT {
    const first = this.toMeshDataT(a);
    const second = this.toMeshDataT(b);
    return { first, second };
  }

  static setMappingScalars(
    actor: vtkActor,
    scalars: Float32Array,
    range: { min: number; max: number },
    lut: vtkLookupTable
  ) {
    this.setPointDataScalars(actor, scalars);
    this.setMapperScalarRange(actor, range);
    this.setLookupTable(actor, lut);
    actor.getMapper()?.setScalarVisibility(true);
    actor.getMapper()?.update();
  }
}

enum WasmCmdType {
  InitWasm = "cmd-init-wasm",
  InitWasmSuccess = "cmd-init-wasm-success",
  InitWasmFail = "cmd-init-wasm-fail",
  FindClosestPtsInput = "cmd-wasm-find_closet_pts_input",
  FindClosestPtsUpdate = "cmd-wasm-find_closet_pts_update",
  InputDataMeshCleaning = "cmd-input-data-mesh-cleaning",
  ComputeMeshCleaning = "cmd-compute-mesh-cleaning",
  StartActionMeshCleaning = "cmd-start-action-mesh-cleaning",
  EndActionMeshCleaning = "cmd-end-action-mesh-cleaning",
  ComputeOcclusion = "cmd-compute-occlusion",
  ComputeHoleFilling = "cmd-compute-hole-filling",
  ComputeAlignmentMatrix = "cmd-compute-alignmentMatrix",
  ComputeManualMatchingMatrix = "cmd-compute-manualMatching-matrix",
  ComputeDistanceMapping = "cmd-compute-distance-mapping",
}

interface IWorkerMessage {
  type: WasmCmdType;
  data?: any;
  responseId?: string;
}

class DataPairT<FirstType, SecondType = FirstType> {
  first: FirstType;
  second: SecondType;
  constructor() {
    this.first = null as FirstType;
    this.second = null as SecondType;
  }
}

class MeshDataT {
  verts: Float32Array;
  cells: Uint32Array;
  constructor() {
    this.verts = new Float32Array();
    this.cells = new Uint32Array();
  }
}

class MeshPairT extends DataPairT<MeshDataT> {}

export { OccluUtil, WasmCmdType, MeshDataT, MeshPairT, DataPairT };

export type { IWorkerMessage };
