import vtkActor from "@kitware/vtk.js/Rendering/Core/Actor";
import vtkMapper from "@kitware/vtk.js/Rendering/Core/Mapper";
import vtkPolyData from "@kitware/vtk.js/Common/DataModel/PolyData";
import vtkPlane from "@kitware/vtk.js/Common/DataModel/Plane";
import vtkCutter from "@kitware/vtk.js/Filters/Core/Cutter";
import { Vector3, RGBColor, Bounds } from "@kitware/vtk.js/types";
import { vtkUtil } from "../../utils/vtkUtils";
import { Mesh } from "../Mesh";
import vtkMath from "@kitware/vtk.js/Common/Core/Math";
import vtkSphereSource from "@kitware/vtk.js/Filters/Sources/SphereSource";
import vtkWidgetManager from "@kitware/vtk.js/Widgets/Core/WidgetManager";
import vtkImplicitPlaneWidget from "@kitware/vtk.js/Widgets/Widgets3D/ImplicitPlaneWidget";
import {
  Representation,
  Interpolation,
} from "@kitware/vtk.js/Rendering/Core/Property/Constants.js";

import { CrossSection } from "./CrossSection";
import { ISliceData } from "../../components/layouts/Measurement";
import vtkRenderer from "@kitware/vtk.js/Rendering/Core/Renderer";

const GOLDEN_COLOR = [0.996, 0.753, 0.059];
export default class CrossSectionHandler {
  private renderer: any;
  private pointsData = new Array<Array<number>>();
  private enabled = false;
  private meshes = new Array<Mesh>();
  private colors = new Array<RGBColor>();
  private plane = vtkPlane.newInstance();
  private slicesData = new Array<ISliceData>();
  private sliceNormal = new Float32Array(3);
  private selectedPointActor = vtkActor.newInstance();
  private sphereSource = vtkSphereSource.newInstance();
  private slicesDataCallback: (data: any) => void = () => {};
  private implicitPlaneWidget: any = null;
  private widgetManager: vtkWidgetManager = vtkWidgetManager.newInstance();
  private interactionCallback: () => void = () => {};
  private endInteractionCallback: (data: any) => void = () => {};
  private currentCrossSection: CrossSection | any = null;

  CUSTOM_STYLE = {
    active: {
      plane: {
        opacity: 0.5,
        color: [0, 0.61, 0.71],
      },
      normal: {
        opacity: 1,
        color: GOLDEN_COLOR,
      },
      origin: {
        opacity: 1,
        color: GOLDEN_COLOR,
      },
    },
    inactive: {
      plane: {
        opacity: 0.6,
        color: [0, 0.61, 0.71],
      },
      normal: {
        opacity: 1,
        color: [0.96, 0.96, 0.96],
      },
      origin: {
        opacity: 1,
        color: [0.96, 0.96, 0.96],
      },
    },
    static: {
      display2D: {
        representation: Representation.POINTS,
      },
      outline: {
        color: [1, 1, 1],
        opacity: 1,
        representation: Representation.WIREFRAME,
        interpolation: Interpolation.FLAT,
      },
    },
  };

  constructor(crossSection: CrossSection | null) {
    this.widgetManager.enablePicking();
    this.currentCrossSection = crossSection;
  }

  setRenderer(args: any) {
    this.renderer = args;
    this.widgetManager.setRenderer(this.renderer);
  }

  addMesh(mesh: Mesh) {
    this.meshes.push(mesh);
  }

  setColor(color: RGBColor) {
    this.colors.push(color);
  }

  addSlice(slice: ISliceData) {
    this.slicesData.push(slice);
  }

  clearMesh() {
    while (this.meshes.length > 0) {
      this.meshes.pop();
    }
  }

  clearSliceData() {
    while (this.slicesData.length > 0) {
      this.slicesData.pop();
    }
  }

  getSliceData() {
    return this.slicesData;
  }

  getSliceColor() {
    return this.colors;
  }

  setSlicesDataCallBack(cb: (data: any) => void) {
    this.slicesDataCallback = cb;
  }

  getSliceNormal() {
    return this.sliceNormal;
  }

  getMesh() {
    return this.meshes;
  }

  onPointSelection(args: any) {
    this.doPointSelection(args, false);
  }

  onPointPreview(args: any) {
    this.doPointSelection(args, true);
  }

  isEnabled() {
    return this.enabled;
  }

  setEnabled(arg: boolean) {
    if (this.enabled === arg) {
      return;
    }
    this.enabled = arg;
  }

  resetSelection() {
    while (this.pointsData.length > 0) {
      this.pointsData.pop();
      this.clearSelectedPointGraphics();
    }
  }

  updateCrossSection() {
    if (!this.currentCrossSection) return;

    this.buildPlaneWidget(
      this.currentCrossSection.origin,
      this.currentCrossSection.normal
    );
  }

  setInteractionCallback(cb: () => void) {
    this.interactionCallback = cb;
  }

  setEndInteractionCallback(cb: (data: any) => void) {
    this.endInteractionCallback = cb;
  }

  private doPointSelection(args: any, isPreview: boolean) {
    if (isPreview && this.pointsData.length < 1) {
      return;
    }
    const pos = args.position;
    const pt = vtkUtil.projectPoint2ViewPlane(pos, this.renderer, 50);

    if (this.pointsData.length > 2) {
      while (this.pointsData.length > 1) {
        this.pointsData.shift();
        this.log("points pop()");
      }
    }
    this.pointsData.push(pt);

    if (isPreview) {
      this.pointsData.pop();
    }
    if (this.pointsData.length === 2) {
      this.buildCutter();
    } else {
      this.createSelectedPtGraphics(pt);
    }
  }

  private buildPlaneWidget(origin: Vector3, normal: Vector3) {
    // Init implicite plane widget if needed
    if (!this.implicitPlaneWidget) {
      this.implicitPlaneWidget = vtkImplicitPlaneWidget.newInstance();
      const widget = this.widgetManager.addWidget(
        this.implicitPlaneWidget,
        undefined,
        {
          outlineVisible: false,
          handleSizeRatio: 0.05,
          axisScale: 0.2,
        }
      );
      (widget as any).setRepresentationStyle(this.CUSTOM_STYLE);

      widget.onInteractionEvent(() => {
        this.performCut();
        this.interactionCallback();
      });

      widget.onEndInteractionEvent(() => {
        this.currentCrossSection = this.getCrossSection();
        this.endInteractionCallback(this.currentCrossSection);
      });
    }

    this.implicitPlaneWidget
      .getWidgetState()
      .setNormal(normal[0], normal[1], normal[2]);
    this.implicitPlaneWidget
      .getWidgetState()
      .setOrigin(origin[0], origin[1], origin[2]);
    this.implicitPlaneWidget.setPlaceFactor(1.3);
    this.implicitPlaneWidget.placeWidget(this.getGlobalBounds());

    this.log("Finished building cutter");

    this.performCut();
    this.currentCrossSection = this.getCrossSection();
    this.endInteractionCallback(this.currentCrossSection);

    (this.renderer as vtkRenderer).getRenderWindow()?.render();
  }

  private buildCutter() {
    this.clearSliceData();
    this.clearSelectedPointGraphics();

    // Compute slice normal
    let cam = this.renderer?.getActiveCamera();
    let dir = cam?.getViewPlaneNormal();
    let vec2: Vector3 = [
      this.pointsData[0][0] - this.pointsData[1][0],
      this.pointsData[0][1] - this.pointsData[1][1],
      this.pointsData[0][2] - this.pointsData[1][2],
    ];
    let normal: Vector3 = [0, 0, 0];
    vtkMath.cross(dir, vec2, normal);
    vtkMath.normalize(normal);

    this.plane.setOrigin(
      this.pointsData[0][0],
      this.pointsData[0][1],
      this.pointsData[0][2]
    );
    this.plane.setNormal(normal[0], normal[1], normal[2]);

    let origin: Vector3 = [0, 0, 0];
    this.plane.projectPoint([0, 0, 0], origin);

    this.buildPlaneWidget(origin, normal);
  }

  private performCut() {
    if (!this.implicitPlaneWidget || !this.implicitPlaneWidget.getWidgetState())
      return;

    this.clearSliceData();
    this.clearSelectedPointGraphics();

    const origin = this.implicitPlaneWidget.getWidgetState().getOrigin();
    const normal = this.implicitPlaneWidget.getWidgetState().getNormal();

    for (let i = 0; i < normal.length; i++) {
      this.sliceNormal[i] = normal[i];
    }

    this.plane.setOrigin(origin[0], origin[1], origin[2]);
    this.plane.setNormal(normal[0], normal[1], normal[2]);

    for (let j = 0; j < this.meshes.length; j++) {
      const cutter = vtkCutter.newInstance();
      cutter.setCutFunction(this.plane);
      cutter.setInputData(this.getPolyData(j));

      const cutMapper = vtkMapper.newInstance();
      cutMapper.setInputConnection(cutter.getOutputPort());
      this.addSlice({
        scanId: this.meshes[j].scanId,
        polydata: cutMapper.getInputData(),
      });
    }
    this.slicesDataCallback({
      polydata: this.slicesData,
      normal: this.sliceNormal,
    });

    this.resetSelection();
    this.log("Finished performing cut");
  }

  private log(msg: any) {
    console.log("[CrossSectionHandler]", msg);
  }

  private getPolyData(index: number): vtkPolyData {
    return vtkUtil.getPolyData(this.meshes[index]?.actor);
  }

  private getGlobalBounds(): Bounds {
    let globalBounds: Bounds = [0.0, 0.0, 0.0, 0.0, 0.0, 0.0];
    for (let index = 0; index < this.meshes.length; ++index) {
      const polyData = this.getPolyData(index);
      if (polyData) {
        const bounds = polyData.getBounds();
        if (bounds[0] < globalBounds[0]) globalBounds[0] = bounds[0];
        if (bounds[1] > globalBounds[1]) globalBounds[1] = bounds[1];
        if (bounds[2] < globalBounds[2]) globalBounds[2] = bounds[2];
        if (bounds[3] > globalBounds[3]) globalBounds[3] = bounds[3];
        if (bounds[4] < globalBounds[4]) globalBounds[4] = bounds[4];
        if (bounds[5] > globalBounds[5]) globalBounds[5] = bounds[5];
      }
    }
    return globalBounds;
  }

  private getCrossSection(): CrossSection | null {
    try {
      const origin = this.implicitPlaneWidget.getWidgetState().getOrigin();
      const normal = this.implicitPlaneWidget.getWidgetState().getNormal();
      return new CrossSection(origin, normal);
    } catch (any) {
      return null;
    }
  }

  private createSelectedPtGraphics(pt: Vector3) {
    this.sphereSource.setCenter(pt);
    this.sphereSource.setThetaResolution(20);
    this.sphereSource.setPhiResolution(20);
    this.sphereSource.update();

    const mapper = vtkMapper.newInstance();
    mapper.setInputConnection(this.sphereSource.getOutputPort());
    this.selectedPointActor.setMapper(mapper);
    this.selectedPointActor
      .getProperty()
      ?.setColor(GOLDEN_COLOR[0], GOLDEN_COLOR[1], GOLDEN_COLOR[2]);
    this.selectedPointActor.setVisibility(true);
    this.renderer?.addActor(this?.selectedPointActor);
    this.renderer?.getActiveCamera()?.setClippingRange(0.001, 10000);
  }

  private clearSelectedPointGraphics() {
    this.selectedPointActor.setVisibility(false);
    this.renderer?.removeActor(this?.selectedPointActor);
  }
}
