import styled from "styled-components";
import { NavLink } from "react-router-dom";
import { colors } from "../../../theme";

export const ButtonsContainer = styled.div`
  position: fixed;
  left: 24px;
  top: 50%;
  display: flex;
  flex-direction: column;
  gap: 2px;
  transform: translateY(-50%);
`;

export const StyledNavLink = styled(NavLink)<{ $disabled: boolean }>`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 62px;
  height: 62px;
  border: 2px solid ${colors["gray-1"]};
  background-color: white;
  color: inherit;
  opacity: ${({ $disabled }) => ($disabled ? 0.5 : 1)};
  cursor: ${({ $disabled }) => ($disabled ? "default" : "pointer")};
  text-decoration: none;

  &:hover:not(.active) {
    background-color: ${({ $disabled }) => !$disabled && colors["blue-2"]};
  }

  &.active {
    border: 2px solid transparent;
    background-color: ${colors["blue-1"]};
    color: white;

    &:hover {
      background-color: ${({ $disabled }) => !$disabled && colors["blue-1"]};
    }
  }
`;

export const ExportButon = styled.button`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 62px;
  height: 62px;
  border: 2px solid ${colors["gray-1"]};
  background-color: white;
  outline: none;
  cursor: pointer;

  &:hover,
  &.active {
    background-color: ${colors["blue-2"]};
  }
`;
