import vtkActor from "@kitware/vtk.js/Rendering/Core/Actor";

export default class SliceViewerHandler {
  private renderer: any;
  private sliceActors = new Array<vtkActor>();

  setRenderer(args: any) {
    this.renderer = args;
  }

  addSliceActor(actor: vtkActor) {
    this.sliceActors.push(actor);
  }

  private log(msg: any) {
    console.log("[SliceViewerHandler]", msg);
  }

  clearSliceGraphics() {
    for (let actor of this.sliceActors) {
      actor.setVisibility(false);
      this.renderer?.removeActor(actor);
    }
  }
}
