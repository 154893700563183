import React, { useState, useMemo, useContext } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import {
  Backdrop,
  Button,
  Buttons,
  Content,
  Dialog,
  Title,
  InfoImage,
  Instructions,
  CancelButton,
  ScanLabelContainer,
  CustomCheckbox,
  ScanLabel,
} from "./DialogBox.styled";
import infoImage from "../../../images/info.png";
import { formatDateFromString } from "../../../utils/dateTime";
import { AppGlobalDataContext } from "../../../providers/AppGlobalDataProvider";

interface NormalMesh {
  acquisitionDateTime: string;
  id: string;
  scanId: string;
  preferColorHex: string;
}

export interface GroupedMeshes {
  upperJawMeshes: NormalMesh[];
  lowerJawMeshes: NormalMesh[];
}

interface DialogBoxProps {
  meshes?: NormalMesh[];
  onClose: () => void;
  onConfirm: (selectedScans: string[]) => void;
  groupedMeshes?: GroupedMeshes;
  cancelAsHide?: boolean;
}

const getTitle = (path: string, translations: { [key: string]: string }) => {
  switch (path) {
    case "/distance-mapping":
      return translations["DISTANCE_MAPPING"];
    case "/occlusion-proximity":
      return translations["OCCLUSION_EVOLUTION"];
    case "/measurement":
      return translations["MEASUREMENT"];
    case "/point-matching":
      return translations["POINT_BASED_MATCHING"];
    default:
      return "Dialog";
  }
};

const getInstructions = (
  path: string,
  translations: { [key: string]: string }
) => {
  switch (path) {
    case "/distance-mapping":
      return translations["PLEASE_SELECT_2SCANS"];
    case "/point-matching":
      return translations["PLEASE_SELECT_1SCAN_TO_ALIGN_WITH_LATEST_SCAN"];
    case "/measurement":
      return translations["PLEASE_SELECT_2SCANS"];
    case "/occlusion-proximity":
      return "Please select a scan:";
    default:
      return translations["PLEASE_SELECT_2SCANS"];
  }
};

const getUniqueScans = (meshList: NormalMesh[]) => {
  const scans = meshList?.reduce(
    (
      acc: { [key: string]: { acquisitionDateTime: string; color: string } },
      mesh
    ) => {
      if (!acc[mesh.scanId]) {
        acc[mesh.scanId] = {
          acquisitionDateTime: mesh.acquisitionDateTime,
          color: mesh.preferColorHex,
        };
      }
      return acc;
    },
    {}
  );
  return scans;
};
const DialogBox: React.FC<DialogBoxProps> = ({
  meshes,
  onClose,
  onConfirm,
  groupedMeshes,
  cancelAsHide = false,
}) => {
  const [selectedScans, setSelectedScans] = useState<string[]>([]);
  const { pathname } = useLocation();
  const navigate = useNavigate();
  const { translations } = useContext(AppGlobalDataContext);

  const title = useMemo(
    () => getTitle(pathname, translations),
    [pathname, translations]
  );
  const instructions = useMemo(
    () => getInstructions(pathname, translations),
    [pathname, translations]
  );

  const relevantMeshes = useMemo(() => {
    return pathname === "/distance-mapping" || pathname === "/point-matching"
      ? groupedMeshes?.upperJawMeshes.concat(groupedMeshes?.lowerJawMeshes) ||
          []
      : meshes || [];
  }, [pathname, groupedMeshes, meshes]);

  const filteredMeshes = useMemo(() => {
    return relevantMeshes.filter(
      (mesh, _, arr) => arr.filter((m) => m.scanId === mesh.scanId).length > 1
    );
  }, [relevantMeshes]);

  const isRadio = useMemo(
    () => pathname === "/occlusion-proximity",
    [pathname]
  );

  const isLatestAlwaysChosen = useMemo(
    () => pathname === "/point-matching",
    [pathname]
  );

  const uniqueScans = useMemo(() => {
    const collection =
      pathname === "/occlusion-proximity"
        ? getUniqueScans(groupedMeshes ? groupedMeshes.upperJawMeshes : [])
        : getUniqueScans(filteredMeshes);
    if (isLatestAlwaysChosen) {
      // set the first checkbox chosen
      const id = Object.keys(collection)[0];
      setSelectedScans([id]);
    }
    return collection;
  }, [pathname, groupedMeshes, filteredMeshes, isLatestAlwaysChosen]);

  const handleSelect = (event: React.ChangeEvent<HTMLInputElement>) => {
    const scanId = event.target.value;
    if (selectedScans.includes(scanId)) {
      setSelectedScans(selectedScans.filter((id) => id !== scanId));
    } else if (isRadio) {
      setSelectedScans([scanId]);
    } else if (selectedScans.length < 2) {
      setSelectedScans([...selectedScans, scanId]);
    }
  };

  const handleConfirm = () => {
    if (selectedScans.length === 2 || (isRadio && selectedScans.length === 1)) {
      onConfirm(selectedScans);
      onClose();
    }
  };

  const handleCancel = () => {
    onClose();
    if (!isRadio && !cancelAsHide) navigate("/");
  };

  if (Object.keys(uniqueScans).length <= 2) {
    return null;
  }

  return (
    <Backdrop>
      <Dialog>
        <InfoImage src={infoImage} alt="Info" />
        <Title>{title}</Title>
        <Instructions>{instructions}</Instructions>
        <Content>
          {Object.entries(uniqueScans).map(
            ([scanId, { acquisitionDateTime, color }], index) => (
              <ScanLabelContainer key={scanId}>
                <CustomCheckbox
                  id={scanId}
                  name="scan"
                  value={scanId}
                  onChange={handleSelect}
                  checked={selectedScans.includes(scanId)}
                  disabled={
                    (isLatestAlwaysChosen && 0 === index) ||
                    (selectedScans.length === 2 &&
                      !selectedScans.includes(scanId))
                  }
                  color={color}
                />
                <ScanLabel
                  htmlFor={scanId}
                  disabled={
                    selectedScans.length === 2 &&
                    !selectedScans.includes(scanId)
                  }
                  selected={selectedScans.includes(scanId)}
                >
                  {formatDateFromString(acquisitionDateTime)}
                </ScanLabel>
              </ScanLabelContainer>
            )
          )}
        </Content>
        <Buttons>
          <CancelButton onClick={handleCancel}>
            {" "}
            {translations["CANCEL"]}
          </CancelButton>
          <Button
            onClick={handleConfirm}
            disabled={
              (!isRadio && selectedScans.length !== 2) ||
              (isRadio && selectedScans.length < 1)
            }
          >
            {translations["OK"]}
          </Button>
        </Buttons>
      </Dialog>
    </Backdrop>
  );
};

export default DialogBox;
