import styled from "styled-components";
import { colors } from "../../../theme";
import { ColorIndicator } from "../ColorManipulationTool/ColorManipulation.styled";
import { Dialog } from "@mui/material";

export const StyledLayout = styled.div`
  position: fixed;
  right: 0;
  top: 220px;
  display: flex;
  height: 600px;
  z-index: 2;
`;

export const CommentsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 420px;
  height: 752px;
  border: 1px solid ${colors["gray-1"]};
  border-radius: 10px 0px 0px 10px;
  background-color: white;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
`;

export const HorizontalLine = styled.hr`
  height: 1px;
  flex-grow: 1;
`;

export const MessageConatner = styled.div`
  padding: 19px;
`;

export const CommentText = styled.p`
  margin: 10px 0 0 0;
  font-size: 18px;
  color: ${colors["dark-gray"]};
`;

export const CommentsList = styled.ul`
  max-height: 735px;
  padding: 0;
  color: ${colors["dark-gray"]};
  list-style-type: none;
  overflow-y: auto;

  &::-webkit-scrollbar {
    width: 12px;
  }

  &::-webkit-scrollbar-track {
    border-radius: 2px;
  }

  &::-webkit-scrollbar-thumb {
    border: 2px solid transparent;
    background-clip: padding-box;
    border-radius: 10px;
    background-color: ${colors["gray-1"]};
  }
`;

export const SearchBarContainer = styled.div`
  display: flex;
  align-items: center;
  margin-top: 2px;
  padding: 8px;
  border-radius: 4px;
  background-color: white;
`;

export const SearchBarInput = styled.input`
  flex-grow: 1;
  border: none;
  background: none;
  color: ${colors["dark-gray"]};
  font-size: 18px;
  padding: 0;
  margin-left: 20px;
  outline: none;
`;

export const CommentScanInfo = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
  padding: 15px 30px 0 30px;
  margin-bottom: 20px;
`;

export const CommentsColorIndicator = styled(ColorIndicator)`
  width: 18px;
  height: 18px;
  margin: 0;
`;

export const DateSpan = styled.span`
  font-size: 18px;
`;

export const CommentContainer = styled.div<{ $focus: boolean }>`
  background-color: ${({ $focus }) =>
    $focus ? colors["blue-5"] : "transperant"};
  padding: 8px 30px;
  cursor: pointer;
`;

export const CommentHeader = styled.div`
  display: flex;
  align-items: center;
  gap: 15px;
`;

export const CommentTimeElapsedSpan = styled.span`
  font-size: 18px;
  color: ${colors["gray-1"]};
`;

export const ActionButtonsContainer = styled.div`
  display: flex;
  gap: 20px;
  margin-left: auto;
`;

export const ActionButton = styled.button`
  padding: 0;
  border: none;
  outline: none;
  background-color: transparent;
  cursor: pointer;
`;

export const DeleteCommentDialog = styled(Dialog)`
  & div[role="dialog"] {
    max-width: unset;
  }
`;

export const PromptContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 10px;
  padding: 48px 30px 30px 30px;
  color: ${colors["dark-gray"]};
  background-color: ${colors["light-gray"]};

  & p {
    font-size: 22px;
    margin-top: 0;
  }
`;

export const DialogTitle = styled.h2`
  font-size: 36px;
  font-weight: 400;
  margin: 10px;
`;

export const PromptButtonsContainer = styled.div`
  display: flex;
  gap: 30px;
`;

export const DeleteButton = styled.button`
  width: 250px;
  height: 62px;
  padding: 12px 24px;
  border: 2px solid transparent;
  border-radius: 5px;
  color: white;
  background-color: ${colors["red"]};
  font-size: 22px;
  text-align: center;
  outline: none;
  cursor: pointer;
`;

export const CancelButton = styled(DeleteButton)`
  background-color: transparent;
  color: ${colors["dark-gray"]};
  border-color: ${colors["gray-1"]};
`;

export const HideCommentsButton = styled.button`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
  width: 228px;
  min-height: 60px;
  margin: auto auto 20px auto;
  border: 2px solid ${colors["gray-1"]};
  border-radius: 30px;
  background-color: transparent;
  color: ${colors["dark-gray"]};
  font-size: 22px;
  outline: none;
  cursor: pointer;

  &:hover {
    background-color: ${colors["dark-gray"]}15;
  }

  &:active {
    background-color: ${colors["dark-gray"]}30;
  }
`;
