import React from "react";

const InformationIcon: React.FC = () => {
  return (
    <svg
      width="36"
      height="36"
      viewBox="0 0 36 36"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M18 34C26.8366 34 34 26.8366 34 18C34 9.16344 26.8366 2 18 2C9.16344 2 2 9.16344 2 18C2 26.8366 9.16344 34 18 34ZM18 36C27.9411 36 36 27.9411 36 18C36 8.05887 27.9411 0 18 0C8.05887 0 0 8.05887 0 18C0 27.9411 8.05887 36 18 36ZM17 16C17 15.4477 17.4477 15 18 15C18.5523 15 19 15.4477 19 16V26C19 26.5523 18.5523 27 18 27C17.4477 27 17 26.5523 17 26V16ZM18 12.2C18.8837 12.2 19.6 11.4837 19.6 10.6C19.6 9.71634 18.8837 9 18 9C17.1164 9 16.4 9.71634 16.4 10.6C16.4 11.4837 17.1164 12.2 18 12.2Z"
        fill="#4D4D4D"
      />
    </svg>
  );
};

export default InformationIcon;
