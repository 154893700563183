import React from "react";

const ArrowUp: React.FC = () => {
  return (
    <svg
      width="20"
      height="12"
      viewBox="0 0 20 12"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M10.7071 0.792893L19.7071 9.79289C20.0976 10.1834 20.0976 10.8166 19.7071 11.2071C19.3166 11.5976 18.6834 11.5976 18.2929 11.2071L10 2.91421L1.70711 11.2071C1.31658 11.5976 0.683417 11.5976 0.292893 11.2071C-0.0976311 10.8166 -0.0976311 10.1834 0.292893 9.79289L9.29289 0.792893C9.68342 0.402369 10.3166 0.402369 10.7071 0.792893Z"
        fill="#4D4D4D"
      />
    </svg>
  );
};

export default ArrowUp;
