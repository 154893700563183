import styled from "styled-components";
import { colors } from "../../../theme";

export const CommentBoxContainer = styled.div<{ x: number; y: number }>`
  position: fixed;
  bottom: ${({ y }) => y}px;
  left: ${({ x }) => x + 20}px;
  transform: translateY(50%);
  width: 396px;
  height: auto;
  padding: 18px 18px 5px 18px;
  border: 1px solid ${colors["gray-1"]};
  border-radius: 10px;
  background-color: white;
  z-index: 10;

  &::before {
    content: "";
    position: absolute;
    top: 50%;
    left: -10px;
    transform: translateY(-50%);
    border-top: 10px solid transparent;
    border-right: 10px solid white;
    border-bottom: 10px solid transparent;
  }
`;

export const CommentBoxHeader = styled.div`
  display: flex;
  align-items: center;
  gap: 15px;
  margin-bottom: 5px;
`;

export const DateSpan = styled.span`
  font-size: 18px;
  color: ${colors["gray-1"]};
`;

export const CommentInput = styled.textarea`
  width: 100%;
  min-height: 72px;
  padding: 10px;
  border: none;
  outline: none;
  box-shadow: none;
  resize: none;
  color: ${colors["dark-gray"]};
  font-size: 18px;
`;

export const ActionButtonsContainer = styled.div`
  display: flex;
  gap: 20px;
  margin-left: auto;
`;

export const CommentActionButton = styled.button`
  padding: 0;
  border: none;
  outline: none;
  background-color: transparent;
  cursor: pointer;
`;

export const SaveButton = styled.button`
  width: 250px;
  height: 62px;
  padding: 12px 24px;
  margin-bottom: 10px;
  border: 2px solid transparent;
  border-radius: 5px;
  color: white;
  background-color: ${colors["blue-1"]};
  font-size: 22px;
  text-align: center;
  outline: none;
  cursor: pointer;
`;

export const CancelButton = styled(SaveButton)`
  background-color: transparent;
  color: ${colors["dark-gray"]};
  border-color: ${colors["gray-1"]};
`;
