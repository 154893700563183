import { Dialog } from "@mui/material";
import styled from "styled-components";
import { colors } from "../../../theme";

export const StyledHeader = styled.header`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 74px;
  padding-left: 15px;

  & img {
    max-width: 600px;
    margin-right: auto;
  }

  & span {
    width: 50%;
    padding-left: 120px;
    font-size: 32px;
    color: ${colors["dark-gray"]};
  }
`;

export const IconButton = styled.button`
  width: 74px;
  height: 74px;
  padding: 0;
  border: none;
  border-radius: 50%;
  background-color: transparent;
  outline: none;
  cursor: pointer;
`;

export const VersionContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 20px;
  padding: 10px 70px;
  color: ${colors["dark-gray"]};

  & img {
    width: 100%;
    max-width: 600px;
    margin-bottom: 20px;
  }

  & span {
    font-size: 22px;
    margin-bottom: 70px;
  }

  & p {
    font-size: 18px;
  }
`;

export const VersionDialogTitle = styled.h2`
  font-size: 34px;
  font-weight: 400;
  margin: 10px;
`;

export const StyledVersionDialog = styled(Dialog)`
  & div[role="dialog"] {
    max-width: 1200px;
    min-width: 800px;
    min-height: 600px;
    transition: all 0.3s ease;
  }
`;

export const UserGuideContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
  height: 80vh;
  width: 80vw;
  max-height: 800px;
  max-width: 1200px;
  padding-bottom: 30px;
  padding-right: 2px;
  background-color: ${colors["light-gray"]};

  & .pdf-viewer {
    flex-grow: 1;
    width: 100%;
    height: 100%;
    overflow-x: hidden;
    padding-left: 20px;
    padding-right: 20px;
    overflow-y: auto;

    &::-webkit-scrollbar {
      width: 12px;
    }

    &::-webkit-scrollbar-track {
      border-radius: 18px;
    }

    &::-webkit-scrollbar-thumb {
      border: 2px solid transparent;
      background-clip: padding-box;
      border-radius: 10px;
      background-color: ${colors["gray-1"]};
    }
  }

  & .pdf-navigation {
    display: flex;
    justify-content: space-between;
    width: 100%;
    padding: 10px;
  }

  & .pdf-page {
    padding: 20px;
    background-color: white;
  }

  .pdf-viewer .react-pdf__message {
    display: none;
  }

  & .close-button {
    margin-top: 25px;
    min-width: 250px;
    height: 62px;
    cursor: pointer;
    align-self: center;
    font-size: 22px;
    border: 2px solid ${colors["gray-1"]};
    border-radius: 5px;
    background-color: transparent;
    color: ${colors["dark-gray"]};
  }
`;
