import { Vector3 } from "@kitware/vtk.js/types";

export class Measurement {
  point1: Vector3 = [0, 0, 0];
  point2: Vector3 = [0, 0, 0];
  textPosition: Vector3 = [0, 0, 0];
  widgetHandle: any;

  constructor(widgetHandle: any) {
    this.setWidgetHandle(widgetHandle);
  }

  setWidgetHandle(widgetHandle: any) {
    this.widgetHandle = widgetHandle;
    this.updatePositions();

    // Sync class positions according to widget after each widget interaction
    this.widgetHandle.onEndInteractionEvent(() => {
      this.updatePositions();
    });
  }

  getDistanceText(): string {
    return this.widgetHandle.getWidgetState().getText().getText();
  }

  updatePositions() {
    const widgetState = this.widgetHandle.getWidgetState();
    this.point1 = widgetState.getHandle1().getOrigin();
    this.point2 = widgetState.getHandle2().getOrigin();
    this.textPosition = widgetState.getText().getOrigin();
  }
}
