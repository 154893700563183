import styled from "styled-components";
import { colors } from "../../../theme";
import { Dialog } from "@mui/material";

export const MeasurementsWrapper = styled.div`
  position: fixed;
  right: 0;
  top: 400px;
  display: flex;
  flex-direction: column;
  width: 240px;
  height: 509px;
  border: 1px solid ${colors["gray-1"]};
  border-radius: 10px 0px 0px 10px;
  background-color: white;
  z-index: 2;
`;

export const PanelTitle = styled.h2`
  margin: 0;
  color: ${colors["dark-gray"]};
  font-size: 24px;
  padding-top: 15px;
  font-weight: 400;
  text-align: center;
  height: 70px;
`;

export const MeasurementList = styled.ul`
  list-style-type: none;
  overflow-y: auto;
  padding: 0;
  border-bottom-left-radius: 6px;
  overflow: auto;
  margin-top: 0px;

  &::-webkit-scrollbar {
    width: 12px;
  }

  &::-webkit-scrollbar-track {
    border-radius: 2px;
  }

  &::-webkit-scrollbar-thumb {
    border: 2px solid transparent;
    background-clip: padding-box;
    border-radius: 10px;
    background-color: ${colors["gray-1"]};
  }
`;

export const DeleteMeasurementDialog = styled(Dialog)`
  & div[role="dialog"] {
    max-width: unset;
  }
`;

export const PromptContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 10px;
  padding: 48px 30px 30px 30px;
  color: ${colors["dark-gray"]};
  background-color: ${colors["light-gray"]};

  & p {
    font-size: 22px;
    margin-top: 0;
  }
`;

export const DialogTitle = styled.h2`
  font-size: 36px;
  font-weight: 400;
  margin: 10px;
`;

export const PromptButtonsContainer = styled.div`
  display: flex;
  gap: 30px;
`;

export const DeleteButton = styled.button`
  width: 250px;
  height: 62px;
  padding: 12px 24px;
  border: 2px solid transparent;
  border-radius: 5px;
  color: white;
  background-color: ${colors["red"]};
  font-size: 22px;
  text-align: center;
  outline: none;
  cursor: pointer;
`;

export const CancelButton = styled(DeleteButton)`
  background-color: transparent;
  color: ${colors["dark-gray"]};
  border-color: ${colors["gray-1"]};
`;

export const MeasurementListItem = styled.li<{ $focus: boolean }>`
  display: flex;
  align-items: center;
  height: 60px;
  font-size: 22px;
  cursor: pointer;

  &:nth-child(odd) {
    background-color: ${({ $focus }) =>
      $focus ? colors["blue-5"] : colors["light-gray"]};
  }

  &:nth-child(even) {
    background-color: ${({ $focus }) =>
      $focus ? colors["blue-5"] : "transparent"};
  }

  & button {
    margin-left: auto;
  }

  & svg {
    margin: 0 10px 0 15px;
  }
`;

export const AddMeasurementButton = styled.button<{ $toggled: boolean }>`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
  width: 220px;
  min-height: 60px;
  margin: auto auto 15px auto;
  background-color: ${({ $toggled }) =>
    $toggled ? colors["blue-1"] : "transparent"};
  border: 2px solid
    ${({ $toggled }) => ($toggled ? "transparent" : colors["gray-1"])};
  border-radius: 30px;
  font-size: 22px;
  outline: none;
  cursor: pointer;

  &:hover {
    background-color: ${({ $toggled }) =>
      $toggled ? colors["blue-1"] : colors["blue-2"]};
  }

  &:hidden {
    visibility: hidden;
  }
`;
