import styled from "styled-components";
import { colors } from "../../theme";

export const StyledLayout = styled.div`
  width: 100%;
  height: calc(100vh - 74px);
  overflow: hidden;
  background: rgb(245, 245, 245);
`;

export const Viewport = styled.div`
  width: calc(100vw - 150px - 498px);
  height: calc(100vh - 74px);
  position: fixed;
  left: 150px;
`;

export const ControlPanel = styled.div`
  position: fixed;
  right: 0;
  top: calc(50% - 260px);
  width: 460px;
  height: 601px;
  background: #fff;
  border: 1px solid #afafaf;
  border-radius: 10px 0 0 10px;
  padding: 30px;
`;

export const PanelTitle = styled.div`
  font-size: 36px;
  margin-bottom: 30px;
`;

export const TipText = styled.div`
  display: flex;
  justify-content: left;
  margin-bottom: 18px;

  & > img {
    width: 32px;
    height: 32px;
    margin-right: 12px;
  }
`;

export const MatchingMethodGroup = styled.div`
  display: flex;
  justify-content: flex-start;
  gap: 30px;
  margin-top: 30px;
`;

export const IconButton = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 62px;
  height: 62px;
  border: 2px solid ${colors["gray-1"]};
  background-color: white;
  color: inherit;
  text-decoration: none;

  &:hover {
    background-color: ${colors["blue-2"]};
  }
`;

export const GroupLabel = styled.div`
  border: none;
  width: 124px;
  font-size: 22px;
  text-align: right;
`;

export const ColorIndicator = styled.div`
  width: 24px;
  height: 24px;
  border: 2px solid transparent;
  border-radius: 4px;
  margin-right: 10px;
`;

export const LeftTopLabel = styled.div`
  position: fixed;
  width: 302px;
  height: 24px;
  top: calc(50% - 22px);
  left: calc(18.35% - 111px);
  font-size: 22px;

  & > div {
    width: 312px;
    height: 24px;
    display: flex;
    justify-content: flex-start;
    & > span {
      line-height: 24px;
      margin-left: 20px;
    }
  }
`;

export const LeftBottomLabel = styled.div`
  position: fixed;
  width: 302px;
  height: 24px;
  bottom: 38px;
  left: calc(18.35% - 111px);
  font-size: 22px;

  & > div {
    width: 312px;
    height: 24px;
    display: flex;
    justify-content: flex-start;
    & > span {
      line-height: 24px;
      margin-left: 20px;
      margin-right: 10px;
    }
    & > image {
      height: 24px;
      cursor: pointer;
    }
  }
`;

export const RightLabel = styled.div`
  position: fixed;
  bottom: 38px;
  right: calc(26% - 10px);
`;

export const HorizonDivider = styled.div`
  position: fixed;
  width: calc(36.69% - 297px);
  height: 2px;
  top: calc(50% + 36px);
  left: 180px;
  background: #4d4d4d;
  opacity: 30%;
`;

export const VerticleDivider = styled.div`
  position: fixed;
  height: calc(100% - 140px);
  width: 2px;
  top: 106px;
  left: calc(36.69% - 87px);
  background: #4d4d4d;
  opacity: 30%;
`;
