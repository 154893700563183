import React, { useCallback, useContext, useState } from "react";
import {
  Backdrop,
  ImportButton,
  Buttons,
  Content,
  Dialog,
  Title,
  CancelButton,
  StyledInputLabel,
  StyledSpan,
  InputWrapper,
  InputContainer,
  StyledDateInputLabel,
  DateInputContainer,
} from "./ImportDataDialogBox.styled";
import ThreeDotsIcon from "../../svg-icons/ThreeDotsIcon";
import UpperJawIcon from "../../svg-icons/UpperJawIcon";
import LowerJawIcon from "../../svg-icons/LowerJawIcon";
import { arrayBufferToIMeshNode } from "../../../utils/vtkUtils";
import { AppGlobalDataContext } from "../../../providers/AppGlobalDataProvider";
import { NormalMesh, buildMesh } from "../../../model/Mesh";
import {
  formatDate,
  formatDateToIDicomTimeString,
} from "../../../utils/dateTime";
import uuid from "react-uuid";
import { hexToRgb } from "../../../utils/hexToRgb";
import { IMeshNode } from "@envistaco/dicom-reader";
import { useAppInsightsContext } from "@microsoft/applicationinsights-react-js";
import CalendarIcon from "../../svg-icons/CalendarIcon";

interface ImportDataDialogBoxProps {
  onClose: () => void;
}

const ImportDataDialogBox: React.FC<ImportDataDialogBoxProps> = ({
  onClose,
}) => {
  const {
    normalMeshes,
    opacityValues,
    translations,
    setNormalMeshes,
    setOpacityValues,
    setGroupedMeshesByDicomId,
  } = useContext(AppGlobalDataContext);
  const [maxillaryFile, setMaxillaryFile] = useState<File>();
  const [mandibularFile, setMandibularFile] = useState<File>();
  const [acquisitionDateTime, setAcquisitionDateTime] = useState<Date>(
    new Date()
  );
  const appInsights = useAppInsightsContext();

  const readFile = useCallback(
    (file: File, jawPart: "Maxillary" | "Mandibular"): Promise<IMeshNode> => {
      return new Promise((resolve) => {
        const reader = new FileReader();
        reader.onloadend = () =>
          resolve(
            arrayBufferToIMeshNode(
              reader.result as ArrayBuffer,
              `${jawPart} Anatomy`,
              file?.name?.split(".")?.pop()?.toUpperCase() === "STL"
                ? "STL_FILE"
                : "PLY_FILE"
            )
          );
        reader.readAsArrayBuffer(file);
      });
    },
    []
  );

  const handleImportDataDialogConfirm = useCallback(async () => {
    const meshes: IMeshNode[] = [];
    const scanId = "uploadId-" + uuid();
    const color = hexToRgb("#98AAB2");
    const dateTime = formatDateToIDicomTimeString(acquisitionDateTime);

    if (maxillaryFile) {
      const mesh: IMeshNode = await readFile(maxillaryFile, "Maxillary");
      meshes.push(mesh);
    }
    if (mandibularFile) {
      const mesh: IMeshNode = await readFile(mandibularFile, "Mandibular");
      meshes.push(mesh);
    }

    const newNormalMeshes = await Promise.all(
      meshes.map((mesh) => buildMesh(mesh, color, dateTime, scanId))
    );

    const allMeshes: NormalMesh[] = [...normalMeshes, ...newNormalMeshes].sort(
      (a, b) => Number(b.acquisitionDateTime) - Number(a.acquisitionDateTime)
    );
    const groupsMeshes: {
      [key: string]: {
        meshes: NormalMesh[];
        isActive: boolean;
      };
    } = {};
    const meshesOpacity: {
      [key: string]: number;
    } = { ...opacityValues };

    allMeshes.forEach((mesh) => {
      if (!groupsMeshes[mesh.scanId]) {
        groupsMeshes[mesh.scanId] = { meshes: [], isActive: mesh.isActive };
      }
      groupsMeshes[mesh.scanId].meshes.push(mesh);

      if (meshesOpacity[mesh.id] === undefined) {
        meshesOpacity[mesh.id] = 100;
      }
    });

    setNormalMeshes(allMeshes);
    setGroupedMeshesByDicomId(groupsMeshes);
    setOpacityValues(meshesOpacity);

    // Log the import event to Application Insights
    appInsights.trackEvent({
      name: "Meshes Imported",
      properties: { count: newNormalMeshes.length },
    });

    onClose();
  }, [
    maxillaryFile,
    mandibularFile,
    opacityValues,
    normalMeshes,
    readFile,
    onClose,
    setGroupedMeshesByDicomId,
    setNormalMeshes,
    setOpacityValues,
    appInsights,
    acquisitionDateTime,
  ]);

  const handleFileChange = (
    event: React.ChangeEvent<HTMLInputElement>,
    jawType: "Maxillary" | "Mandibular"
  ) => {
    if (event.target.files) {
      const newFiles = Object.values(Array.from(event.target.files));

      jawType === "Maxillary"
        ? setMaxillaryFile(newFiles[0])
        : setMandibularFile(newFiles[0]);
    }
  };

  const handleCancel = () => {
    onClose();
  };

  return (
    <Backdrop>
      <Dialog>
        <Title>{translations["IMPORT_NEW_DATA"]}</Title>
        <Content>
          <InputWrapper>
            <UpperJawIcon color="#fff" />
            <InputContainer>
              <StyledSpan>{translations["MAXILLARY"]}</StyledSpan>
              <StyledInputLabel $hasValue={!!maxillaryFile}>
                {maxillaryFile
                  ? maxillaryFile.name
                  : translations["SELECT_FILE"]}
                <input
                  type="file"
                  accept=".stl"
                  onChange={(e) => handleFileChange(e, "Maxillary")}
                />
                <ThreeDotsIcon />
              </StyledInputLabel>
            </InputContainer>
          </InputWrapper>

          <InputWrapper>
            <LowerJawIcon color="#fff" />
            <InputContainer>
              <StyledSpan>{translations["MANDIBULAR"]}</StyledSpan>
              <StyledInputLabel $hasValue={!!mandibularFile}>
                {mandibularFile
                  ? mandibularFile.name
                  : translations["SELECT_FILE"]}

                <input
                  type="file"
                  accept=".stl"
                  onChange={(e) => handleFileChange(e, "Mandibular")}
                />
                <ThreeDotsIcon />
              </StyledInputLabel>
            </InputContainer>
          </InputWrapper>

          <DateInputContainer>
            <StyledSpan>Date</StyledSpan>
            <StyledDateInputLabel $hasValue={true}>
              <CalendarIcon />
              {formatDate(acquisitionDateTime)}
              <input
                type="date"
                max={new Date().toISOString().split("T")[0]}
                onChange={(e) =>
                  setAcquisitionDateTime(new Date(e.target.value + "T00:00:00"))
                }
              />
            </StyledDateInputLabel>
          </DateInputContainer>
        </Content>
        <Buttons>
          <CancelButton onClick={handleCancel}>
            {translations["CANCEL"]}
          </CancelButton>
          <ImportButton
            onClick={handleImportDataDialogConfirm}
            disabled={!maxillaryFile && !mandibularFile}
          >
            {translations["IMPORT"]}
          </ImportButton>
        </Buttons>
      </Dialog>
    </Backdrop>
  );
};

export default ImportDataDialogBox;
