import styled from "styled-components";
import { colors } from "../../theme";

export const StyledLeftViewPage = styled.div`
  width: 50%;
  height: calc(100vh - 74px);
  overflow: hidden;
  float: left;
`;

export const StyledRightViewPage = styled.div`
  position: relative;
  width: 50%;
  height: calc(100vh - 74px);
  overflow: hidden;
  float: right;
`;

export const MeshSelectorDiv = styled.div`
  position: fixed;
  top: 200px;
  right: 0;
  display: flex;
  height: 180px;
  overflow: auto;
  z-index: 2;

  &::-webkit-scrollbar {
    width: 12px;
  }

  &::-webkit-scrollbar-track {
    border-radius: 20px;
    background-color: ${colors["light-gray"]};
  }

  &::-webkit-scrollbar-thumb {
    border: 2px solid transparent;
    background-clip: padding-box;
    border-radius: 10px;
    background-color: ${colors["gray-1"]};
  }
`;

export const MeshCheckBoxList = styled.ul`
  display: flex;
  flex-direction: column;
  gap: 10px;
  list-style: none;
  font-size: 18px;
  margin: 0;
  padding: 0;
`;

export const MeshCheckboxLi = styled.li`
  display: flex;
  align-items: center;
  height: 36px;
  padding-left: 5px;
  border-radius: 6px;
  background-color: ${colors["light-gray"]};
`;

export const DualViewDivider = styled.div`
  position: fixed;
  width: 3px;
  height: calc(100vh - 74px);
  top: 74px;
  left: 50%;
  background: linear-gradient(
    to bottom,
    rgba(77, 77, 77, 0),
    rgba(77, 77, 77, 1),
    rgba(77, 77, 77, 0)
  );
  z-index: 2;
`;

export const DisclaimerMessage = styled.p`
  position: absolute;
  top: 23px;
  left: 40px;
  display: flex;
  gap: 9px;
  padding: 5px;
  background-color: ${colors["light-gray"]};
  color: ${colors["dark-gray"]};
  font-size: 14px;
  white-space: pre-line;
  z-index: 2;
`;

export const AttentionHighlight = styled.span`
  color: ${colors["blue-1"]};
  font-weight: 600;
`;
