function typedArrayToBuffer(array: Uint8Array) {
  return array.buffer.slice(
    array.byteOffset,
    array.byteLength + array.byteOffset
  );
}

function stringToTypedArray(str: string) {
  const enc = new TextEncoder();
  return enc.encode(str);
}

function arrayBufferToString(array: ArrayBuffer) {
  const dec = new TextDecoder("utf-8");
  return dec.decode(array);
}

function stringToArrayBuffer(str: string) {
  const typedArray = stringToTypedArray(str);
  return typedArrayToBuffer(typedArray);
}

export {
  typedArrayToBuffer,
  stringToArrayBuffer,
  arrayBufferToString,
  stringToTypedArray,
};
