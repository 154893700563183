import { styled } from "@mui/material/styles";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import LinearProgress, {
  linearProgressClasses,
} from "@mui/material/LinearProgress";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import TimeGlassPNG from "../../../images/timeglass.png";
import { useContext } from "react";
import { AppGlobalDataContext } from "../../../providers/AppGlobalDataProvider";

const BorderLinearProgress = styled(LinearProgress)(({ theme }) => ({
  width: 500,
  height: 12,
  borderRadius: 6,
  boxShadow: "inset 0px 2px #bcbcbc, inset 0px -2px #bcbcbc",
  [`&.${linearProgressClasses.indeterminate}`]: {
    backgroundColor: "transparent",
  },
  [`&.${linearProgressClasses.indeterminate} > .${linearProgressClasses.bar1Indeterminate}`]:
    {
      backgroundColor: "#F4AA20",
    },
  [`&.${linearProgressClasses.indeterminate} > .${linearProgressClasses.bar2Indeterminate}`]:
    {
      backgroundColor: "#F4AA20",
    },
}));

interface BusyDlgProps {
  open: boolean;
  title: string;
}

function BusyDlg(props: BusyDlgProps) {
  const title = props.title;
  const { translations } = useContext(AppGlobalDataContext);

  return (
    <Dialog
      open={props.open}
      PaperProps={{
        style: { backgroundColor: "transparent", boxShadow: "none" },
      }}
    >
      <DialogContent>
        <Grid
          container
          direction="column"
          justifyContent="center"
          alignItems="center"
        >
          <img src={TimeGlassPNG} alt="Time Glass" />
          <Typography variant="h5" color="white">
            <b>{title}</b>
          </Typography>
          <div style={{ height: 10 }} />
          <BorderLinearProgress />
          <div style={{ height: 10 }} />
          <Typography color="white">
            {translations["PROCESSING_PLEASE_WAIT"]}
          </Typography>
        </Grid>
      </DialogContent>
    </Dialog>
  );
}

export default BusyDlg;
