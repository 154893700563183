import styled from "styled-components";
import { colors } from "../../../theme";

export const Backdrop = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  z-index: 1000;
`;

export const Dialog = styled.div`
  display: flex;
  flex-direction: column;
  width: 50%;
  min-height: 35vh;
  max-height: calc(100vh - 160px);
  padding: 20px;
  background-color: ${colors["light-gray"]};
  border: 1px solid #ccc;
  border-radius: 5px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  overflow: auto;
  scroll-behavior: smooth;
`;

export const Title = styled.h2`
  color: ${colors["dark-gray"]};
  font-size: 36px;
  font-weight: 400;
  text-align: center;
`;

export const CheckboxTitleLabel = styled.label`
  display: flex;
  align-items: center;
  gap: 7px;
  margin-top: 25px;
  font-size: 30px;
  color: ${colors["blue-1"]};
`;

export const StyledCheckbox = styled.input.attrs({ type: "checkbox" })`
  position: relative;
  display: inline-block;
  width: 30px;
  height: 30px;
  border-radius: 5px;
  background-color: ${colors["light-gray"]};
  border: 2px solid ${colors["blue-1"]};
  cursor: pointer;
  appearance: none;
  -webkit-appearance: none;
  -moz-appearance: none;

  &:checked {
    background-color: ${colors["blue-1"]};
  }

  &:checked::before {
    content: "✔";
    position: absolute;
    top: 0;
    left: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
    font-size: 20px;
    color: white;
  }

  &:focus {
    outline: none;
  }
`;

export const ThumbnailContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
  padding: 20px 35px;
`;

export const CheckboxOverlay = styled(StyledCheckbox)`
  position: absolute;
  bottom: 10px;
  right: 10px;
  border-color: ${colors["gray-1"]};

  &:disabled {
    background-color: ${colors["gray-2"]};
    cursor: default;

    &:checked::before {
      color: ${colors["gray-3"]};
    }
  }
`;

export const OptionsContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 100px;
`;

export const OptionLabel = styled(CheckboxTitleLabel)`
  margin-top: 5px;
  font-size: 18px;
  color: ${colors["dark-gray"]};
`;

export const SnapshotWrapper = styled.div`
  position: relative;
  display: inline-block;
  height: 90px;
  width: 160px;
  border: 2px solid ${colors["gray-1"]};
  border-radius: 3px;
`;

export const SnapshotThumbnailImg = styled.img`
  height: 100%;
  width: 100%;
  object-fit: cover;
`;

export const VideoWrapper = styled.div`
  width: 288px;
  border-radius: 3px;
`;

export const VideoThumbnailImg = styled.img`
  width: inherit;
  object-fit: cover;
`;

export const GenerateButton = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  width: inherit;
  height: 40px;
  color: ${colors["dark-gray"]};
  background-color: #e6e6e6;
  border-radius: 0 0 5px 5px;
  font-size: 18px;
  border: none;
  cursor: pointer;
`;

export const EmptyBoxContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 70px auto;
`;

export const TextContainer = styled.div`
  display: flex;
  gap: 10px;
  margin-top: 10px;
  font-size: 22px;
`;

export const ButtonsContainer = styled.div`
  display: flex;
  justify-content: center;
  gap: 20px;
  padding-top: 20px;
  margin-top: auto;
`;

export const ActionButton = styled.button`
  font-size: 22px;
  text-align: center;
  width: 250px;
  height: 62px;
  padding: 12px 24px;
  gap: 0px;
  border-radius: 5px;
  border: 2px solid transparent;
  opacity: 1;
  outline: none;
  cursor: pointer;
`;

export const SaveAndExitButton = styled(ActionButton)`
  color: white;
  background-color: ${colors["blue-1"]};

  &:disabled {
    background-color: ${colors["gray-1"]};
    border-color: ${colors["dark-gray"]};
    color: ${colors["dark-gray"]};
    opacity: 15%;
    cursor: default;
  }

  &:hover:not(:disabled) {
    background-color: ${colors["blue-3"]};
  }

  &:active:not(:disabled) {
    background-color: ${colors["blue-4"]};
  }
`;

export const CancelButtton = styled(ActionButton)`
  color: ${colors["dark-gray"]};
  background-color: ${colors["light-gray"] + 15};
  border: 2px solid ${colors["gray-1"]};

  &:hover {
    background-color: ${colors["gray-2"]};
  }

  &:active {
    background-color: ${colors["gray-3"]};
  }
`;

export const SaveButtton = styled(CancelButtton)`
  &:disabled {
    background-color: ${colors["gray-1"]};
    border-color: ${colors["dark-gray"]};
    color: ${colors["dark-gray"]};
    opacity: 15%;
    cursor: default;
  }
`;
