import { useContext } from "react";
import {
  LegendContainer,
  RulerContainer,
  RulerScaler,
  RulerScalersArea,
  ColorsContainer,
  Title,
} from "./DistanceMappingLegend.styled";
import { AppGlobalDataContext } from "../../providers/AppGlobalDataProvider";

export default function DistanceMappingLegend() {
  const { translations } = useContext(AppGlobalDataContext);

  return (
    <LegendContainer>
      <Title>{translations["DISTANCE_MAPPING"]}</Title>
      <RulerContainer>
        <RulerScalersArea>
          <RulerScaler>1.0mm —</RulerScaler>
          <RulerScaler>0.8mm —</RulerScaler>
          <RulerScaler>0.6mm —</RulerScaler>
          <RulerScaler>0.4mm —</RulerScaler>
          <RulerScaler>0.2mm —</RulerScaler>
        </RulerScalersArea>
        <ColorsContainer />
      </RulerContainer>
    </LegendContainer>
  );
}
