import React from "react";

const CalendarIcon: React.FC = () => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M7 0C6.44772 0 6 0.447715 6 1V2H3C1.34315 2 0 3.34315 0 5V21C0 22.6569 1.34315 24 3 24H21C22.6569 24 24 22.6569 24 21V5C24 3.34315 22.6569 2 21 2H18V1C18 0.447715 17.5523 0 17 0C16.4477 0 16 0.447715 16 1V2H8V1C8 0.447715 7.55229 0 7 0ZM16 5V4H8V5C8 5.55228 7.55229 6 7 6C6.44772 6 6 5.55228 6 5V4H3C2.44771 4 2 4.44772 2 5V21C2 21.5523 2.44771 22 3 22H21C21.5523 22 22 21.5523 22 21L22 8H2V7H22L22 5C22 4.44772 21.5523 4 21 4H18V5C18 5.55228 17.5523 6 17 6C16.4477 6 16 5.55228 16 5ZM9 12H6V14H9V12ZM9 16H6V18H9V16ZM4 16V14V10H9H11H13H15H20V14V16V20H15H13H11H9H4V16ZM18 14V12H15V14H18ZM15 16H18V18H15V16ZM13 16V18H11V16H13ZM13 14H11V12H13V14Z"
        fill="#AFAFAF"
      />
    </svg>
  );
};

export default CalendarIcon;
