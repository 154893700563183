import React from "react";

const ImportIcon: React.FC = () => {
  return (
    <svg
      width="37"
      height="31"
      viewBox="0 0 37 31"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M10.495 24.9148C10.4948 26.264 12.0053 27.1777 13.1925 26.3523L25.2907 17.9408C26.3476 17.206 26.2722 15.5921 25.1344 14.9698L25.1345 14.9697L25.1262 14.9653L22.1079 13.3507C23.3693 11.0725 24.5838 9.10956 26.3584 7.63853C28.3127 6.01855 31.0417 4.92695 35.495 4.92695C35.9519 4.92695 36.3507 4.61728 36.4639 4.17462C36.577 3.73196 36.3758 3.26889 35.9749 3.04963C32.9331 1.3857 30.3312 0.659768 28.0632 0.659912C25.7767 0.660055 23.907 1.39839 22.3488 2.52467C20.809 3.63769 19.5853 5.1204 18.5471 6.60055C18.0262 7.34313 17.5432 8.0982 17.0889 8.81601L16.9029 9.11015L16.9027 9.11047C16.6897 9.44767 16.4834 9.77415 16.2805 10.0888L13.0345 8.35098C11.8452 7.70478 10.4983 8.61814 10.498 9.88704L10.495 24.9148ZM23.814 16.5316L12.4951 24.4013L12.498 10.3323L16.1412 12.2828C16.5928 12.5245 17.1539 12.386 17.4412 11.962C17.824 11.3968 18.2089 10.7876 18.5957 10.1753L18.7789 9.88554C19.2318 9.16986 19.693 8.4496 20.1844 7.74907C21.1707 6.34301 22.2437 5.0684 23.5204 4.14558C24.7788 3.23603 26.2455 2.66002 28.0633 2.65991C29.0693 2.65985 30.2056 2.83631 31.4955 3.2519C28.7892 3.73263 26.7373 4.72665 25.0821 6.09877C22.7401 8.04008 21.2867 10.6717 19.8671 13.2775C19.7396 13.5116 19.7108 13.7869 19.7872 14.0422C19.8635 14.2976 20.0386 14.5119 20.2736 14.6377L23.814 16.5316ZM6.5 18.9949C7.05228 18.9949 7.5 19.4426 7.5 19.9949C7.5 20.5472 7.05228 20.9949 6.5 20.9949C4.29086 20.9949 2.5 22.7858 2.5 24.9949C2.5 27.2041 4.29086 28.9949 6.5 28.9949H30.5C32.7091 28.9949 34.5 27.2041 34.5 24.9949C34.5 22.7858 32.7091 20.9949 30.5 20.9949H28.5C27.9477 20.9949 27.5 20.5472 27.5 19.9949C27.5 19.4426 27.9477 18.9949 28.5 18.9949H30.5C33.8137 18.9949 36.5 21.6812 36.5 24.9949C36.5 28.3086 33.8137 30.9949 30.5 30.9949H6.5C3.18629 30.9949 0.5 28.3086 0.5 24.9949C0.5 21.6812 3.18629 18.9949 6.5 18.9949Z"
        fill="#4D4D4D"
      />
    </svg>
  );
};

export default ImportIcon;
