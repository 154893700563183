import { mat4 } from "gl-matrix";
import { matrix4 } from "./matrixUtils";
import { Comment } from "../model/Comment";

interface IComment {
  comment: string;
  timestamp: number;
  position: { x: number; y: number; z: number };
  parent: string;
  parentCatalog: string;
}
interface IMeshRelation {
  seriesInstanceUid: string;
  parent: null | string;
  matrix: mat4;
  catalog: string;
}
interface IQtContext {
  analysesDataLoaded: {
    connect: (callbackfn: (data: string) => Promise<void>) => void;
  };
  saveAnalysesData: (data: string) => void;
}
export class AnalysesDataManager {
  private comments: Array<IComment>;
  private relations: Array<IMeshRelation>;
  private qtContext: IQtContext | null;
  constructor() {
    this.comments = [];
    this.relations = [];
    this.qtContext = null;
  }
  setQtContext(context: IQtContext) {
    if (
      context &&
      context.analysesDataLoaded &&
      "function" === typeof context.saveAnalysesData
    ) {
      this.qtContext = context;
    }
  }

  load(data: any) {
    if (data) {
      let raw: string = "";
      try {
        raw = atob(data);
        const json = JSON.parse(raw);
        this.comments = json.comments;
        this.relations = json.relations;
      } catch (err) {
        console.error(err);
      }
    }
  }
  save() {
    if (!this.qtContext) return;
    const data = { comments: this.comments, relations: this.relations };
    const str = JSON.stringify(data);
    this.qtContext.saveAnalysesData(btoa(str));
  }

  getRelations() {
    return this.relations;
  }

  // query matrix
  getRelation(uid: string, catalog: string, parent: string) {
    let prevMat = this.relations.find(
      (r) =>
        r.seriesInstanceUid === uid &&
        r.parent === parent &&
        r.catalog === catalog
    );
    if (prevMat) {
      return prevMat;
    } else {
      return null;
    }
  }

  // update matrix
  updateRelations(uid: string, catalog: string, parent: string, matrix: mat4) {
    matrix =
      matrix instanceof Array
        ? matrix
        : (Array.prototype.slice.call(matrix) as matrix4);
    let prevMat = this.getRelation(uid, catalog, parent);
    if (!prevMat) {
      this.relations.push({
        seriesInstanceUid: uid,
        parent,
        matrix,
        catalog,
      });
    } else {
      const updateMat = mat4.create();
      mat4.multiply(updateMat, prevMat.matrix, matrix);
      prevMat.matrix = Array.prototype.slice.call(updateMat) as matrix4;
    }
    this.save();
  }

  updateComments(comments: Array<Comment>) {
    this.comments = [];
    comments.forEach((comment) => {
      this.comments.push({
        comment: comment.text,
        timestamp: comment.creationDate.getTime(),
        position: comment.position,
        parent: comment.parentMesh.scanId,
        parentCatalog: comment.parentMesh.name,
      });
    });
    this.save();
  }

  getComments() {
    return this.comments;
  }
}
