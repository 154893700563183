import vtkPolyData from "@kitware/vtk.js/Common/DataModel/PolyData";
import { vtkUtil } from "./vtkUtils";
import { Mesh, NormalMesh } from "../model/Mesh";
import { MeshPairT, OccluUtil, WasmCmdType } from "./OccluUtil";
import WasmWorkerClient from "../workers/WasmWorkerClient";

class ManualMatcher {
  private worker: WasmWorkerClient;
  private workerReady: boolean = false;
  constructor(worker: WasmWorkerClient) {
    this.worker = worker;
    this.initializeWorker();
  }

  private initializeWorker() {
    this.worker.onmessage = (e) => {
      if (WasmCmdType.InitWasmSuccess === e?.data) {
        this.workerReady = true;
      }
    };

    this.worker.postMessage({ type: WasmCmdType.InitWasm });
  }

  compute(
    referPd: vtkPolyData,
    targetPd: vtkPolyData,
    pointPairs: number[]
  ): Promise<number[]> {
    if (!this.workerReady || !referPd || !targetPd) {
      return Promise.reject("Worker or data not ready");
    }

    return new Promise((resolve) => {
      const data: MeshPairT & { pointPairs: number[] } = {
        ...OccluUtil.toMeshPairT(referPd, targetPd),
        pointPairs,
      };

      this.worker.postMessage(
        { type: WasmCmdType.ComputeManualMatchingMatrix, data: data },
        (msg: any) => {
          const matrix = msg.data;
          resolve(matrix || []);
        }
      );
    });
  }
}

export const computeManualMatchingTransform = (
  movingMesh: NormalMesh,
  targetMesh: NormalMesh,
  pointPairs: number[]
): Promise<number[]> => {
  return new Promise((resolve, reject) => {
    const transform = (
      a: Mesh,
      b: Mesh,
      points: number[]
    ): Promise<number[]> => {
      return new Promise((resolve, reject) => {
        if (a && b) {
          const a_pd = vtkUtil.getPolyData(a.actor);
          const b_pd = vtkUtil.getPolyData(b.actor);
          manualMatcher
            .compute(a_pd, b_pd, points)
            .then((matrix: number[]) => {
              resolve(matrix);
            })
            .catch(reject);
        } else {
          reject("Invalid input");
        }
      });
    };

    if (movingMesh && targetMesh && pointPairs.length >= 6) {
      const transformPromise = transform(movingMesh, targetMesh, pointPairs);
      transformPromise
        .then((matrix) => {
          resolve(matrix);
        })
        .catch(reject);
    } else {
      reject("Invalid input");
    }
  });
};

export const manualMatcher = new ManualMatcher(new WasmWorkerClient());
export default manualMatcher;
