import styled from "styled-components";
import { colors } from "../../theme";

export const StyledLayout = styled.div`
  display: flex;
  width: 100%;
  height: calc(100vh - 74px);
  overflow: hidden;
  background-color: ${colors["light-gray"]};
`;

export const ViewportContainer = styled.div<{
  $isCommentModeActive: boolean;
  $shiftedContentWithCommentMode: number;
}>`
  width: ${({ $isCommentModeActive, $shiftedContentWithCommentMode }) =>
    $isCommentModeActive
      ? `calc(50% - ${$shiftedContentWithCommentMode / 2}px)`
      : "50%"};
  height: 100%;
  position: relative;
  overflow: hidden;
`;

export const DualViewDivider = styled.div<{
  $isCommentModeActive: boolean;
  $shiftedContentWithCommentMode: number;
}>`
  position: fixed;
  width: 3px;
  height: calc(100vh - 74px);
  top: 74px;
  left: ${({ $isCommentModeActive, $shiftedContentWithCommentMode }) =>
    $isCommentModeActive
      ? `calc(50% - ${$shiftedContentWithCommentMode / 2}px)`
      : "50%"};
  background: linear-gradient(
    to bottom,
    rgba(77, 77, 77, 0),
    rgba(77, 77, 77, 1),
    rgba(77, 77, 77, 0)
  );
`;

export const DualViewLabels = styled.div<{
  $isCommentModeActive: boolean;
  $shiftedContentWithCommentMode: number;
}>`
  position: fixed;
  width: ${({ $isCommentModeActive, $shiftedContentWithCommentMode }) =>
    $isCommentModeActive
      ? `calc(100% - ${$shiftedContentWithCommentMode}px)`
      : "100%"};
  bottom: 45px;
  display: flex;
  justify-content: space-around;
  font-size: 22px;

  & > div {
    height: 24px;
    display: flex;
    gap: 10px;

    & > span {
      line-height: 24px;
    }
    & > image {
      height: 24px;
      cursor: pointer;
    }
  }
`;

export const DateWrapper = styled.div`
  display: flex;
  align-items: center;
`;
