enum MeshName {
  Maxillary = "Maxillary",
  MaxillaryAnatomy = "Maxillary Anatomy",
  MaxillaryEdentulous = "Maxillary Edentulous",
  MaxillaryPreScan = "Maxillary Pre Scan",
  MaxillaryImplant = "Maxillary Implant",
  Mandibular = "Mandibular",
  MandibularAnatomy = "Mandibular Anatomy",
  MandibularEdentulous = "Mandibular Edentulous",
  MandibularPreScan = "Mandibular Pre Scan",
  MandibularImplant = "Mandibular Implant",
  BiteAverage = "BiteAverage",
  ExtraObject = "Extra Object",
  NormalBite = "Normal Bite",
}

const SelectableMesh = [
  MeshName.Maxillary,
  MeshName.MaxillaryAnatomy,
  MeshName.Mandibular,
  MeshName.MandibularAnatomy,
];

enum ViewOrientation {
  Unknown,
  Top,
  Bottom,
  Left,
  Right,
  Front,
  Back,
}

export { MeshName, SelectableMesh, ViewOrientation };
