import { RefObject } from "react";

import { RGBColor, Vector3 } from "@kitware/vtk.js/types";
import "@kitware/vtk.js/Rendering/Profiles/Geometry";
import vtkGenericRenderWindow from "@kitware/vtk.js/Rendering/Misc/GenericRenderWindow";
import vtkRenderer from "@kitware/vtk.js/Rendering/Core/Renderer";
import vtkOrientationMarkerWidget from "@kitware/vtk.js/Interaction/Widgets/OrientationMarkerWidget";
import vtkAnnotatedCubeActor from "@kitware/vtk.js/Rendering/Core/AnnotatedCubeActor";
import vtkRenderWindowInteractor from "@kitware/vtk.js/Rendering/Core/RenderWindowInteractor";
import { IMeshNode, MeshNode } from "@envistaco/dicom-reader";
import vtkActor from "@kitware/vtk.js/Rendering/Core/Actor";
import vtkMapper from "@kitware/vtk.js/Rendering/Core/Mapper";
import vtkSTLReader from "@kitware/vtk.js/IO/Geometry/STLReader";
import vtkPLYReader from "@kitware/vtk.js/IO/Geometry/PLYReader";
import vtkPlane from "@kitware/vtk.js/Common/DataModel/Plane";
import vtkMath from "@kitware/vtk.js/Common/Core/Math";
import vtkTexture from "@kitware/vtk.js/Rendering/Core/Texture";
// @ts-ignore
import vtkPolyDataNormals from "@kitware/vtk.js/Filters/Core/PolyDataNormals";
// @ts-ignore
import { XMLParser } from "fast-xml-parser";
import vtkXMLPolyDataReader from "@kitware/vtk.js/IO/XML/XMLPolyDataReader";
import {
  arrayBufferToString,
  stringToArrayBuffer,
  typedArrayToBuffer,
} from "./parsers";
import GlobalEvents from "./globalEvents";
import vtkCamera from "@kitware/vtk.js/Rendering/Core/Camera";
import { ViewOrientation } from "./common";
import vtkPolyData from "@kitware/vtk.js/Common/DataModel/PolyData";
import vtkSphereSource from "@kitware/vtk.js/Filters/Sources/SphereSource";
import vtkDataArray from "@kitware/vtk.js/Common/Core/DataArray";

interface DualView {
  renderWindowL: vtkGenericRenderWindow;
  renderWindowR: vtkGenericRenderWindow;
  rendererL: vtkRenderer;
  rendererR: vtkRenderer;
}

interface TriView {
  renderWindow: vtkGenericRenderWindow;
  rendererLT: vtkRenderer;
  rendererLB: vtkRenderer;
  rendererMain: vtkRenderer;
}

class vtkUtil {
  static getPolyData(actor: vtkActor) {
    return actor?.getMapper()?.getInputData();
  }

  static buildActorFromDeepCopy(srcPolyData: vtkPolyData) {
    const pd = this.createPolyDataFromDeepCopy(srcPolyData);
    const actor = this.createPolyDataActor(pd!);
    return actor;
  }

  static createPolyDataFromDeepCopy(srcPolyData: vtkPolyData) {
    if (!srcPolyData) {
      return null;
    }
    const verts = Float32Array.from(srcPolyData.getPoints().getData());
    const cells = Uint32Array.from(srcPolyData.getPolys().getData());
    return this.createPolyData(verts, cells);
  }

  private static glMaxTextureSize = 8192;
  static setCameraOrientation(
    camera: vtkCamera,
    viewOrientation: ViewOrientation
  ) {
    camera.setPosition(0, 0, 0);
    switch (viewOrientation) {
      case ViewOrientation.Right: // Positive X
        camera.setFocalPoint(1, 0, 0);
        camera.setViewUp(0, 0, 1);
        break;
      case ViewOrientation.Left: // Negative X
        camera.setFocalPoint(-1, 0, 0);
        camera.setViewUp(0, 0, 1);
        break;
      case ViewOrientation.Front: // Postive Y
        camera.setFocalPoint(0, 1, 0);
        camera.setViewUp(0, 0, 1);
        break;
      case ViewOrientation.Back: // Negative Y
        camera.setFocalPoint(0, -1, 0);
        camera.setViewUp(0, 0, 1);
        break;
      case ViewOrientation.Top: // Postive Z
        camera.setFocalPoint(0, 0, -1);
        camera.setViewUp(0, 1, 0);
        break;
      case ViewOrientation.Bottom: // Negative Z
        camera.setFocalPoint(0, 0, 1);
        camera.setViewUp(0, -1, 0);
        break;
    }
  }
  static createOrientationMarkerWidget(interactor: vtkRenderWindowInteractor) {
    const axes = vtkAnnotatedCubeActor.newInstance();
    axes.setDefaultStyle({
      text: "+X",
      fontStyle: "bold",
      fontFamily: "Roboto",
      fontColor: "white",
      fontSizeScale: (res) => res * 0.72,
      faceColor: "#E0BD00",
      faceRotation: 0,
      edgeThickness: 0.06,
      edgeColor: "white",
    });
    axes.setXPlusFaceProperty({
      text: "L",
      faceRotation: 90,
    });
    axes.setXMinusFaceProperty({
      text: "R",
      faceRotation: -90,
    });
    axes.setYPlusFaceProperty({
      text: "P",
      faceRotation: 180,
    });
    axes.setYMinusFaceProperty({
      text: "A",
    });
    axes.setZPlusFaceProperty({
      text: "H",
      faceRotation: 0,
    });
    axes.setZMinusFaceProperty({
      text: "F",
      faceRotation: 180,
    });
    // create orientation widget
    const wgt = vtkOrientationMarkerWidget.newInstance({
      actor: axes,
      interactor: interactor,
    });
    wgt.setEnabled(true);
    wgt.setViewportCorner(vtkOrientationMarkerWidget.Corners.BOTTOM_LEFT);
    wgt.setViewportSize(0.1);
    wgt.setMinPixelSize(100);
    wgt.setMaxPixelSize(300);
    return wgt;
  }

  static getGLMaxTextureSize() {
    return this.glMaxTextureSize;
  }

  static createGenericRenderWindow(
    containerRef: RefObject<HTMLElement>
  ): vtkGenericRenderWindow {
    const bgArrF: RGBColor = [245.0 / 255.0, 245.0 / 255.0, 245.0 / 255.0];
    const genericRenderWindow = vtkGenericRenderWindow.newInstance({
      background: bgArrF,
    });
    genericRenderWindow.setContainer(containerRef.current!);
    return genericRenderWindow;
  }

  static createDualViewRenderWindow(
    containerRefL: RefObject<HTMLElement>,
    containerRefR: RefObject<HTMLElement>
  ): DualView {
    const bgArrFL: RGBColor = [245.0 / 255.0, 245.0 / 255.0, 245.0 / 255.0];
    const bgArrFR: RGBColor = [245.0 / 255.0, 245.0 / 255.0, 245.0 / 255.0];

    // Create two separate generic render windows for left and right views
    const genericRenderWindowL =
      vtkUtil.createGenericRenderWindow(containerRefL);
    const genericRenderWindowR =
      vtkUtil.createGenericRenderWindow(containerRefR);

    // Get renderers for both windows
    const rendererL = genericRenderWindowL.getRenderer();
    const rendererR = genericRenderWindowR.getRenderer();

    rendererL.setBackground(bgArrFL);
    rendererR.setBackground(bgArrFR);

    return {
      renderWindowL: genericRenderWindowL,
      renderWindowR: genericRenderWindowR,
      rendererL: rendererL,
      rendererR: rendererR,
    };
  }

  static createTriViewRenderWindow(
    containerRef: RefObject<HTMLElement>
  ): TriView {
    const viewPortLT = [0.0, 0.5, 0.3669, 1.0];
    const viewPortLB = [0.0, 0.0, 0.3669, 0.5];
    const viewPortMain = [0.3669, 0.0, 1.0, 1.0];
    const bgArr: RGBColor = [245.0 / 255.0, 245.0 / 255.0, 245.0 / 255.0];
    const genericRenderWindow = vtkUtil.createGenericRenderWindow(containerRef);
    const renderWindow = genericRenderWindow.getRenderWindow();
    const rendererMain = genericRenderWindow.getRenderer();
    const rendererLT = vtkRenderer.newInstance();
    const rendererLB = vtkRenderer.newInstance();
    rendererMain.setViewportFrom(viewPortMain);
    rendererLT.setViewportFrom(viewPortLT);
    rendererLB.setViewportFrom(viewPortLB);
    rendererMain.setBackground(bgArr);
    rendererLT.setBackground(bgArr);
    rendererLB.setBackground(bgArr);
    renderWindow.addRenderer(rendererLT);
    renderWindow.addRenderer(rendererLB);

    return {
      renderWindow: genericRenderWindow,
      rendererLT,
      rendererLB,
      rendererMain,
    };
  }

  static synchronizeCamerasBidirectionally(
    renderer1: vtkRenderer,
    renderer2: vtkRenderer
  ) {
    const camera1 = renderer1.getActiveCamera();
    const camera2 = renderer2.getActiveCamera();

    let syncing = false;

    const syncCamera1ToCamera2 = () => {
      if (syncing) return;
      syncing = true;

      camera2.setPosition(...camera1.getPosition());
      camera2.setFocalPoint(...camera1.getFocalPoint());
      camera2.setViewUp(...camera1.getViewUp());
      camera2.setClippingRange(...camera1.getClippingRange());

      renderer2.resetCameraClippingRange();
      renderer2.getRenderWindow()!.render();

      syncing = false;
    };

    const syncCamera2ToCamera1 = () => {
      if (syncing) return;
      syncing = true;

      camera1.setPosition(...camera2.getPosition());
      camera1.setFocalPoint(...camera2.getFocalPoint());
      camera1.setViewUp(...camera2.getViewUp());
      camera1.setClippingRange(...camera2.getClippingRange());

      renderer1.resetCameraClippingRange();
      renderer1.getRenderWindow()!.render();

      syncing = false;
    };

    camera1.onModified(syncCamera1ToCamera2);
    camera2.onModified(syncCamera2ToCamera1);

    // Initially synchronize the cameras
    syncCamera1ToCamera2();
  }

  static getMaxTextureSize(w: number, h: number) {
    const max = this.getGLMaxTextureSize();
    if (w <= max && h <= max) {
      return { width: w, height: h };
    }
    let wx = max;
    let hx = max;
    if (w > max) {
      hx = (max / w) * h;
      if (hx > max) {
        hx = max;
        wx = (max / h) * w;
      }
    } else {
      wx = (max / h) * w;
      if (wx > max) {
        wx = max;
        hx = (max / w) * h;
      }
    }
    return { width: wx, height: hx };
  }

  static createPolyData(verts: Float32Array, cells: Uint32Array) {
    if (!verts || !cells) {
      return null;
    }
    const pd = vtkPolyData.newInstance();
    pd.getPoints().setData(verts, 3);
    pd.getPolys().setData(cells);
    return pd;
  }

  static createPolyDataActor(polydata: vtkPolyData): vtkActor {
    const mapper = vtkMapper.newInstance();
    const actor = vtkActor.newInstance();
    actor.setMapper(mapper);
    mapper.setInputData(polydata);
    return actor;
  }

  static projectPoint2ViewPlane(
    pt: any,
    renderer: vtkRenderer,
    maxOffset = 10
  ) {
    const selectionX = pt.x;
    const selectionY = pt.y;
    let selectionZ = pt.z;

    const view = renderer?.getRenderWindow()?.getViews()[0];
    // Get camera focal point and position. Convert to display (screen)
    // coordinates. We need a depth value for z-buffer.
    const camera = renderer.getActiveCamera();
    const cameraFP = camera.getFocalPoint();

    const dims = view.getViewportSize(renderer);
    const aspect = dims[0] / dims[1];

    const displayCoords = renderer.worldToNormalizedDisplay(
      cameraFP[0],
      cameraFP[1],
      cameraFP[2],
      aspect
    );
    const displayCoordsx = view.normalizedDisplayToDisplay(
      displayCoords[0],
      displayCoords[1],
      displayCoords[2]
    );
    selectionZ = displayCoordsx[2];

    // Convert the selection point into world coordinates.
    const normalizedDisplay = view.displayToNormalizedDisplay(
      selectionX,
      selectionY,
      selectionZ
    );

    const worldCoords = renderer.normalizedDisplayToWorld(
      normalizedDisplay[0],
      normalizedDisplay[1],
      normalizedDisplay[2],
      aspect
    );

    const viewNorm = camera.getViewPlaneNormal();
    const origin: Vector3 = [0, 0, 0];
    const plane = vtkPlane.newInstance();
    plane.setOrigin(origin);
    plane.setNormal(viewNorm);
    const ptProj: Vector3 = [0, 0, 0];
    plane.projectVector(worldCoords as Vector3, ptProj);

    const cameraPos = camera.getPosition();
    const dist = Math.sqrt(vtkMath.distance2BetweenPoints(origin, cameraPos));
    const offset = Math.min(dist * 0.8, maxOffset);
    for (let i = 0; i < ptProj.length; ++i) {
      ptProj[i] = ptProj[i] + offset * viewNorm[i];
    }
    return ptProj;
  }
}

function readVTKXML(arraybuffer: ArrayBuffer) {
  const str = arrayBufferToString(arraybuffer);
  const xml = new XMLParser().parse(str);
  const data3d = xml?.object3D?.data3D;
  const vtkFileStr = data3d?.replaceAll("&lt;", "<").replaceAll("&gt;", ">");
  return stringToArrayBuffer(vtkFileStr);
}

async function loadImage(src: string) {
  return new Promise((resolve, reject) => {
    let img = new Image();
    img.onload = () => resolve(img);
    img.onerror = reject;
    img.src = src;
  });
}

function createPolyData(buffer: Uint8Array, bufferType: string) {
  const arrayBuffer = typedArrayToBuffer(buffer);
  return createPolyDataFromArrayBuffer(arrayBuffer, bufferType);
}

function arrayBufferToIMeshNode(
  buffer: ArrayBuffer,
  name: string,
  format: "PLY_FILE" | "STL_FILE"
): IMeshNode {
  const mesh = new MeshNode("", format, name, "", new Uint8Array(buffer));
  return mesh as any as IMeshNode;
}

function createPolyDataFromArrayBuffer(
  arrayBuffer: ArrayBuffer,
  bufferType: string
) {
  switch (bufferType) {
    case "STL_FILE": {
      const reader = vtkSTLReader.newInstance();
      reader.parseAsArrayBuffer(arrayBuffer);
      reader.update();
      return reader.getOutputData();
    }

    case "PLY_FILE":
    case "PLY_TEXTURED_FILE": {
      const reader = vtkPLYReader.newInstance();
      reader.parseAsArrayBuffer(arrayBuffer);
      reader.update();
      return reader.getOutputData();
    }

    case "VTK_FILE": {
      const vtkFileBuffer = readVTKXML(arrayBuffer);
      const reader = vtkXMLPolyDataReader.newInstance();
      reader.parseAsArrayBuffer(vtkFileBuffer);
      reader.update();
      return reader.getOutputData();
    }

    default:
      return null;
  }
}

function buildActor(mesh: IMeshNode): vtkActor | null {
  if (!mesh.meshBuffer) {
    console.error("Mesh buffer is undefined or null.");
    return null;
  }

  const polydata_port = createPolyData(mesh.meshBuffer, mesh.meshBufferType);
  if (polydata_port === null) {
    console.error("Failed to create polydata from mesh buffer.");
    return null;
  }

  try {
    const polyDataNormals = vtkPolyDataNormals.newInstance();
    polyDataNormals.setComputePointNormals(true);
    polyDataNormals.setComputeCellNormals(true);
    polyDataNormals.setInputData(polydata_port);
    const mapper = vtkMapper.newInstance();
    const actor = vtkActor.newInstance();
    mapper.setInputConnection(polyDataNormals.getOutputPort());
    actor.setMapper(mapper);
    return actor;
  } catch (error) {
    console.error("Error building actor for mesh:", error);
    return null;
  }
}

async function buildTexture(mesh: IMeshNode): Promise<vtkTexture | null> {
  if (mesh.texture != null) {
    const texture = vtkTexture.newInstance();
    texture.setInterpolate(true);

    const img = await loadImage(mesh.texture);

    if (!img) {
      return null;
    }
    const imgElement = img as HTMLIFrameElement;
    const w = Number(imgElement.width);
    const h = Number(imgElement.height);
    const max = vtkUtil.getGLMaxTextureSize();
    if (img && w <= max && h <= max) {
      texture.setImage(img);
      GlobalEvents.refreshCanvas();
    } else {
      console.warn("texture is too large", imgElement.width, imgElement.height);
    }

    return texture;
  } else {
    return null;
  }
}

async function getTextureScalars(
  actor: vtkActor
): Promise<vtkDataArray | null> {
  const mapper = actor.getMapper();
  if (!mapper) return null;

  const polydata = mapper.getInputData() as vtkPolyData;
  if (!polydata) return null;

  const pointData = polydata.getPointData();
  if (!pointData) return null;

  const scalars = pointData.getScalars();
  if (!scalars || scalars.getNumberOfValues() <= 0) return null;

  console.debug("Getting texture scalars");

  return scalars;
}

async function createMeshActor(
  mesh: IMeshNode
): Promise<[vtkActor | null, vtkTexture | null, vtkDataArray | null]> {
  const actor = buildActor(mesh);
  if (!actor) {
    return [null, null, null];
  }

  const texture = await buildTexture(mesh);
  if (texture) {
    actor.addTexture(texture);
    return [actor, texture, null];
  }

  const textureScalars = await getTextureScalars(actor);

  return [actor, texture, textureScalars];
}

function createSphereActor(x: number, y: number, z: number) {
  const sphereSource = vtkSphereSource.newInstance();
  sphereSource.setCenter(x, y, z);
  sphereSource.setThetaResolution(20);
  sphereSource.setPhiResolution(20);
  sphereSource.update();

  const mapper = vtkMapper.newInstance();
  mapper.setInputConnection(sphereSource.getOutputPort());

  const selectedPointActor = vtkActor.newInstance();
  selectedPointActor.setMapper(mapper);

  return selectedPointActor;
}

export {
  vtkUtil,
  buildActor,
  createMeshActor,
  createPolyData,
  createPolyDataFromArrayBuffer,
  arrayBufferToIMeshNode,
  createSphereActor,
};
export type { TriView };
