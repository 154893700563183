import styled from "styled-components";
import { colors } from "../../../theme";

export const Backdrop = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
`;

export const Dialog = styled.div`
  background-color: white;
  border: 1px solid #ccc;
  border-radius: 5px;
  padding: 20px;
  width: 450px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
`;

export const Title = styled.h1`
  font-size: 36px;
  font-style: normal;
  font-weight: 400;
  line-height: 36px;
  margin-bottom: 20px;
  text-align: center;
`;

export const Instructions = styled.p`
  font-size: 18px;
  margin-bottom: 20px;
  text-align: start;
`;

export const Content = styled.div`
  margin: 20px 0;
`;

export const ScanLabelContainer = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 10px;
  margin-left: 20px;
  gap: 6px;
`;

export const CustomCheckbox = styled.input.attrs({ type: "checkbox" })<{
  color: string;
}>`
  appearance: none;
  margin-left: 10px;
  width: 20px;
  height: 20px;
  border: 2px solid ${(props) => (props.disabled ? "gray" : props.color)};
  border-radius: 4px;
  cursor: pointer;
  position: relative;

  &:checked {
    background-color: ${(props) => props.color};
  }

  &:checked::after {
    content: "✔";
    color: white;
    font-size: 16px;
    position: absolute;
    top: -2px;
    left: 2px;
  }
`;

export const ScanLabel = styled.label<{
  disabled?: boolean;
  selected?: boolean;
}>`
  display: inline-block;
  color: ${(props) =>
    props.disabled ? "gray" : props.selected ? "black" : "grey"};
`;

export const Buttons = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
`;

export const InfoImage = styled.img`
  display: block;
  margin: 0 auto 20px;
`;

export const Button = styled.button`
  padding: 10px 20px;
  flex: 1;
  margin: 0 10px;
  border-radius: 5px;
  border: 2px solid ${colors["light-gray"]};
  background-color: ${(props) =>
    props.disabled ? "rgba(175, 175, 175, 0.15)" : colors["blue-1"]};
  cursor: ${(props) => (props.disabled ? "default" : "pointer")};
  color: ${(props) => (props.disabled ? colors["gray-1"] : "white")};
  font-size: 18px;
`;

export const CancelButton = styled(Button)`
  background-color: white;
  color: black;
  border: 2px solid #ccc;

  &:hover {
    background-color: #f0f0f0;
  }
`;
