import { Routes, Route } from "react-router-dom";
import DistanceMapping from "../components/layouts/DistanceMapping";
import OcclusionProximity from "../components/layouts/OcclusionProximity";
import { MeasurementLayout } from "../components/layouts/Measurement";
import PointBasedMatching from "../model/PointBasedMatching/PointBasedMatching";
import SuperImposition from "../components/layouts/SuperImposition";

const AppRoutes = () => {
  return (
    <Routes>
      <Route path="/" element={<SuperImposition />} />
      <Route path="/distance-mapping" element={<DistanceMapping />} />
      <Route path="/occlusion-proximity" element={<OcclusionProximity />} />
      <Route path="/measurement" element={<MeasurementLayout />} />
      <Route path="/point-matching" element={<PointBasedMatching />} />
    </Routes>
  );
};

export default AppRoutes;
