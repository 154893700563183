import ReactDOM from "react-dom/client";
import App from "./App";
import { AppConfigProvider } from "./providers/AppConfigProvider";
import { AppGlobalDataProvider } from "./providers/AppGlobalDataProvider";

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);
root.render(
  <AppConfigProvider>
    <AppGlobalDataProvider>
      {/* <React.StrictMode> */}
      <App />
      {/* </React.StrictMode> */}
    </AppGlobalDataProvider>
  </AppConfigProvider>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals();
