import vtkActor from "@kitware/vtk.js/Rendering/Core/Actor";
import { createSphereActor } from "../utils/vtkUtils";
import { NormalMesh } from "./Mesh";

class Comment {
  text: string;
  position: { x: number; y: number; z: number };
  actor: vtkActor;
  parentMesh: NormalMesh;
  creationDate: Date;

  constructor(
    text: string,
    position: { x: number; y: number; z: number },
    parentMesh: NormalMesh,
    creationDate?: Date
  ) {
    this.text = text;
    this.position = position;
    this.actor = createSphereActor(position.x, position.y, position.z);
    this.parentMesh = parentMesh;
    this.parentMesh.actor &&
      this.actor.setUserMatrix(parentMesh.actor.getUserMatrix());
    this.parentMesh.actor &&
      parentMesh.actor.onModified(() => {
        this.actor.setUserMatrix(parentMesh.actor.getUserMatrix());
      });
    this.creationDate = creationDate ?? new Date();
  }
}

export { Comment };
