import styled from "styled-components";
import { colors } from "../../../theme";

interface CommentFormContainerProps {
  x: number;
  y: number;
}

export const CommentFormContainer = styled.div<CommentFormContainerProps>`
  position: fixed;
  bottom: ${({ y }) => y}px;
  left: ${({ x }) => x + 20}px;
  transform: translateY(50%);
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 36px;
  width: 396px;
  height: auto;
  background-color: white;
  padding: 18px;
  border: 1px solid ${colors["gray-1"]};
  border-radius: 10px;
  z-index: 10;

  &::before {
    content: "";
    position: absolute;
    top: 50%;
    left: -10px;
    transform: translateY(-50%);

    border-top: 10px solid transparent;
    border-right: 10px solid white;
    border-bottom: 10px solid transparent;
  }
`;

export const CommentInput = styled.textarea`
  width: 100%;
  min-height: 72px;
  padding: 10px;
  border: none;
  outline: none;
  box-shadow: none;
  resize: none;
  color: ${colors["dark-gray"]};
  font-size: 18px;
  line-height: 24px;
`;

export const ButtonContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 18px;
`;

export const SaveButton = styled.button`
  background-color: ${colors["blue-1"]};
  color: white;
  padding: 12px 18px;
  cursor: pointer;
  border-radius: 5px;
  font-size: 18px;
  border: 2px solid ${colors["blue-1"]};
  min-width: 160px;

  &:disabled {
    background-color: ${colors["gray-1"]};
    cursor: default;
    border: 2px solid ${colors["gray-1"]};
  }
`;

export const CancelButton = styled.button`
  background-color: white;
  color: ${colors["dark-gray"]};
  padding: 12px 18px;
  min-width: 160px;
  border: 2px solid ${colors["gray-1"]};
  cursor: pointer;
  border-radius: 5px;
  font-size: 18px;
`;
