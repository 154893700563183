import "@kitware/vtk.js/Rendering/Profiles/Geometry";
import "@kitware/vtk.js/Rendering/Profiles/Glyph";
import vtkRenderer from "@kitware/vtk.js/Rendering/Core/Renderer";
import vtkWidgetManager from "@kitware/vtk.js/Widgets/Core/WidgetManager";
import vtkLineWidget from "../vtkExtension/LineWidget";
import { ShapeType } from "@kitware/vtk.js/Widgets/Widgets3D/LineWidget/Constants";
import { isHandlePlaced } from "@kitware/vtk.js/Widgets/Widgets3D/LineWidget/helpers";
import "@kitware/vtk.js/favicon";
import vtkInteractorObserver from "@kitware/vtk.js/Rendering/Core/InteractorObserver";
import { bindSVGRepresentation } from "../vtkExtension/SVGHelpers";
import { Vector3 } from "@kitware/vtk.js/types";
import vtkAbstractWidget from "@kitware/vtk.js/Widgets/Core/AbstractWidget";
import vtkWidgetState from "@kitware/vtk.js/Widgets/Core/WidgetState";

const { computeWorldToDisplay } = vtkInteractorObserver;

const DISTANCE_POINT_COLOR = 0.75;
const DISTANCE_POINT_HIGHLIGHT_COLOR = 1.0;

const svgCleanupCallbacks: {
  widgetState: vtkWidgetState;
  cleanup: () => void;
}[] = [];

function setupSVG(widget: any, renderer: vtkRenderer) {
  const cleanupCallback = bindSVGRepresentation(
    renderer,
    widget.getWidgetState(),
    {
      mapState(widgetState: { getText: () => any }, { size }: any) {
        const textState = widgetState.getText();
        const text = textState.getText();
        const origin = textState.getOrigin();
        if (origin) {
          const coords = computeWorldToDisplay(renderer, ...origin);
          const position = [coords[0], size[1] - coords[1]];
          return {
            text,
            position,
          };
        }
        return null;
      },
      render(
        data: { position: any[]; text: any },
        h: (
          arg0: string,
          arg1: {
            key: string;
            attrs: {
              x: any;
              y: any;
              dx: number;
              dy: number;
              fill: string;
              "font-size": number;
            };
          },
          arg2: any
        ) => any
      ) {
        if (data) {
          return h(
            "text",
            {
              key: "lineText",
              attrs: {
                x: data.position[0],
                y: data.position[1],
                dx: 12,
                dy: -12,
                fill: "#313030",
                "font-size": 24,
              },
            },
            data.text
          );
        }
        return [];
      },
    }
  );

  // Store the cleanup callback and widgetState for later cleanup
  svgCleanupCallbacks.push({
    widgetState: widget.getWidgetState(),
    cleanup: cleanupCallback,
  });
}

export class MeasurementManager {
  private state = "created";
  private widgetManager: vtkWidgetManager;
  private renderer: vtkRenderer;
  private lineWidgetHandle: any;

  // Use a Set to manage widgets
  private widgets: Set<vtkAbstractWidget> = new Set();

  constructor(widgetManager: vtkWidgetManager, renderer: vtkRenderer) {
    this.widgetManager = widgetManager;
    this.renderer = renderer;
  }

  private setState(state: string) {
    this.state = state;
  }

  public isCreating() {
    return this.state === "creating";
  }

  public startCreation() {
    this.setState("creating");
  }

  public finishCreation() {
    this.setState("created");
  }

  public createLineWidget = (
    finishCallback: (data: any) => void,
    updateCallback: (widgetHandle: any) => void
  ) => {
    if (this.isCreating()) {
      return;
    }
    this.startCreation();

    // Create a line widget
    const lineWidget = vtkLineWidget.newInstance();

    this.lineWidgetHandle = this.widgetManager.addWidget(lineWidget) as any;

    this.configureWidget(lineWidget, this.lineWidgetHandle);
    this.lineWidgetHandle.updateHandleVisibility(1);
    this.lineWidgetHandle.getInteractor().render();

    this.widgetManager.grabFocus(lineWidget);

    this.lineWidgetHandle.onInteractionEvent(() => {
      MeasurementManager.updateDistanceText(lineWidget, this.lineWidgetHandle);
      updateCallback(this.lineWidgetHandle);
    });

    this.lineWidgetHandle.onEndInteractionEvent(() => {
      MeasurementManager.updateDistanceText(lineWidget, this.lineWidgetHandle);

      const widgetState = this.lineWidgetHandle.getWidgetState();
      const handle1 = isHandlePlaced(0, widgetState);
      const handle2 = isHandlePlaced(1, widgetState);

      if (handle1 && handle2 && lineWidget.getDistance().toFixed(2) > 0) {
        if (!this.widgets.has(this.lineWidgetHandle)) {
          this.widgets.add(this.lineWidgetHandle);
          this.finishCreation();
          finishCallback(this.lineWidgetHandle);
        }
      }
    });
    return { lineWidget, widgetHandle: this.lineWidgetHandle };
  };

  public createFinishedLineWidget = (
    point1: Vector3,
    point2: Vector3,
    textPosition: Vector3,
    updateCallback: () => void
  ) => {
    // Create a line widget
    const lineWidget = vtkLineWidget.newInstance();
    const lineWidgetHandle = this.widgetManager.addWidget(lineWidget) as any;

    this.configureWidget(lineWidget, lineWidgetHandle);

    lineWidgetHandle
      .getWidgetState()
      .getHandle1()
      .setOrigin(point1[0], point1[1], point1[2]);
    lineWidgetHandle
      .getWidgetState()
      .getHandle2()
      .setOrigin(point2[0], point2[1], point2[2]);
    lineWidgetHandle
      .getWidgetState()
      .getText()
      .setOrigin(textPosition[0], textPosition[1], textPosition[2]);
    MeasurementManager.updateDistanceText(lineWidget, lineWidgetHandle);

    lineWidgetHandle.updateHandleVisibility(0);
    lineWidgetHandle.updateHandleVisibility(1);
    lineWidgetHandle.getInteractor().render();

    lineWidgetHandle.onInteractionEvent(() => {
      MeasurementManager.updateDistanceText(lineWidget, lineWidgetHandle);
      updateCallback();
    });
    this.widgets.add(lineWidgetHandle);
    this.finishCreation();

    return lineWidgetHandle;
  };

  public clearLineWidget(widget: vtkAbstractWidget) {
    this.finishCreation();
    if (this.widgets.has(widget)) {
      const widgetState = widget.getWidgetState();
      const svgIndex = svgCleanupCallbacks.findIndex(
        (callback) => callback.widgetState === widgetState
      );

      if (svgIndex !== -1) {
        svgCleanupCallbacks[svgIndex].cleanup();
        svgCleanupCallbacks.splice(svgIndex, 1);
      }

      // Remove the widget from the widget manager
      this.widgetManager.removeWidget(widget);
      this.widgets.delete(widget);
    }
  }

  public clearLineWidgets = () => {
    if (this.isCreating()) {
      this.widgetManager.removeWidget(this.lineWidgetHandle);
    }

    this.finishCreation();
    this.widgetManager.removeWidgets();

    svgCleanupCallbacks.forEach(({ cleanup }) => {
      cleanup();
    });
    svgCleanupCallbacks.length = 0;

    this.widgets.clear();
  };

  public highlightWidget = (widgetToHighlight: any | null) => {
    this.widgetManager.getWidgets().forEach((widget: any) => {
      const widgetState = widget.getWidgetState() as any;

      const color =
        widget === widgetToHighlight
          ? DISTANCE_POINT_HIGHLIGHT_COLOR
          : DISTANCE_POINT_COLOR;
      widgetState.getHandle1().setColor(color);
      widgetState.getHandle2().setColor(color);
      widget.updateHandleVisibility(0);
      widget.updateHandleVisibility(1);
    });
  };

  private configureWidget(lineWidget: any, lineWidgetHandle: any) {
    lineWidgetHandle.setActiveColor(DISTANCE_POINT_HIGHLIGHT_COLOR);
    lineWidgetHandle.setActiveScaleFactor(1.1);

    const widgetState = lineWidgetHandle.getWidgetState();

    setupSVG(lineWidget, this.renderer);

    widgetState.getHandle1().setShape(ShapeType.SPHERE);
    widgetState.getHandle1().setScale1(15);
    widgetState.getHandle1().setColor(DISTANCE_POINT_COLOR);

    lineWidgetHandle.updateHandleVisibility(0);
    lineWidgetHandle.getInteractor().render();

    widgetState.getHandle2().setShape(ShapeType.SPHERE);
    widgetState.getHandle2().setScale1(15);
    widgetState.getHandle2().setColor(DISTANCE_POINT_COLOR);

    const subState = widgetState.getPositionOnLine();
    subState.setPosOnLine(0.55);
  }

  static updateDistanceText(lineWidget: any, lineWidgetHandle: any) {
    lineWidgetHandle.setText(lineWidget.getDistance().toFixed(2) + "mm");
  }
}

export default MeasurementManager;
