import styled from "styled-components";

export const LegendContainer = styled.div`
  position: absolute;
  right: 20px;
  top: calc(50% - 118px);
  color: #4d4d4d;
`;

export const Title = styled.div`
  font-size: 22px;
  margin-bottom: 20px;
`;

export const RulerContainer = styled.div`
  display: flex;
`;

export const RulerScalersArea = styled.div`
  display: flex;
  flex-direction: column;
  height: 360px;
  margin-left: auto;
  margin-right: 3px;
  padding-top: 45px;
`;

export const RulerScaler = styled.span`
  font-size: 18px;
  text-align: right;
  height: 60px;
`;

export const ColorsContainer = styled.div`
  width: 12px;
  height: 360px;
  background: linear-gradient(
    #b700b7 0px 55px,
    #ff0000 65px 115px,
    #ff7f00 125px 175px,
    #ffbf00 185px 235px,
    #dfff00 245px 295px,
    #00eaff 305px 360px
  );
`;
