import React from "react";

const EmptyBox: React.FC = () => {
  return (
    <svg
      width="130"
      height="134"
      viewBox="0 0 130 134"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M14.9425 25.9043H115.057L130 133.5H0L14.9425 25.9043Z"
        fill="url(#paint0_linear_20330_23562)"
      />
      <path
        d="M0 90.9101L14.9425 0.5V25.9045L0 132.006V90.9101Z"
        fill="url(#paint1_linear_20330_23562)"
      />
      <path
        d="M130 90.9101L115.057 0.5V25.9045L130 132.006V90.9101Z"
        fill="url(#paint2_linear_20330_23562)"
      />
      <path
        d="M37.3563 93.1631C37.3563 91.5062 36.0132 90.1631 34.3563 90.1631H0V133.5H130V90.1631H95.6437C93.9868 90.1631 92.6437 91.5062 92.6437 93.1631V100.624C92.6437 104.75 89.2987 108.096 85.1724 108.096H44.8276C40.7013 108.096 37.3563 104.75 37.3563 100.624V93.1631Z"
        fill="#C3C3C3"
      />
      <rect
        x="14.9424"
        y="0.5"
        width="100.115"
        height="25.4045"
        fill="url(#paint3_linear_20330_23562)"
      />
      <defs>
        <linearGradient
          id="paint0_linear_20330_23562"
          x1="65"
          y1="25.9043"
          x2="64.2527"
          y2="111.831"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#BEBDBD" />
          <stop offset="1" stop-color="#515151" />
        </linearGradient>
        <linearGradient
          id="paint1_linear_20330_23562"
          x1="7.47126"
          y1="0.5"
          x2="7.47126"
          y2="105.854"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#AAAAAA" />
          <stop offset="1" stop-color="#4B4B4B" />
        </linearGradient>
        <linearGradient
          id="paint2_linear_20330_23562"
          x1="122.529"
          y1="0.5"
          x2="122.529"
          y2="105.854"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#AAAAAA" />
          <stop offset="1" stop-color="#4B4B4B" />
        </linearGradient>
        <linearGradient
          id="paint3_linear_20330_23562"
          x1="64.9999"
          y1="0.5"
          x2="64.9999"
          y2="25.9045"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#C4C4C4" />
          <stop offset="1" stopColor="#ABABAB" />
        </linearGradient>
      </defs>
    </svg>
  );
};

export default EmptyBox;
