import { createContext, useEffect, useState } from "react";
import { ApplicationInsights } from "@microsoft/applicationinsights-web";
import { ReactPlugin } from "@microsoft/applicationinsights-react-js";
import { createBrowserHistory } from "history";
import { IAppConfig, loadConfig } from "../services/appConfigService";
import { ErrorText, ErrorTitle, MessageContainer } from "./Providers.styled";
import { AppInsightsContext } from "@microsoft/applicationinsights-react-js";

export const AppConfigContext = createContext<{
  config?: IAppConfig | null;
  buildVersion?: string | null;
}>({ config: undefined, buildVersion: undefined });

export const AppConfigProvider = ({ children }: any) => {
  const [config, setConfig] = useState<IAppConfig | undefined | null>();
  const [reactPlugin, setReactPlugin] = useState<ReactPlugin | null>(null);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const initializeAppInsights = (config: IAppConfig) => {
      let appInsights: ApplicationInsights | null = null;
      try {
        const browserHistory = createBrowserHistory();
        const reactPluginInstance = new ReactPlugin();

        appInsights = new ApplicationInsights({
          config: {
            connectionString: config.connectionString,
            extensions: [reactPluginInstance],
            extensionConfig: {
              [reactPluginInstance.identifier]: { history: browserHistory },
            },
            enableAutoRouteTracking: true,
            autoTrackPageVisitTime: true,
          },
        });

        appInsights.loadAppInsights();

        const urlParams = new URLSearchParams(window.location.search);
        const token = urlParams.get("token");

        if (token) {
          try {
            const payload = token.split(".")[1];
            const decodedPayload = JSON.parse(atob(payload));
            const { oid, email, name } = decodedPayload;

            appInsights.trackEvent({
              name: "User Info",
              properties: { oid, email, name },
            });
          } catch (error) {
            console.error("Error decoding token:", error);
          }
        }

        appInsights.trackPageView({ name: "Initialized page view" });
        appInsights.trackEvent({ name: "User Session Start" });

        setReactPlugin(reactPluginInstance);
      } catch (error) {
        console.error("Error initializing Application Insights:", error);
        if (appInsights) {
          appInsights.trackException({ exception: error as Error });
        }
        setReactPlugin(null);
      }
    };

    loadConfig()
      .then((config) => {
        setConfig(config);
        setIsLoading(false);

        if (config?.connectionString) {
          initializeAppInsights(config);
        }
      })
      .catch((error) => {
        console.error("Error loading config:", error);
        setIsLoading(false);
        setConfig(null);
      });
  }, []);

  if (isLoading) {
    return null;
  }

  if (config === null) {
    return (
      <MessageContainer>
        <ErrorTitle>Error :</ErrorTitle>
        <ErrorText>Failed to load application configuration.</ErrorText>
      </MessageContainer>
    );
  }

  return (
    <AppConfigContext.Provider
      value={{ config, buildVersion: config ? config.buildVersion : null }}
    >
      {reactPlugin ? (
        <AppInsightsContext.Provider value={reactPlugin}>
          {children}
        </AppInsightsContext.Provider>
      ) : (
        children
      )}
    </AppConfigContext.Provider>
  );
};

export { loadConfig };
