import React, {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState,
} from "react";
import DICOMReader, { IMeshNode } from "@envistaco/dicom-reader";
import { MeshName } from "../utils/common";
import QWebChannel from "../utils/qwebchanel";
import { NormalMesh, buildMesh } from "../model/Mesh";
import { Comment } from "../model/Comment";
import { CrossSection } from "../model/Measurement/CrossSection";
import { Measurement } from "../model/Measurement/Measurement";
import { AnalysesDataManager } from "../utils/AnalysesDataManager";
import { hexToRgb } from "../utils/hexToRgb";
import { AppInsightsContext } from "@microsoft/applicationinsights-react-js";

export const AnimationState = {
  Stopped: 0,
  Playing: 1,
  Paused: 2,
};

export interface IAppGlobalContext {
  qtContext: any;
  patient: IPatient | null;
  normalMeshes: NormalMesh[];
  setNormalMeshes: React.Dispatch<React.SetStateAction<NormalMesh[]>>;
  activeNormalMeshes: NormalMesh[];
  setActiveNormalMeshes: React.Dispatch<React.SetStateAction<NormalMesh[]>>;
  measurementMeshes: { id: string; visible: boolean }[];
  setMeasurementMeshes?: React.Dispatch<
    React.SetStateAction<{ id: string; visible: boolean }[]>
  >;
  opacityValues: { [key: string]: number };
  setOpacityValues: React.Dispatch<
    React.SetStateAction<{ [key: string]: number }>
  >;
  capturedSnapshots: ISnapshot[];
  setCapturedSnapshots: React.Dispatch<React.SetStateAction<ISnapshot[]>>;
  groupedMeshesByDicomId: {
    [key: string]: { meshes: NormalMesh[]; isActive: boolean };
  } | null;
  setGroupedMeshesByDicomId: React.Dispatch<
    React.SetStateAction<{
      [key: string]: { meshes: NormalMesh[]; isActive: boolean };
    } | null>
  >;
  comments: Comment[];
  setComments: React.Dispatch<React.SetStateAction<Comment[]>>;
  isCommentModeActive: boolean;
  setIsCommentModeActive: React.Dispatch<React.SetStateAction<boolean>>;
  animationState: number;
  setAnimationState: React.Dispatch<React.SetStateAction<number>>;
  analysesDataManager: AnalysesDataManager;
  isMatching: boolean;
  setIsMatching: React.Dispatch<React.SetStateAction<boolean>>;
  isTrueColorOn: boolean;
  setIsTrueColorOn: React.Dispatch<React.SetStateAction<boolean>>;
  isHideCommentsOn: boolean;
  setIsHideCommentsOn: React.Dispatch<React.SetStateAction<boolean>>;
  crossSection: CrossSection | null;
  setCrossSection: React.Dispatch<React.SetStateAction<CrossSection | null>>;
  measurements: Measurement[];
  setMeasurements: React.Dispatch<React.SetStateAction<Measurement[]>>;
  locale: string | null;
  setLocale: React.Dispatch<React.SetStateAction<string | null>>;
  translations: { [key: string]: string };
  areTranslationsLoaded: boolean;
}

export interface ISnapshot {
  snapshot: string;
  saved: boolean;
}
export interface IPatient {
  id: string;
  name: string;
}
export interface IDicomMesh {
  seriesInstanceUID: string;
  acquisitionDateTime: string;
  meshes: IMeshNode[];
}

export const AppGlobalDataContext = createContext<IAppGlobalContext>({
  qtContext: null,
  patient: null,
  normalMeshes: [],
  setNormalMeshes: () => {},
  activeNormalMeshes: [],
  setActiveNormalMeshes: () => {},
  measurementMeshes: [],
  opacityValues: {},
  setOpacityValues: () => {},
  capturedSnapshots: [],
  setCapturedSnapshots: () => {},
  groupedMeshesByDicomId: null,
  setGroupedMeshesByDicomId: () => {},
  comments: [],
  setComments: () => {},
  isCommentModeActive: false,
  setIsCommentModeActive: () => {},
  animationState: AnimationState.Stopped,
  setAnimationState: () => {},
  analysesDataManager: new AnalysesDataManager(),
  isMatching: false,
  setIsMatching: () => {},
  isTrueColorOn: false,
  setIsTrueColorOn: () => {},
  isHideCommentsOn: false,
  setIsHideCommentsOn: () => {},
  crossSection: null,
  setCrossSection: () => {},
  measurements: [],
  setMeasurements: () => {},
  locale: null,
  setLocale: () => {},
  translations: {},
  areTranslationsLoaded: false,
});

const defaultColors = [
  "#4d9960",
  "#cc3d79",
  "#52bbcc",
  "#b26a36",
  "#595ec2",
  "#9c59b2",
];

type StepDataCacher = {
  [name: string]: {
    [step: number]: string;
  };
};

export const AppGlobalDataProvider = ({ children }: any) => {
  const [qtContext, setQtContext] = useState<any>(null);
  const reactPlugin = useContext(AppInsightsContext);
  const [patient, setPatient] = useState<IPatient | null>(null);
  const [allFetchedDicoms, setAllFetchedDicoms] = useState<IDicomMesh[]>([]);
  const [normalMeshes, setNormalMeshes] = useState<NormalMesh[]>([]);
  const [activeNormalMeshes, setActiveNormalMeshes] = useState<NormalMesh[]>(
    []
  );
  const [measurementMeshes, setMeasurementMeshes] = useState<
    { id: string; visible: boolean }[]
  >([]);
  const [groupedMeshesByDicomId, setGroupedMeshesByDicomId] = useState<{
    [key: string]: { meshes: NormalMesh[]; isActive: boolean };
  } | null>(null);
  const [opacityValues, setOpacityValues] = useState<{
    [key: string]: number;
  }>({});
  const [capturedSnapshots, setCapturedSnapshots] = useState<ISnapshot[]>([]);
  const [colorsCount] = useState(defaultColors.length);
  const [loadState, setLoadState] = useState<{
    timeoutId: any;
    dataArr: string[];
    isMeshesReady: boolean;
    isAnalysesDataReady: boolean;
    hostDataCount: number;
  }>({
    timeoutId: null,
    dataArr: [],
    isMeshesReady: false,
    isAnalysesDataReady: false,
    hostDataCount: 0,
  });
  const [comments, setComments] = useState<Comment[]>([]);
  const [isCommentModeActive, setIsCommentModeActive] = useState(false);
  const [stepCacher, setStepCacher] = useState<StepDataCacher>({});
  const [animationState, setAnimationState] = useState<number>(
    AnimationState.Stopped
  );
  const [isMatching, setIsMatching] = useState<boolean>(false);
  const [isTrueColorOn, setIsTrueColorOn] = useState(false);
  const [isHideCommentsOn, setIsHideCommentsOn] = useState(false);
  const [crossSection, setCrossSection] = useState<CrossSection | null>(null);
  const [measurements, setMeasurements] = useState<Measurement[]>([]);
  const [locale, setLocale] = useState<string | null>(null);
  const [translations, setTranslations] = useState<{ [key: string]: string }>(
    {}
  );
  const [areTranslationsLoaded, setAreTranslationsLoaded] = useState(false);

  const analysesDataManager = useRef<AnalysesDataManager>(
    new AnalysesDataManager()
  );

  const parseLoadData = useCallback(
    async (dataArr: string[]) => {
      const dicoms: IDicomMesh[] = [];
      dataArr.forEach((data) => {
        if (!data) {
          return;
        }
        let raw: string = "";
        try {
          raw = atob(data);
        } catch (err) {
          console.error(err);
          return;
        }
        const array = new Uint8Array(new ArrayBuffer(raw.length));
        for (let i = 0; i < raw.length; i++) {
          array[i] = raw.charCodeAt(i);
        }
        const dicomReader = new DICOMReader(array.buffer);
        const dcmData = dicomReader.extractData();
        const meshes = dcmData.meshes.filter((mesh) => mesh.meshBuffer);

        // We have to filter mesh parts to have only one mesh part per arch
        let commonMeshes = meshes.filter(
          (mesh) =>
            mesh.meshPart === MeshName.MaxillaryPreScan ||
            mesh.meshPart === MeshName.MandibularPreScan
        );
        if (commonMeshes.length === 0) {
          commonMeshes = meshes.filter(
            (mesh) =>
              mesh.meshPart === MeshName.MaxillaryAnatomy ||
              mesh.meshPart === MeshName.MandibularAnatomy
          );
        }
        if (commonMeshes.length === 0) {
          commonMeshes = meshes.filter(
            (mesh) =>
              mesh.meshPart === MeshName.Maxillary ||
              mesh.meshPart === MeshName.Mandibular
          );
        }
        if (commonMeshes.length === 0) {
          return;
        }
        dicoms.push({
          meshes: commonMeshes,
          acquisitionDateTime: dcmData.acquisitionDateTime,
          seriesInstanceUID: dcmData.seriesInstanceUID,
        });
      });
      setAllFetchedDicoms(dicoms);
    },
    [setAllFetchedDicoms]
  );

  useEffect(() => {
    if (loadState.dataArr.length <= 0) {
      return;
    }
    if (loadState.isMeshesReady && loadState.isAnalysesDataReady) {
      parseLoadData(loadState.dataArr);
      return;
    }
    if (loadState.hostDataCount === 0) {
      if (loadState.timeoutId !== null) {
        clearTimeout(loadState.timeoutId);
      }
      loadState.timeoutId = setTimeout(() => {
        setLoadState((a) => {
          return { ...a, isMeshesReady: true, timeoutId: null };
        });
      }, 5000);
    }
  }, [loadState, parseLoadData]);

  const handleDataLoadedByStep = useCallback(
    async (data: string, name: string, step: number, stepCount: number) => {
      setLoadState((a) => {
        if (a.isMeshesReady) {
          return a;
        }
        const curItem = stepCacher[name] ? stepCacher[name] : {};
        curItem[step] = data;
        if (Object.values(curItem).length === stepCount) {
          let fullData: string = "";
          for (var i = 0; i < stepCount; ++i) {
            fullData += curItem[i];
          }
          if (fullData) {
            a.dataArr.push(fullData);
            a.isMeshesReady = a.dataArr.length === a.hostDataCount;
          }
        }
        stepCacher[name] = curItem;
        setStepCacher({ ...stepCacher });
        return { ...a };
      });
    },
    [stepCacher]
  );

  useEffect(() => {
    if (globalThis.qt && !qtContext) {
      new QWebChannel(
        globalThis.qt.webChannelTransport,
        (channel: { objects: { WebContext: any } }) => {
          const ctxt = channel.objects.WebContext;
          setQtContext(ctxt);
          setLocale(ctxt.locale);
          console.log(ctxt);
        }
      );
    }
  }, [qtContext]);

  const loadTranslations = useCallback(async (locale: string) => {
    try {
      setAreTranslationsLoaded(false);
      const translationFile = await import(`../translate/${locale}.json`);
      setTranslations(translationFile.default);
      setAreTranslationsLoaded(true);
    } catch (error) {
      console.error(`Error loading translations for locale ${locale}:`, error);
      if (locale !== "en_US") {
        loadTranslations("en_US");
      }
    } finally {
      setAreTranslationsLoaded(true);
    }
  }, []);

  useEffect(() => {
    if (locale) {
      loadTranslations(locale);
    }
  }, [locale, loadTranslations]);

  useEffect(() => {
    if (qtContext && areTranslationsLoaded) {
      analysesDataManager.current.setQtContext(qtContext);
      setPatient({ id: qtContext.patientID, name: qtContext.patientName });
      setLoadState((a) => {
        // for compatibility with scanflow beta1 version
        return { ...a, hostDataCount: qtContext.dicomList?.length ?? 0 };
      });
      qtContext.dataLoaded.connect(async (data: string, name: any) => {
        setLoadState((a) => {
          if (a.isMeshesReady) {
            return a;
          }
          a.dataArr.push(data);
          a.isMeshesReady = a.dataArr.length === a.hostDataCount;
          return { ...a };
        });
      });
      qtContext.dataLoadedByStep?.connect(handleDataLoadedByStep);
      qtContext.analysesDataLoaded.connect(async (data: any) => {
        setLoadState((a) => {
          if (a.isAnalysesDataReady) {
            return a;
          }
          analysesDataManager.current.load(data);
          return { ...a, isAnalysesDataReady: true };
        });
      });

      qtContext.requestData();
    }
  }, [qtContext, areTranslationsLoaded, handleDataLoadedByStep]);

  const parseMesh = useCallback(
    async (
      meshes: IMeshNode[],
      fileNumber: number,
      acquisitionDateTime: string,
      scanId: string
    ) => {
      const meshObjects = [];
      fileNumber = fileNumber % colorsCount;
      const color = hexToRgb(defaultColors[fileNumber]);

      for (let mesh of meshes) {
        const meshObj = await buildMesh(
          mesh,
          color,
          acquisitionDateTime,
          scanId
        );
        meshObjects.push(meshObj);
      }
      return meshObjects;
    },
    [colorsCount]
  );

  // Load comments from context
  useEffect(() => {
    if (
      !loadState.isAnalysesDataReady ||
      !loadState.isMeshesReady ||
      normalMeshes.length === 0
    )
      return;

    let loadedComments: Comment[] = [];
    analysesDataManager.current.getComments().forEach((comment) => {
      const parentMesh = normalMeshes.find(
        (mesh) =>
          mesh.scanId === comment.parent && mesh.name === comment.parentCatalog
      );
      if (parentMesh) {
        loadedComments.push(
          new Comment(
            comment.comment,
            comment.position,
            parentMesh,
            new Date(comment.timestamp)
          )
        );
      } else {
        console.error("Failed to retrieve comment parent mesh");
      }
      setComments(loadedComments);
    });
  }, [
    loadState.isAnalysesDataReady,
    loadState.isMeshesReady,
    normalMeshes,
    setComments,
  ]);

  // Save comments to context
  useEffect(() => {
    analysesDataManager.current.updateComments(comments);
  }, [comments]);

  useEffect(() => {
    if (!allFetchedDicoms.length) {
      return;
    }

    const sortedDicomsByDate = allFetchedDicoms.sort(
      (a, b) => Number(b.acquisitionDateTime) - Number(a.acquisitionDateTime)
    );

    const parseAllMeshes = async () => {
      const allMeshes: NormalMesh[] = [];
      const groupsMeshes: {
        [key: string]: { meshes: NormalMesh[]; isActive: boolean };
      } = {};
      const meshesOpacity: {
        [key: string]: number;
      } = {};

      const result = await Promise.all(
        sortedDicomsByDate.map((IDicom, index) =>
          parseMesh(
            IDicom.meshes,
            index,
            IDicom.acquisitionDateTime,
            IDicom.seriesInstanceUID
          )
        )
      );

      result.forEach((meshes) => {
        allMeshes.push(...meshes);
        groupsMeshes[meshes[0].scanId] = { meshes: meshes, isActive: true };
        meshes.forEach((mesh) => (meshesOpacity[mesh.id] = 100));
      });

      setNormalMeshes(allMeshes);
      setActiveNormalMeshes(allMeshes.filter((m) => m.isActive));
      setGroupedMeshesByDicomId(groupsMeshes);
      setOpacityValues(meshesOpacity);

      const numberOfScans = Object.keys(groupsMeshes).length;

      if (reactPlugin) {
        const appInsights = reactPlugin.getAppInsights();

        appInsights.trackEvent({
          name: "Scans Loaded",
          properties: {
            scanCount: numberOfScans,
            scanIds: Object.keys(groupsMeshes).join(", "),
          },
        });
      }
    };
    parseAllMeshes();
  }, [allFetchedDicoms, parseMesh, reactPlugin]);

  return (
    <AppGlobalDataContext.Provider
      value={{
        qtContext,
        patient,
        normalMeshes,
        setNormalMeshes,
        activeNormalMeshes,
        setActiveNormalMeshes,
        groupedMeshesByDicomId,
        setGroupedMeshesByDicomId,
        measurementMeshes,
        setMeasurementMeshes,
        opacityValues,
        setOpacityValues,
        capturedSnapshots,
        setCapturedSnapshots,
        comments,
        setComments,
        isCommentModeActive,
        setIsCommentModeActive,
        animationState,
        setAnimationState,
        analysesDataManager: analysesDataManager.current,
        isMatching,
        setIsMatching,
        isTrueColorOn,
        setIsTrueColorOn,
        isHideCommentsOn,
        setIsHideCommentsOn,
        crossSection,
        setCrossSection,
        measurements,
        setMeasurements,
        locale,
        setLocale,
        translations,
        areTranslationsLoaded,
      }}
    >
      {children}
    </AppGlobalDataContext.Provider>
  );
};
