import React from "react";

const HelpIcon: React.FC = () => {
  return (
    <svg
      width="36"
      height="36"
      viewBox="0 0 36 36"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M18 34C26.8366 34 34 26.8366 34 18C34 9.16344 26.8366 2 18 2C9.16344 2 2 9.16344 2 18C2 26.8366 9.16344 34 18 34ZM18 36C27.9411 36 36 27.9411 36 18C36 8.05887 27.9411 0 18 0C8.05887 0 0 8.05887 0 18C0 27.9411 8.05887 36 18 36ZM19.6 25.4C19.6 26.2836 18.8837 27 18 27C17.1164 27 16.4 26.2836 16.4 25.4C16.4 24.5163 17.1164 23.8 18 23.8C18.8837 23.8 19.6 24.5163 19.6 25.4ZM15.1579 13.2873C15.4128 12.4374 16.2725 11 18 11C19.8676 11 21 12.4686 21 14C21 14.3438 20.8659 14.706 20.5581 15.1421C20.2416 15.5903 19.807 16.0288 19.2929 16.5429L19.2632 16.5726L19.2632 16.5726C18.7841 17.0517 18.2328 17.6028 17.8081 18.2046C17.3659 18.831 17 19.5938 17 20.5V21C17 21.5523 17.4478 22 18 22C18.5523 22 19 21.552 19 20.9997V20.5C19 20.1562 19.1342 19.794 19.442 19.3579C19.7584 18.9097 20.1931 18.4712 20.7071 17.9571L20.7369 17.9274C21.216 17.4483 21.7673 16.8972 22.192 16.2954C22.6342 15.669 23 14.9062 23 14C23 11.5314 21.1325 9 18 9C14.9276 9 13.5872 11.5626 13.2422 12.7127C13.0836 13.2417 13.3838 13.7992 13.9128 13.9578C14.4418 14.1165 14.9993 13.8163 15.1579 13.2873Z"
        fill="#4D4D4D"
      />
    </svg>
  );
};

export default HelpIcon;
