import React from "react";

const WarningIcon: React.FC = () => {
  return (
    <svg
      width="62"
      height="62"
      viewBox="0 0 62 62"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M55.2479 49L32.7313 10C31.9615 8.66666 30.037 8.66667 29.2672 9.99999L6.75053 49C5.98073 50.3333 6.94298 52 8.48259 52H53.5159C55.0555 52 56.0178 50.3333 55.2479 49ZM36.1954 8.00001C33.886 4.00001 28.1125 3.99999 25.8031 7.99999L3.28643 47C0.97703 51 3.86378 56 8.48259 56H53.5159C58.1347 56 61.0215 51 58.7121 47L36.1954 8.00001ZM27.6579 24.9456C27.3136 22.8802 28.9064 21 31.0003 21C33.0942 21 34.6869 22.8802 34.3427 24.9456L32.1647 38.0136C32.0698 38.5828 31.5773 39 31.0003 39C30.4232 39 29.9307 38.5828 29.8359 38.0136L27.6579 24.9456ZM31.0003 48C32.6571 48 34.0003 46.6569 34.0003 45C34.0003 43.3432 32.6571 42 31.0003 42C29.3434 42 28.0003 43.3432 28.0003 45C28.0003 46.6569 29.3434 48 31.0003 48Z"
        fill="#FE9223"
      />
    </svg>
  );
};

export default WarningIcon;
