import styled, { keyframes } from "styled-components";
import { colors } from "../../../theme";

export const TimelineWrapper = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  max-width: calc(100vw - 720px);
  padding: 10px 0;
  border-radius: 5px;
  position: absolute;
  bottom: 40px;
  left: 44%;
  transform: translateX(-50%);
`;

export const TimelineLineWrapper = styled.div`
  position: absolute;
  bottom: 32px;
  left: 0;
  right: 0;
  height: 2px;
`;

export const TimelineItem = styled.div<{
  $relativePosition: number;
  $isFirstOrLast: boolean;
}>`
  position: absolute;
  display: flex;
  flex-direction: column;
  align-items: center;
  z-index: 1;
  left: ${({ $relativePosition }) => `${$relativePosition * 100}%`};
  transform: translateX(-50%);
  width: ${({ $isFirstOrLast }) => ($isFirstOrLast ? "16px" : "auto")};
`;

export const TimelineLine = styled.div`
  height: 4px;
  background: ${colors["gray-1"]};
  position: relative;
  z-index: 0;
  flex-grow: 1;
`;

const ButtonBase = styled.button`
  position: absolute;
  bottom: 55px;
  width: 36px;
  height: 36px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: transparent;
  border: none;
  border-radius: 50%;
  box-shadow: none;
  outline: none;
  padding: 0;
  cursor: pointer;
  transition:
    transform 0.2s ease-in-out,
    opacity 0.2s ease-in-out;

  img {
    width: 100%;
    height: 100%;
  }

  &:active {
    transform: scale(0.9);
  }

  &:disabled img {
    opacity: 0.4;
  }
`;

interface ButtonProps {
  $show: boolean;
}

const fadeInAnimation = keyframes`
  from { opacity: 0; }
  to { opacity: 1; }
`;

const fadeOutAnimation = keyframes`
  from { opacity: 1; }
  to { opacity: 0; }
`;

export const ButtonContainer = styled.div`
  display: flex;
  gap: 10px;
  position: absolute;
  bottom: 0px;
  left: 120px;
`;

export const PlayButton = styled(ButtonBase)<ButtonProps>`
  left: 0;
  animation: ${({ $show }) => ($show ? fadeInAnimation : fadeOutAnimation)} 0.3s
    forwards;
`;

export const StopButton = styled(ButtonBase)<ButtonProps>`
  left: ${({ $show }) => ($show ? "1px" : "0")};
  animation: ${({ $show }) => ($show ? fadeInAnimation : fadeOutAnimation)} 0.3s
    forwards;
`;

export const PauseButton = styled(ButtonBase)<ButtonProps>`
  left: ${({ $show }) => ($show ? "45px" : "0")};
  animation: ${({ $show }) => ($show ? fadeInAnimation : fadeOutAnimation)} 0.3s
    forwards;
`;

export const DateTimeDisplay = styled.div<{ color: string }>`
  font-size: 14px;
  margin-top: 8px;
  white-space: pre-wrap;
  text-align: center;
  font-weight: 700;

  & .date {
    color: ${(props) => props.color};
  }

  & .year {
    color: ${colors["dark-gray"]};
  }
`;

export const TimelineCursor = styled(ButtonBase)<{
  $relativePosition: number;
}>`
  position: absolute;
  bottom: 56px;
  display: flex;
  flex-direction: column;
  align-items: center;
  z-index: 1;
  left: calc(
    ${({ $relativePosition }) => `${$relativePosition * 100}%`} - 18px
  );
`;
