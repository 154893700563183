import { useContext } from "react";
import { AppGlobalDataContext } from "../../../providers/AppGlobalDataProvider";
import {
  RulerCard,
  RulerCardsArea,
  RulerContainer,
  RulerPanel,
  RulerPanelTitle,
  RulerScaler,
  RulerScalersArea,
} from "./OcclusionProximityRuler.styled";

export default function OcclusionProximityRuler() {
  const { translations } = useContext(AppGlobalDataContext);

  return (
    <RulerPanel>
      <RulerPanelTitle>{translations["OCCLUSION_PROXIMITY"]}</RulerPanelTitle>
      <RulerContainer>
        <RulerScalersArea>
          <RulerScaler>-0.10mm —</RulerScaler>
          <RulerScaler>-0.05mm —</RulerScaler>
          <RulerScaler>0.00mm —</RulerScaler>
          <RulerScaler>0.05mm —</RulerScaler>
          <RulerScaler>0.10mm —</RulerScaler>
        </RulerScalersArea>
        <RulerCardsArea>
          <RulerCard>{translations["COLLISION"]}</RulerCard>
          <RulerCard>{translations["STRONG"]}</RulerCard>
          <RulerCard>{translations["CONTACT"]}</RulerCard>
          <RulerCard>{translations["AVERAGE"]}</RulerCard>
          <RulerCard>{translations["WEAK"]}</RulerCard>
          <RulerCard>{translations["VERY_WEAK"]}</RulerCard>
        </RulerCardsArea>
      </RulerContainer>
    </RulerPanel>
  );
}
