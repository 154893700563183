import { Vector3 } from "@kitware/vtk.js/types";

export class CrossSection {
  origin: Vector3 = [0, 0, 0];
  normal: Vector3 = [0, 0, 0];

  constructor(origin: Vector3, normal: Vector3) {
    this.origin = origin;
    this.normal = normal;
  }
}
