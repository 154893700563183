import React, { MouseEventHandler } from "react";
import { useAppInsightsContext } from "@microsoft/applicationinsights-react-js";

interface TrackedButtonProps {
  onClick?: MouseEventHandler<HTMLButtonElement>;
  eventName: string;
  children: React.ReactNode;
  className?: string;
}

const TrackedButton: React.FC<TrackedButtonProps> = React.forwardRef<
  HTMLButtonElement,
  TrackedButtonProps
>(({ eventName, ...props }, ref) => {
  const appInsights = useAppInsightsContext();

  const handleClick = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    if (appInsights) {
      appInsights.trackEvent({ name: eventName });
    }
    props.onClick && props.onClick(e);
  };

  return (
    <button {...props} onClick={handleClick} ref={ref}>
      {props.children}
    </button>
  );
});

export default TrackedButton;
