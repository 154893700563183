import styled from "styled-components";
import { colors } from "../../theme";

export const StyledPage = styled.div`
  width: 100%;
  height: calc(100vh - 74px);
  overflow: hidden;

  & button {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 62px;
    height: 62px;
    border: 2px solid ${colors["gray-1"]};
    background-color: white;
    cursor: pointer;
    outline: none;

    &:hover:not(.active):not(.activeView) {
      background-color: ${colors["blue-2"]};
    }
  }
`;
