import React, { useCallback, useContext, useEffect, useState } from "react";
import { ButtonsContainer, DropdownContainer } from "./GenericToolBar.styled";
import commentsImage from "../../../images/comments.png";
import trueColorImage from "../../../images/true-color.png";
import snapShotImage from "../../../images/snapshot.png";
import zoomFitImage from "../../../images/zoom_fit.png";
import viewOrientationImage from "../../../images/ViewOrientation.png";
import frontViewImage from "../../../images/front-view.png";
import backViewImage from "../../../images/back-view.png";
import leftViewImage from "../../../images/left-view.png";
import rightViewImage from "../../../images/right-view.png";
import topViewImage from "../../../images/top-view.png";
import bottomViewImage from "../../../images/bottom-view.png";
import expandImage from "../../../images/expand.png";
import collapseImage from "../../../images/collapse.png";
import { ViewOrientation } from "../../../utils/common";
import vtkGenericRenderWindow from "@kitware/vtk.js/Rendering/Misc/GenericRenderWindow";
import vtkRenderer from "@kitware/vtk.js/Rendering/Core/Renderer";
import { AppGlobalDataContext } from "../../../providers/AppGlobalDataProvider";
import Tooltip from "../Tooltip/Tooltip";
import { mergeBase64 } from "../../../utils/mergeBase64";
import { RenderingMode } from "../../../model/Mesh";
import TrackedButton from "../TrackedButton/TrackedButton";

export interface IGenericToolBarProps {
  cameraOrientation?: ViewOrientation;
  setCameraOrientation?: React.Dispatch<React.SetStateAction<ViewOrientation>>;
  genericRenderWindow?: vtkGenericRenderWindow | null;
  multipleRenderers?: vtkRenderer[];
  multipleGenericRenderWindows?: vtkGenericRenderWindow[];
  withTrueColor?: boolean;
  withSnapshot?: boolean;
  withViewOrientation?: boolean;
  withComments?: boolean;
}

export const GenericToolBar: React.FC<IGenericToolBarProps> = ({
  cameraOrientation = ViewOrientation.Front,
  setCameraOrientation,
  genericRenderWindow,
  multipleRenderers,
  multipleGenericRenderWindows,
  withTrueColor = true,
  withSnapshot = true,
  withViewOrientation = true,
  withComments = true,
}: IGenericToolBarProps) => {
  const {
    normalMeshes,
    translations,
    setCapturedSnapshots,
    isTrueColorOn,
    setIsTrueColorOn,
    isCommentModeActive,
    setIsCommentModeActive,
  } = useContext(AppGlobalDataContext);
  const [isExpanded, setIsExpanded] = useState(true);
  const [showDropdown, setShowDropdown] = useState(false);

  const resetViewButtonUponRotation = useCallback(() => {
    if (!genericRenderWindow || !setCameraOrientation) return;

    let lastRendererEvents: string[] = [];
    const renderer = genericRenderWindow.getRenderer();
    renderer.onEvent((e: any) => {
      lastRendererEvents.length < 7
        ? lastRendererEvents.push(e.type)
        : (lastRendererEvents = [e.type]);

      if (
        lastRendererEvents.length === 7 &&
        !lastRendererEvents.includes("ResetCameraEvent")
      ) {
        setCameraOrientation(0);
      }
    });
  }, [genericRenderWindow, setCameraOrientation]);

  useEffect(() => {
    if (genericRenderWindow) {
      resetViewButtonUponRotation();
    }
  }, [genericRenderWindow, resetViewButtonUponRotation]);

  const handleViewOrientationClick = () => {
    setShowDropdown(!showDropdown);
  };

  const toggleExpand = () => {
    setIsExpanded(!isExpanded);
    setShowDropdown(false);
  };

  const handleZoomBtnClick = useCallback(() => {
    if (genericRenderWindow) {
      const renderer = genericRenderWindow.getRenderer();
      renderer.resetCamera();
      genericRenderWindow.resize();
    } else if (multipleRenderers && multipleGenericRenderWindows) {
      multipleRenderers.forEach((r) => r.resetCamera());
      multipleGenericRenderWindows.forEach((w) => w.resize());
    }
  }, [genericRenderWindow, multipleRenderers, multipleGenericRenderWindows]);

  const handleTrueColorClick = () => {
    if (!isTrueColorOn) {
      normalMeshes.forEach((mesh) => {
        mesh.setRenderingMode(RenderingMode.TrueColor);
      });
      genericRenderWindow?.getRenderWindow().render();
      setIsTrueColorOn(true);
    } else {
      normalMeshes.forEach((mesh) => {
        mesh.setRenderingMode(RenderingMode.Colored);
      });
      genericRenderWindow?.getRenderWindow().render();
      setIsTrueColorOn(false);
    }
  };

  const handleSnapshotClick = async () => {
    if (genericRenderWindow) {
      const renderWindow = genericRenderWindow.getRenderWindow();
      const view = renderWindow.getInteractor().getView();

      view.captureNextImage().then((image: any) => {
        setCapturedSnapshots((prevState) => [
          ...prevState,
          { snapshot: image, saved: false },
        ]);
      });
      renderWindow.render();
    }

    if (multipleGenericRenderWindows) {
      let mergedImage: string = "";

      multipleGenericRenderWindows.forEach((gl) => {
        const renderWindow = gl.getRenderWindow();
        const view = renderWindow.getInteractor().getView();

        view.captureNextImage().then((image: any) => {
          if (mergedImage) {
            mergeBase64([mergedImage, image])
              .then((result) => {
                mergedImage = result;
                setCapturedSnapshots((prevState) => [
                  ...prevState,
                  { snapshot: result, saved: false },
                ]);
              })
              .catch((err) => console.log(err));
          } else {
            mergedImage = image;
          }
        });
        renderWindow.render();
      });
    }
  };

  return (
    <ButtonsContainer className={!isExpanded ? "collapsed" : ""}>
      {withComments && (
        <Tooltip title={translations["COMMENT"]}>
          <TrackedButton
            className={isCommentModeActive ? "active" : ""}
            onClick={() => setIsCommentModeActive(!isCommentModeActive)}
            eventName="Comment button clicked"
          >
            <img src={commentsImage} alt="Comments" />
          </TrackedButton>
        </Tooltip>
      )}

      {withTrueColor && (
        <Tooltip title={translations["TRUE_COLOR"]}>
          <TrackedButton
            className={isTrueColorOn ? "active" : ""}
            onClick={handleTrueColorClick}
            eventName="True Color button clicked"
          >
            <img src={trueColorImage} alt="True Color" />
          </TrackedButton>
        </Tooltip>
      )}

      <Tooltip title={translations["ZOOM_FIT"]}>
        <TrackedButton
          onClick={handleZoomBtnClick}
          eventName="Zoom Fit button clicked"
        >
          <img src={zoomFitImage} alt="Zoom Fit" />
        </TrackedButton>
      </Tooltip>

      {withSnapshot && (
        <Tooltip title={translations["TAKE_SNAPSHOT"]}>
          <TrackedButton
            onClick={handleSnapshotClick}
            eventName="Snapshot button clicked"
          >
            <img src={snapShotImage} alt="Snap Shot" />
          </TrackedButton>
        </Tooltip>
      )}

      {withViewOrientation && (
        <Tooltip title={translations["STANDARD_VIEW"]}>
          <TrackedButton
            className={`viewOrientation ${showDropdown ? "active" : ""}`}
            onClick={handleViewOrientationClick}
            eventName="Standard View button clicked"
          >
            <img src={viewOrientationImage} alt="View Orientation" />
          </TrackedButton>
        </Tooltip>
      )}

      <DropdownContainer
        className={!showDropdown || !isExpanded ? "hidden" : ""}
      >
        <Tooltip title={translations["ANTERIOR_VIEW"]}>
          <TrackedButton
            onClick={() => {
              setCameraOrientation &&
                setCameraOrientation(ViewOrientation.Front);
            }}
            className={
              cameraOrientation === ViewOrientation.Front ? "activeView" : ""
            }
            eventName="Anterior View button clicked"
          >
            <img src={frontViewImage} alt="Front View" />
          </TrackedButton>
        </Tooltip>

        <Tooltip title={translations["POSTERIOR_VIEW"]}>
          <TrackedButton
            onClick={() => {
              setCameraOrientation &&
                setCameraOrientation(ViewOrientation.Back);
            }}
            className={
              cameraOrientation === ViewOrientation.Back ? "activeView" : ""
            }
            eventName="Posterior View button clicked"
          >
            <img src={backViewImage} alt="Back View" />
          </TrackedButton>
        </Tooltip>

        <Tooltip title={translations["LEFT_VIEW"]}>
          <TrackedButton
            onClick={() => {
              setCameraOrientation &&
                setCameraOrientation(ViewOrientation.Left);
            }}
            className={
              cameraOrientation === ViewOrientation.Left ? "activeView" : ""
            }
            eventName="Left View button clicked"
          >
            <img src={leftViewImage} alt="Left View" />
          </TrackedButton>
        </Tooltip>

        <Tooltip title={translations["RIGHT_VIEW"]}>
          <TrackedButton
            onClick={() => {
              setCameraOrientation &&
                setCameraOrientation(ViewOrientation.Right);
            }}
            className={
              cameraOrientation === ViewOrientation.Right ? "activeView" : ""
            }
            eventName="Right View button clicked"
          >
            <img src={rightViewImage} alt="Right View" />
          </TrackedButton>
        </Tooltip>

        <Tooltip title={translations["HEAD_VIEW"]}>
          <TrackedButton
            onClick={() => {
              setCameraOrientation && setCameraOrientation(ViewOrientation.Top);
            }}
            className={
              cameraOrientation === ViewOrientation.Top ? "activeView" : ""
            }
            eventName="Head View button clicked"
          >
            <img src={topViewImage} alt="Top View" />
          </TrackedButton>
        </Tooltip>

        <Tooltip title={translations["FOOT_VIEW"]}>
          <TrackedButton
            onClick={() => {
              setCameraOrientation &&
                setCameraOrientation(ViewOrientation.Bottom);
            }}
            className={
              cameraOrientation === ViewOrientation.Bottom ? "activeView" : ""
            }
            eventName="Foot View button clicked"
          >
            <img src={bottomViewImage} alt="Bottom View" />
          </TrackedButton>
        </Tooltip>
      </DropdownContainer>

      <TrackedButton
        onClick={toggleExpand}
        className="toggleMenuVisibility"
        eventName="Menu Visibility button clicked"
      >
        <img
          src={isExpanded ? collapseImage : expandImage}
          alt={isExpanded ? "Collapse" : "Expand"}
        />
      </TrackedButton>
    </ButtonsContainer>
  );
};

export default GenericToolBar;
