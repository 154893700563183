import uuid from "react-uuid";
// eslint-disable-next-line
import WasmWorker from "worker-loader?filename=static/js/[name].js!../workers/wasm.worker";
// eslint-disable-next-line
import WasmSecondWorker from "worker-loader?filename=static/js/[name].js!../workers/wasmSecond.worker";
import { IWorkerMessage } from "../utils/OccluUtil";

export enum WorkerName {
  Wasm,
  WasmSecond,
}

export default class WasmWorkerClient {
  private worker: Worker;
  private responseMap: Map<string, (msg: any) => void> = new Map();

  onmessage!: (msg: any) => void;

  constructor(name: WorkerName = WorkerName.Wasm) {
    switch (name) {
      case WorkerName.Wasm:
        this.worker = new WasmWorker();
        break;
      case WorkerName.WasmSecond:
        this.worker = new WasmSecondWorker();
        break;
    }
    this.worker.onmessage = (e) => {
      const msg = e.data;
      const respFunction = this.responseMap.get(msg?.responseId);
      if (respFunction) {
        respFunction(msg);
        this.responseMap.delete(msg.responseId);
      } else {
        this.onmessage?.(msg);
      }
    };
  }

  postMessage(
    msg: IWorkerMessage,
    responseFunction?: (msg: IWorkerMessage) => void
  ) {
    if (responseFunction) {
      msg.responseId = msg.responseId ?? uuid();
      this.responseMap.set(msg.responseId, responseFunction);
    }
    this.worker?.postMessage(msg);
  }
  terminate() {
    this.worker?.terminate();
  }
}
