export const parseDateTimeString = (dateTimeString: string): Date => {
  const year = parseInt(dateTimeString.substring(0, 4));
  const month = parseInt(dateTimeString.substring(4, 6)) - 1;
  const day = parseInt(dateTimeString.substring(6, 8));
  const hour = parseInt(dateTimeString.substring(8, 10));
  const minute = parseInt(dateTimeString.substring(10, 12));
  const second = parseInt(dateTimeString.substring(12, 14));

  return new Date(year, month, day, hour, minute, second);
};

export const formatDateFromString = (dateTimeString: string): string => {
  const date = parseDateTimeString(dateTimeString);
  return formatDate(date);
};

export const formatDateToIDicomTimeString = (date: Date): string => {
  const year = date.getFullYear() + "";
  const month = (date.getMonth() + 1 + "").padStart(2, "0");
  const day = (date.getDate() + "").padStart(2, "0");
  const hour = (date.getHours() + "").padStart(2, "0");
  const minute = (date.getMinutes() + "").padStart(2, "0");
  const second = (date.getSeconds() + "").padStart(2, "0");
  return year + month + day + hour + minute + second;
};

export const formatDate = (date: Date): string => {
  const datePart = date.toLocaleDateString(undefined, {
    year: "numeric",
    month: "2-digit",
    day: "2-digit",
  });
  const timePart = `${date.getHours().toString().padStart(2, "0")}:${date.getMinutes().toString().padStart(2, "0")}:${date.getSeconds().toString().padStart(2, "0")}`;

  return `${datePart} ${timePart}`;
};

export const formatDateForTimeline = (
  dateTime: Date,
  isFirstOfMonth: boolean,
  isFirstOfYear: boolean
): { date: string; year: string } => {
  const day = String(dateTime.getDate()).padStart(2, "0");
  const month = String(dateTime.getMonth() + 1).padStart(2, "0");
  const year = dateTime.getFullYear();

  if (isFirstOfMonth && isFirstOfYear) {
    return { date: `${month}/${day}`, year: `\n${year}` };
  } else {
    return { date: `${month}/${day}`, year: "\n\u00A0" };
  }
};

export const timeElapsedBetweenDates = (
  startDateTime: Date,
  endDateTime: Date
): string => {
  const difference = (endDateTime.getTime() - startDateTime.getTime()) / 1000;
  let result: string = "";

  const minutes = Math.floor((difference / 60) % 60);
  const hours = Math.floor((difference / 60 / 60) % 24);
  const days = Math.floor(difference / 60 / 60 / 24);
  const months = Math.floor(days / 31);
  const years = Math.floor(months / 12);

  if (years) {
    result = years + (years === 1 ? " year" : " years");
  } else if (months) {
    result = months + (months === 1 ? " month" : " months");
  } else if (days) {
    result = days + (days === 1 ? " day" : " days");
  } else if (hours) {
    result = hours + (hours === 1 ? " hour" : " hours");
  } else {
    result = minutes + (minutes === 1 ? " min" : " mins");
  }

  return result + " ago";
};
