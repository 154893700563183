import React from "react";

const PowerIcon: React.FC = () => {
  return (
    <svg
      width="36"
      height="36"
      viewBox="0 0 36 36"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M5 0C2.23858 0 0 2.23858 0 5V31C0 33.7614 2.23858 36 5 36H11C11.5523 36 12 35.5523 12 35C12 34.4477 11.5523 34 11 34H5C3.34315 34 2 32.6569 2 31V5C2 3.34315 3.34315 2 5 2H11C11.5523 2 12 1.55228 12 1C12 0.447715 11.5523 0 11 0H5ZM20.4706 9.11765C20.1607 8.95235 19.7867 8.96178 19.4855 9.14251C19.1843 9.32323 19 9.64874 19 10V14H8C7.44772 14 7 14.4477 7 15V21C7 21.5523 7.44772 22 8 22H19V26C19 26.3513 19.1843 26.6768 19.4855 26.8575C19.7867 27.0382 20.1607 27.0477 20.4706 26.8824L35.4706 18.8824C35.7965 18.7086 36 18.3693 36 18C36 17.6307 35.7965 17.2914 35.4706 17.1176L20.4706 9.11765ZM21 15V11.6667L32.875 18L21 24.3333V21C21 20.4477 20.5523 20 20 20H9V16H20C20.5523 16 21 15.5523 21 15Z"
        fill="#4D4D4D"
      />
    </svg>
  );
};

export default PowerIcon;
